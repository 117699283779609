import { FC } from 'react'
import { BillingReminderNotificationType } from '@expane/data'
import { notificationContainerStyle, NotificationItemProps, textStyle } from './logic'
import { useTranslation } from 'react-i18next'
import { NotificationItemHeader } from './HeaderItem'

interface BillingReminderNotificationItemProps
  extends Omit<NotificationItemProps, 'item' | 'openBookingDialog'> {
  notification: BillingReminderNotificationType
}

export const BillingNotificationItem: FC<BillingReminderNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
}) => {
  const { t } = useTranslation()

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="text-sm py-1">
          <p className={textStyle}>{t('billingReminder', { days: notification.message })}</p>
        </div>
      </div>
    </li>
  )
}
