import {
  invalidateBookings,
  ServerRecurringBookingByIdType,
  ServerTransactionInBookingType,
  useCancelBooking,
  useCancelBookings,
  useCreateRecurringBookings,
  useFetchBookingById,
  useFetchBranchById,
  useUpdateBookings,
  validateBooking,
} from '@expane/data'
import { set, fromZonedTime } from '@expane/date'
import { generateMessageAfterValidation } from '@expane/logic/bookingChecks'
import { defineDayTiming } from '@expane/logic/calendar'
import { permissions } from '@expane/logic/permission'
import {
  Button,
  CloseButton,
  Dialog,
  RadioGroupOption,
  useShortCut,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { checkIsBookingPaid, prepareBookingData } from 'logic/booking'
import { useShowCancelBookingPopup } from 'logic/hooks/popup/useShowCancelBookingPopup'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import {
  Control,
  SubmitHandler,
  UseFormHandleSubmit,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCashOutline, IoTrashBin } from 'react-icons/io5'
import { store } from 'store'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { useBookingPaymentDialog } from 'widgets/BookingPaymentDialog'
import { ButtonWithBillingCheck, QuickSaleButton, SaveButton } from 'widgets/Buttons'
import { ConfirmationDescription } from 'widgets/ConfirmationDescription'
import { useQuickSaleDialog } from 'widgets/QuickSaleDialog'
import { BookingDialogFormValues, BookingDialogInitialDto } from '.'
import { useCancelPaymentBookingPopup } from './CancelPaymentBookingPopup'
import { useSubmitBooking } from './useSubmitBooking'
import { RecurringEditType, useOpenRecurringEditDialog } from 'widgets/RecurringEditDialog'
import {
  getIsDayOfBookingStartDateChanged,
  getRecurringBookingIdsThatCanBeCanceled,
  getRecurringBookingThatCanBeEdited,
} from '@expane/logic/booking'
import {
  getAreRecurringBookingsWithInvalidDate,
  getRecurringBookingsDates,
} from '@expane/logic/recurringBookings'

interface BookingDialogFooterProps {
  control: Control<BookingDialogFormValues>
  timezone: string
  availableForEdit: boolean
  isCanceled: boolean
  initialData: BookingDialogInitialDto
  transactions: ServerTransactionInBookingType[] | undefined
  handleSubmit: UseFormHandleSubmit<BookingDialogFormValues>
  closeDialog: () => void
  closePopups: () => void
}

export const BookingDialogFooter = observer<BookingDialogFooterProps>(
  ({
    control,
    availableForEdit,
    isCanceled,
    handleSubmit,
    timezone,
    initialData,
    transactions = [],
    closeDialog,
    closePopups,
  }) => {
    const { t } = useTranslation()

    const [isSubmittingOnMultiBooking, setIsSubmittingOnMultiBooking] = useState<boolean>(false)

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const branchId = store.branch.branchId!
    const { data: branch } = useFetchBranchById(branchId)
    const { data: myPermissions } = useFetchMyPermissions()
    const { data: bookingById } = useFetchBookingById(initialData.id, branch?.timezone, branchId)

    const { mutateAsync: createRecurringBookings, isLoading } = useCreateRecurringBookings()
    const { mutateAsync: updateRecurringBookings } = useUpdateBookings()

    const { isDirty, isSubmitting, dirtyFields } = useFormState({ control })

    const { showConfirmCancelBooking, cancelBookingModal } = useShowCancelBookingPopup()

    const { openPaymentDialog, paymentDialog } = useBookingPaymentDialog()

    const { quickSaleDialog, openQuickSaleDialog } = useQuickSaleDialog()
    const watchedClientId = useWatch({
      control,
      name: 'clientId',
    })
    const watchedServices = useWatch({ control, name: 'services' })
    const services = watchedServices ? extractItemsFromFolders(watchedServices) : []

    const watchedConsumables = useWatch({ control, name: 'consumables' })

    const { isSomeServicesPaid, isSomeProductsPaid, isBookingPaid } = checkIsBookingPaid({
      consumables: watchedConsumables,
      services,
      transactions,
    })

    const [openSnackbar] = useSnackbar()
    const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

    const { cancelPaymentBookingPopup, openCancelPaymentBookingPopup } =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      useCancelPaymentBookingPopup({ bookingId: initialData.id! }) // dialog only opens if there is id
    const { mutateAsync: cancelBooking } = useCancelBooking()
    const { mutateAsync: cancelBookings } = useCancelBookings()

    const watchedDate = useWatch({ control, name: 'startDate' })
    const branchSchedule = branch?.schedule
    const branchTiming = defineDayTiming(branchSchedule ?? undefined, watchedDate)

    // Нужны для useSubmitBooking для нахождения товаров для удаления
    const initialBookingProducts = bookingById?.bookingProducts ?? []
    // Нужны для useSubmitBooking для нахождения услуг для удаления и добавления
    const initialBookingServices =
      bookingById && !bookingById.isGroupBooking ? bookingById.bookingServices : []

    const { recurringEditDialog, openRecurringEditDialog } = useOpenRecurringEditDialog()

    const { submitBooking, confirmationModal: submitBookingConfirmationModal } = useSubmitBooking({
      control,
      timezone,
      bookingId: initialData.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      oldStartDate: initialData.isCreate ? initialData.startDate : bookingById!.startDate,
      isCreate: initialData.isCreate,
      dayEnd: branchTiming[1],
      dayStart: branchTiming[0],
      initialBookingProducts,
      initialBookingServices,
    })

    const submitCreatingRecurringBookings = async (
      data: BookingDialogFormValues,
      dates: Date[],
    ) => {
      if (branchId && data.recurring) {
        const extractedServices = extractItemsFromFolders(data.services)
        const serviceIds: number[] = extractedServices.map(service => service.id)

        const recurringBookings = dates.map(date => ({
          duration: Number(data.duration),
          employeeId: data.employeeId,
          locationId: data.locationId,
          bookingServices: { data: serviceIds.map(serviceId => ({ serviceId })) },
          // already converted to UTC
          startDate: date,
          note: data.note,
          clientId: data.clientId,
          isGroupBooking: false,
          bookingProducts: {
            data: data.consumables.map(product => ({
              ...product,
              quantity: Number(product.quantity),
            })),
          },
          branchId,
        }))

        const result = await createRecurringBookings({
          branchId,
          bookingInsertInput: recurringBookings,
        })

        if (result?.insertRecurringBooking?.bookings) {
          openSnackbar(t('booking.createSuccess'), 'success')

          closeDialog()
        } else openSnackbar(t('submitError'), 'error')
      }
    }

    const submitUpdatingRecurringBookings = async (
      data: BookingDialogFormValues,
      recurringBookings: ServerRecurringBookingByIdType[],
    ) => {
      try {
        if (bookingById && branchId) {
          const extractedServices = extractItemsFromFolders(data.services)
          const serviceIds: number[] = extractedServices.map(service => service.id)

          const filteredRecurringBookings = getRecurringBookingThatCanBeEdited(recurringBookings)

          const bookingsToUpdate = filteredRecurringBookings.map(
            ({ id, startDate, bookingServices, bookingProducts }) => {
              const {
                bookingServicesToBeRemovedIds,
                bookingServiceInsertInput,
                bookingProductsToBeRemovedIds,
                bookingProductInsertInput,
              } = prepareBookingData({
                initialBookingServices: bookingServices,
                serviceIds,
                initialBookingProducts: bookingProducts,
                consumables: data.consumables,
                bookingId: id,
              })

              return {
                branchId,
                id,
                bookingSetInput: {
                  startDate: fromZonedTime(
                    set(startDate, {
                      hours: data.startDate.getHours(),
                      minutes: data.startDate.getMinutes(),
                    }),
                    timezone,
                  ),
                  clientId: data.clientId,
                  employeeId: data.employeeId ?? null,
                  locationId: data.locationId,
                  duration: Number(data.duration),
                  note: data.note,
                },
                oldStartDate: startDate,
                bookingServicesToBeRemovedIds,
                bookingServiceInsertInput,
                bookingProductsToBeRemovedIds,
                bookingProductInsertInput,
              }
            },
          )

          await updateRecurringBookings(bookingsToUpdate)

          invalidateBookings({
            date: data.startDate,
            ids: filteredRecurringBookings.map(({ id }) => id),
            dateRange: [
              filteredRecurringBookings[0].startDate,
              filteredRecurringBookings[filteredRecurringBookings.length - 1].startDate,
            ],
            branchId,
          })

          openSnackbar(t('booking.updateSuccess'), 'success')
        }
      } catch (error) {
        openSnackbar(t('submitError'), 'error')
      }

      closeDialog()
    }

    const handleOnSave: SubmitHandler<BookingDialogFormValues> = async data => {
      if (initialData.isCreate && data.recurring) {
        const dates = getRecurringBookingsDates(data.recurring, data.startDate, timezone)

        // если после выбора количества повторений - количество букингов 1
        if (dates.length === 1) await submitBooking(data, closeDialog)

        if (
          getAreRecurringBookingsWithInvalidDate({ dates, timezone, startDate: data.startDate })
        ) {
          openSnackbar(t('recurringBookings.invalidEndDate'), 'error')
          return
        }

        if (dates.length === 0) {
          openSnackbar(t('recurringBookings.invalidInterval'), 'error')
          return
        }

        showConfirmation({
          title: t('warning'),
          description: (
            <div className={'w-120'}>
              <p>{t('recurringBookings.validationWarning')}</p>
            </div>
          ),
          onConfirm: () => submitCreatingRecurringBookings(data, dates),
        })
      } else {
        if (bookingById?.recurringBooking?.id) {
          const isDateChanged = Boolean(dirtyFields.startDate)
            ? getIsDayOfBookingStartDateChanged(bookingById.startDate, data.startDate)
            : false

          if (isDateChanged) {
            showConfirmation({
              title: t('warning'),
              description: (
                <div className={'w-120'}>
                  <p>{t('recurringBookings.editDateWarning')}</p>
                </div>
              ),
              onConfirm: () => submitBooking(data, closeDialog),
            })
          } else
            openRecurringEditDialog({
              recurringId: bookingById.recurringBooking.id,
              startDate: bookingById.startDate,
              onSave: (type, recurringBookings) => {
                if (type?.id === RecurringEditType.all) {
                  submitUpdatingRecurringBookings(data, recurringBookings)
                } else submitBooking(data, closeDialog)
              },
            })
        } else {
          await submitBooking(data, closeDialog)
        }
      }
    }

    const cancelExistingBooking = async (reason: string | null) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const result = await cancelBooking({ id: initialData.id!, canceledReason: reason })
      if (result?.updateBookingById?.id) {
        openSnackbar(t('cancelBooking.success'), 'success')
      } else {
        openSnackbar(t('submitError'), 'error')
      }

      closeDialog()
    }

    const cancelExistingRecurringBooking = async (
      type: RadioGroupOption | undefined,
      recurringBookings: ServerRecurringBookingByIdType[],
      reason: string | null,
    ) => {
      if (type && type.id === RecurringEditType.single) {
        await cancelExistingBooking(reason)
      } else if (bookingById?.recurringBooking?.id) {
        const recurringBookingIdsToCancel =
          getRecurringBookingIdsThatCanBeCanceled(recurringBookings)

        const result = await cancelBookings({
          ids: recurringBookingIdsToCancel,
          canceledReason: reason,
        })

        if (result?.updateBookings?.affectedRows) {
          openSnackbar(t('cancelBooking.success'), 'success')
        } else {
          openSnackbar(t('submitError'), 'error')
        }
      }

      closeDialog()
    }

    const handleCancelBooking = () => {
      if (bookingById?.recurringBooking?.id) {
        openRecurringEditDialog({
          recurringId: bookingById.recurringBooking.id,
          startDate: bookingById.startDate,
          onSave: (type, recurringBookings) =>
            showConfirmCancelBooking({
              onConfirm: reason => cancelExistingRecurringBooking(type, recurringBookings, reason),
            }),
        })
      } else
        showConfirmCancelBooking({
          onConfirm: cancelExistingBooking,
        })
    }

    const handleCancelPaymentBooking = () => {
      if (initialData.id) openCancelPaymentBookingPopup()
    }

    const handleMultiBooking: SubmitHandler<BookingDialogFormValues> = async data => {
      setIsSubmittingOnMultiBooking(true)
      const { services } = data
      const confirmValidation = () => {
        const extractedServices = extractItemsFromFolders(services as TreeMenuItem[])
        const serviceIds: number[] = extractedServices.map(service => service.id)
        store.multiBooking.setFirstBooking({
          duration: Number(data.duration),
          employeeId: data.employeeId,
          locationId: data.locationId,
          bookingServices: { data: serviceIds.map(serviceId => ({ serviceId })) },
          startDate: data.startDate,
          note: data.note,
          clientId: data.clientId,
          isGroupBooking: false,
        })
        setIsSubmittingOnMultiBooking(false)
        closeDialog()
      }

      const startDate = fromZonedTime(data.startDate, timezone)
      const { type } = await validateBooking({
        branchId,
        bookingId: initialData.id ?? null,
        startDate,
        clientId: data.clientId,
        duration: Number(data.duration),
        isGroupBooking: false,
        serviceIds: data.services.map(service => service.id),
        serviceId: null,
        clientIds: null,
        employeeId: data.employeeId ?? null,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        locationId: data.locationId!,
      })

      if (type === null || type === undefined) {
        confirmValidation()
      } else {
        const warning = generateMessageAfterValidation({ warningType: type, t })
        showConfirmation({
          title: t('create'),
          onConfirm: confirmValidation,
          description: (
            <ConfirmationDescription
              text={warning}
              question={t('bookingValidation.confirmation')}
            />
          ),
        })
      }
    }

    const isPaymentAllowed = myPermissions?.includes(permissions.transaction.set)
    const isRefundAllowed = myPermissions?.includes(permissions.transaction.setRefund)

    const disabledSaveButton =
      isSubmitting ||
      (!isDirty && !initialData.serviceId && !initialData.clientId) ||
      isSubmittingOnMultiBooking ||
      isLoading

    useShortCut(['Enter'], () => {
      if (availableForEdit && !disabledSaveButton)
        handleSubmit(data => handleOnSave(data as BookingDialogFormValues))
    })

    const watchedRecurring = useWatch({ control, name: 'recurring' })

    return (
      <Dialog.Footer className="justify-between">
        <div className="flex gap-2">
          <CloseButton onClick={closePopups} disabled={isSubmitting} />

          {availableForEdit && initialData.isCreate && !watchedRecurring && (
            <Button
              onClick={handleSubmit(handleMultiBooking)}
              disabled={disabledSaveButton}
              spinner={isSubmittingOnMultiBooking}
            >
              {t('createMultiple')}
            </Button>
          )}
          {availableForEdit && (
            <SaveButton
              onClick={handleSubmit(handleOnSave)}
              disabled={disabledSaveButton}
              spinner={isSubmitting || isLoading}
              isCreate={!bookingById}
            />
          )}
        </div>

        <div className="flex gap-2">
          {!initialData.isCreate &&
            availableForEdit &&
            !isCanceled &&
            (isSomeServicesPaid || isSomeProductsPaid ? (
              <ButtonWithBillingCheck
                type="danger"
                disabled={isSubmitting || !isRefundAllowed || isLoading}
                onClick={handleCancelPaymentBooking}
                Icon={IoCashOutline}
              >
                {t('cancelPayment')}
              </ButtonWithBillingCheck>
            ) : (
              <ButtonWithBillingCheck
                type="danger"
                disabled={isSubmitting || isLoading}
                onClick={handleCancelBooking}
                Icon={IoTrashBin}
              >
                {t('cancelBooking.title')}
              </ButtonWithBillingCheck>
            ))}

          {isPaymentAllowed && !isCanceled && (
            <>
              <ButtonWithBillingCheck
                type="outline"
                disabled={isBookingPaid || isLoading}
                onClick={
                  isDirty || initialData.isCreate
                    ? handleSubmit(data =>
                        submitBooking(data as BookingDialogFormValues, bookingId => {
                          openPaymentDialog({
                            bookingsIds: bookingId ? [bookingId] : [],
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            clientId: data.clientId!,
                            onClose: initialData.isCreate ? closeDialog : undefined,
                          })
                        }),
                      )
                    : () => {
                        if (initialData.id)
                          openPaymentDialog({
                            bookingsIds: [initialData.id],
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            clientId: watchedClientId!,
                          })
                      }
                }
                Icon={IoCashOutline}
              >
                {isBookingPaid ? t('paid') : t('pay')}
              </ButtonWithBillingCheck>

              {watchedClientId !== undefined && (
                <QuickSaleButton
                  onClick={() => openQuickSaleDialog(watchedClientId ?? undefined)}
                  disabled={isLoading}
                />
              )}
            </>
          )}
        </div>

        {cancelBookingModal}
        {confirmationModal}
        {quickSaleDialog}
        {paymentDialog}
        {cancelPaymentBookingPopup}
        {submitBookingConfirmationModal}
        {recurringEditDialog}
      </Dialog.Footer>
    )
  },
)
