import { ServerTransactionType, TRANSACTION_TYPES, useFetchBusinessSettings } from '@expane/data'
import { formatTimeFromDate } from '@expane/date'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { checkIsPaymentTransactionWithSubscription } from '@expane/logic/finances/filters'
import {
  findTransactionAccountNameForShortInfo,
  getTransactionsListToDisplay,
} from '@expane/logic/finances/transactionsToDisplayFilters'
import { convertUnitValueFromServer } from '@expane/logic/product'
import {
  getAdditionalInfoByTransactionType,
  transformTransactionType,
} from '@expane/logic/transaction'
import { transformPersonName } from '@expane/logic/utils'
import { EmptyPlaceholder, Table } from '@expane/ui'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { TransactionDialog } from 'widgets/TransactionDialog'
import { useOpenTransactionSubscriptionDialog } from 'widgets/TransactionSubscriptionDialog'

interface Props {
  transactions: ServerTransactionType[]
  isLoading: boolean
}

export const CashBoxPageList: FC<PropsWithBranchId<Props>> = memo(
  ({ transactions, isLoading, branchId }) => {
    const { data: businessSettings } = useFetchBusinessSettings()
    const { dialog: transactionDialog, openEditDialog: editTransactionDialog } =
      useOpenDialog(TransactionDialog)

    const { transactionSubscriptionDialog, openTransactionSubscriptionDialog } =
      useOpenTransactionSubscriptionDialog()

    const onRowClick = (item: ServerTransactionType) => {
      const isSubscriptionTransaction = checkIsPaymentTransactionWithSubscription(item)
      if (isSubscriptionTransaction) openTransactionSubscriptionDialog(item.id)
      else editTransactionDialog(item.id)
    }

    const transactionsForDisplay = getTransactionsListToDisplay({
      transactions,
      hideClientsDeposit: businessSettings?.hideClientsDeposit,
      page: 'finance',
    })

    const { t } = useTranslation()
    const columns = useMemo<ColumnDef<ServerTransactionType>[]>(
      () => [
        {
          accessorKey: 'date',
          header: t('timeTitle'),
          cell: data => formatTimeFromDate(data.getValue() as Date),
          size: 60,
        },
        {
          accessorKey: 'type',
          header: t('type'),
          cell: data => t(transformTransactionType(data.getValue() as number)),
          size: 200,
        },
        {
          id: 'additionalInfo',
          header: t('additionalInfo.short'),
          cell: item => getAdditionalInfoByTransactionType(item.row.original, t),
          size: 200,
        },
        {
          accessorKey: 'client',
          header: t('client.name'),
          cell: data => {
            const client = data.getValue()
            if (!client) return '-'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return transformPersonName(client as any)
          },
          size: 160,
        },
        {
          accessorKey: 'employee',
          header: t('employee.name'),
          cell: data => {
            const employee = data.getValue()
            if (!employee) return '-'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return transformPersonName(employee as any)
          },
          size: 160,
        },
        {
          accessorKey: 'author',
          header: t('created'),
          cell: data => {
            const author = data.getValue()
            if (!author) return '-'
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return transformPersonName(author as any)
          },
          size: 160,
        },
        {
          id: 'discount',
          header: t('discount'),
          cell: data => <DiscountCell data={data} branchId={branchId} />,
          size: 50,
        },
        {
          accessorKey: 'amount',
          header: () => <span className="text-right w-full">{t('amount')}</span>,
          cell: data => <AmmountCell data={data} branchId={branchId} />,
          size: 90,
        },
        {
          id: 'account',
          header: t('account.name'),
          cell: data => {
            const transaction = data.row.original
            return findTransactionAccountNameForShortInfo(
              transaction.toAccount,
              transaction.fromAccount,
            )
          },
          size: 70,
        },
      ],
      [branchId, t],
    )

    if (transactionsForDisplay.length === 0 && !isLoading) {
      return <EmptyPlaceholder Icon={IoCashOutline} text={t('noTransactions')} />
    }

    return (
      <>
        <Table
          containerClassName="max-h-full mb-4"
          isLoading={isLoading}
          data={transactionsForDisplay}
          columns={columns}
          onRowClick={onRowClick}
        />
        {transactionDialog}
        {transactionSubscriptionDialog}
      </>
    )
  },
)

const AmmountCell: FC<PropsWithBranchId<{ data: CellContext<ServerTransactionType, unknown> }>> = ({
  data,
  branchId,
}) => {
  const convertToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  return <div className="text-right">{convertToMoney(data.getValue() as number)}</div>
}
const DiscountCell: FC<
  PropsWithBranchId<{ data: CellContext<ServerTransactionType, unknown> }>
> = ({ data, branchId }) => {
  const convertToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const discount = getDiscountAmount(data.row.original)

  return <div>{discount ? convertToMoney(discount) : '-'}</div>
}

const getDiscountAmount = (item: ServerTransactionType | undefined) => {
  if (!item) return 0

  if (
    item.type === TRANSACTION_TYPES.payment.id &&
    item.typeVariation === TRANSACTION_TYPES.payment.variations.giftCard.id
  ) {
    return item.transactionsCards.reduce(
      (acc, value) => (value.discount !== null ? acc + value.discount : acc),
      0,
    )
  }

  if (
    item.type === TRANSACTION_TYPES.payment.id &&
    item.typeVariation === TRANSACTION_TYPES.payment.variations.service.id
  ) {
    return item.transactionsServices.reduce(
      (acc, value) => (value.discount !== null ? acc + value.discount : acc),
      0,
    )
  }

  if (
    item.type === TRANSACTION_TYPES.payment.id &&
    item.typeVariation === TRANSACTION_TYPES.payment.variations.product.id
  ) {
    return item.movement?.movementProducts.reduce(
      (acc, value) =>
        value.discount !== null
          ? acc + value.discount * convertUnitValueFromServer(value.quantity, value.product.unit)
          : acc,
      0,
    )
  }

  if (
    item.type === TRANSACTION_TYPES.payment.id &&
    item.typeVariation === TRANSACTION_TYPES.payment.variations.subscription.id
  ) {
    return item.transactionsCards.reduce((totalDiscount, transactionCard) => {
      if (transactionCard.discount) return totalDiscount + transactionCard.discount

      return totalDiscount
    }, 0)
  }

  return 0
}
