import { ServerAccountType, ServerTransactionByIdType, ServerTransactionType } from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { PropsWithBranchId } from '@expane/logic/branch'
import {
  useConvertNumberToMoneyWithoutSymbol,
  useConvertNumberToMoneyCode,
} from '@expane/logic/currency'
import { checkWithdrawPossibility } from '@expane/logic/form'
import { useBusinessModulesSettings } from '@expane/logic/modules'
import { checkUnitCanBeFloat, getUnitsName, UNITS_IDS } from '@expane/logic/product'
import { checkIsTransactionPaymentInCredit } from '@expane/logic/transaction'
import {
  getRefundItemsAndType,
  getTotalRefundItemsPrice,
  RefundItem,
  removeUsedGiftCards,
  TransactionRefundFormValues,
} from '@expane/logic/transaction/refund'
import { findById } from '@expane/logic/utils'
import { Checkbox, DeleteButtonInTable, Dialog, Input, Modal, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, useMemo, useRef } from 'react'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AccountSelectDropdown } from 'widgets/AccountSelectDropdown'
import { TransactionRefundDialogFooter } from 'widgets/TransactionDialog/RefundDialog/Footer'
import { TransactionInCreditExplanation } from 'widgets/TransactionInCreditExplanation'

interface TransactionRefundDialogLogicProps {
  defaultAccountId: number
  refundsForTransaction: ServerTransactionType[]
  transaction: ServerTransactionByIdType
  onSuccess: () => void
  closeDialog: () => void
  accounts: ServerAccountType[]
}

export const TransactionRefundDialogLogic: FC<
  PropsWithBranchId<TransactionRefundDialogLogicProps>
> = ({
  defaultAccountId,
  transaction,
  refundsForTransaction,
  closeDialog,
  onSuccess,
  accounts,
  branchId,
}) => {
  // в этом диалоге нет абонементов, поэтому удаляем только использованные gift card
  const refundInfo = removeUsedGiftCards(
    getRefundItemsAndType(transaction, refundsForTransaction),
    transaction,
  )

  const submitForm = useRef<(() => void) | undefined>(undefined)

  const { t } = useTranslation()
  const convertNumberToMoneyCode = useConvertNumberToMoneyCode({ branchId })
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const { getModuleSetting } = useBusinessModulesSettings()
  const isCheckboxEnabled = getModuleSetting('checkbox')

  const { handleSubmit, formState, control } = useForm<TransactionRefundFormValues>({
    defaultValues: {
      items: refundInfo.items,
      dataType: refundInfo.type,
      refundToClientAccount: false,
      fromAccountId: defaultAccountId,
      isRefundConsumables: Boolean(transaction.movement),
    },
  })

  const { fields, remove } = useFieldArray({ control, name: 'items', keyName: 'generatedId' })

  const watchedItems = useWatch({ control, name: 'items' })
  const watchedRefundToClientAccount = useWatch({ control, name: 'refundToClientAccount' })
  const watchedFromAccountId = useWatch({ control, name: 'fromAccountId' })

  const totalRefundPrice = getTotalRefundItemsPrice(watchedItems)

  const showReturnConsumablesCheckbox =
    transaction.movement &&
    ((refundInfo.type === 'product' && Boolean(transaction.bookingId)) ||
      refundInfo.type === 'service')

  const isTransactionInCredit = checkIsTransactionPaymentInCredit(transaction)
  const isSoftwarePOSAccount = isCheckboxEnabled
    ? Boolean(findById(watchedFromAccountId, accounts)?.accountSoftwarePOS.length)
    : false

  const columns = useMemo<ColumnDef<RefundItem>[]>(
    () => [
      {
        accessorKey: 'position',
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
        header: t('position'),
        cell: ({
          row: {
            original: { name, unit },
          },
        }) => (
          <div>
            {refundInfo.type === 'product'
              ? `${name}, ${getUnitsName(unit ?? UNITS_IDS.pcs, t)}`
              : name}
          </div>
        ),
        size: 360,
        minSize: 200,
      },
      {
        accessorKey: 'qty',
        enableSorting: false,
        header: t('qty'),
        cell: data => {
          const item = data.row.original

          return (
            <Controller
              name={`items.${data.row.index}` as 'items.0'}
              control={control}
              rules={{
                required: true,
                validate: value => {
                  if (value.unit && checkUnitCanBeFloat(value.unit)) return true

                  return Number.isInteger(value.quantity)
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Input
                  type="number"
                  value={value.quantity.toString()}
                  onChange={e => {
                    if (Number(e.target.value) > 0 && Number(e.target.value) <= item.quantity)
                      onChange({
                        ...item,
                        quantity: Number(e.target.value),
                      })
                  }}
                  height="small"
                />
              )}
            />
          )
        },
        size: 100,
        minSize: 100,
      },
      {
        accessorKey: 'price',
        header: t('price'),
        cell: data => (
          <Controller
            name={`items.${data.row.index}` as 'items.0'}
            control={control}
            render={({ field: { value } }) => (
              <div>{convertNumberToMoney(value.price * value.quantity)}</div>
            )}
          />
        ),
        size: 100,
        minSize: 60,
      },
      {
        accessorKey: 'remove',
        enableSorting: false,
        header: '',
        cell: data => {
          return <DeleteButtonInTable onClick={() => remove(data.row.index)} />
        },
        size: 50,
        enableResizing: false,
      },
    ],
    [control, convertNumberToMoney, refundInfo.type, remove, t],
  )

  return (
    <>
      <Modal close={closeDialog} confirm={submitForm.current}>
        <Dialog>
          <Dialog.Title>{t('transaction.creatingRefund')}</Dialog.Title>

          <Dialog.Body className="w-160">
            {fields.length === 0 ? (
              <p>{t('transaction.noRefunds')}</p>
            ) : (
              <Table columns={columns} data={fields} />
            )}

            {fields.length === 0 ? null : (
              <>
                {transaction.cardId ? null : (
                  <div className="my-3 flex justify-end gap-1 text-secondary-color">
                    {t('cancellationOfBookingPayment.amountToBeReturned')}:
                    <span className="font-medium text-main-color">
                      {convertNumberToMoneyCode(totalRefundPrice)}
                    </span>
                  </div>
                )}

                <div className="flex justify-between">
                  {isTransactionInCredit ? (
                    <TransactionInCreditExplanation />
                  ) : (
                    <>
                      <div className="flex flex-col justify-center">
                        <Controller
                          control={control}
                          name="refundToClientAccount"
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              label={t('transaction.refundToClientAccount')}
                              disabled={isTransactionInCredit || isSoftwarePOSAccount}
                              hint={
                                transaction.cardId
                                  ? `${convertNumberToMoneyCode(totalRefundPrice)} - ${t(
                                      'transaction.refundToClientAccountBySubscriptionHint',
                                    )}`
                                  : t('transaction.refundToClientAccountHint')
                              }
                              onChange={onChange}
                              checked={value}
                            />
                          )}
                        />

                        {showReturnConsumablesCheckbox && (
                          <Controller
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Checkbox
                                label={t('transaction.returnConsumables')}
                                checked={value}
                                onChange={onChange}
                                hint={t('transaction.returnConsumablesHint')}
                              />
                            )}
                            name="isRefundConsumables"
                          />
                        )}
                      </div>
                      {transaction.cardId ? null : (
                        <Controller
                          name="fromAccountId"
                          control={control}
                          rules={{
                            validate: value => {
                              if (isTransactionInCredit) return true
                              else
                                return checkWithdrawPossibility({
                                  currentAccount: getCurrentAccount(accounts, value),
                                  value: totalRefundPrice,
                                })
                            },
                          }}
                          render={({ field: { onChange, value } }) => (
                            <AccountSelectDropdown
                              value={value}
                              label={t('fromAccount')}
                              accounts={accounts}
                              onSelectChange={onChange}
                              popupHeight="small"
                              errorMessage={{
                                isShown: Boolean(formState.errors.fromAccountId),
                                text: t('formError.insufficientFunds'),
                              }}
                              required
                              className="w-1/2"
                              disabled={watchedRefundToClientAccount || isTransactionInCredit}
                            />
                          )}
                        />
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </Dialog.Body>

          <TransactionRefundDialogFooter
            control={control}
            handleSubmit={handleSubmit}
            closeDialog={closeDialog}
            onSuccess={onSuccess}
            transaction={transaction}
            totalSum={totalRefundPrice}
            isCreateRefundButton={fields.length > 0}
            isSubmitting={formState.isSubmitting}
            onSubmitForm={submitForm}
            accounts={accounts}
          />
        </Dialog>
      </Modal>
    </>
  )
}
