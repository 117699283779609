import { ServerProductGroupType } from '@expane/data'
import { checkOnlyPositiveAmount } from '@expane/logic/form'
import { checkUnitCanBeFloat, generateProductTypeHint, UNITS } from '@expane/logic/product'
import { translateItemsName } from '@expane/logic/utils'
import { Input, NumberInput, RadioGroupButtons, SelectDropdown, Textarea } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { ProductDialogFormValues } from 'widgets/ProductDialog'
import { productTypeOptions } from './logic'

interface ProductDialogBodyProps {
  control: Control<ProductDialogFormValues>
  productGroups?: ServerProductGroupType[]
  disabled: boolean
  setValue: UseFormSetValue<ProductDialogFormValues>
  areUsedAsConsumables: boolean
  thereWasAlreadySale: boolean
  isShowProductType: boolean
}

export const ProductDialogBody: FC<ProductDialogBodyProps> = ({
  control,
  productGroups = [],
  disabled,
  setValue,
  areUsedAsConsumables,
  thereWasAlreadySale,
  isShowProductType,
}) => {
  const { t } = useTranslation()

  const watchedUnit = useWatch({ control, name: 'unit' })

  return (
    <div className="flex">
      <div className="w-full">
        <div className={blockStyle}>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field: { onChange, value }, formState }) => (
              <Input
                containerClassName="w-1/2"
                label={t('title')}
                required
                errorMessage={{
                  isShown: Boolean(formState.errors.name),
                  text: t('formError.required'),
                }}
                value={value}
                onChange={onChange}
                disabled={disabled}
                autoFocus
              />
            )}
          />
          <Controller
            control={control}
            name="unit"
            rules={{ required: true }}
            render={({ field: { value, onChange }, formState }) => (
              <SelectDropdown
                className="w-1/4"
                label={t('unit.name')}
                required
                value={value}
                onSelectChange={value => {
                  setValue('criticalQuantity', '0')

                  onChange(value)
                }}
                items={translateItemsName(UNITS, t)}
                errorMessage={{
                  isShown: Boolean(formState.errors.unit),
                  text: t('formError.required'),
                }}
                disabled={disabled}
              />
            )}
          />
          <Controller
            control={control}
            name="criticalQuantity"
            rules={{ validate: { checkOnlyPositiveAmount } }}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <NumberInput
                  containerClassName="w-1/4"
                  label={t('criticalQuantity.shortName')}
                  value={value}
                  onChange={onChange}
                  hint={t('criticalQuantity.hint')}
                  disabled={disabled}
                  errorMessage={{ isShown: Boolean(error), text: t('formError.invalidValue') }}
                  allowDecimals={checkUnitCanBeFloat(watchedUnit)}
                />
              )
            }}
          />
        </div>
        <div className={blockStyle}>
          <Controller
            control={control}
            name="groupId"
            render={({ field: { value, onChange } }) => (
              <SelectDropdown
                className="w-1/2"
                label={t('category')}
                value={value}
                onSelectChange={onChange}
                items={productGroups}
                isClearable
                customClearFunction={() => setValue('groupId', undefined, { shouldDirty: true })}
                disabled={disabled}
              />
            )}
          />

          <Controller
            control={control}
            name="costPrice"
            rules={{ required: true, validate: checkOnlyPositiveAmount }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <NumberInput
                containerClassName="w-1/4"
                label={t('costPricePerUnit')}
                value={value}
                onChange={onChange}
                required
                errorMessage={{
                  isShown: Boolean(error),
                  text:
                    error?.type === 'required'
                      ? t('formError.required')
                      : t('formError.invalidValue'),
                }}
                disabled={disabled}
              />
            )}
          />

          <Controller
            control={control}
            name="price"
            rules={{ required: true, validate: checkOnlyPositiveAmount }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <NumberInput
                containerClassName="w-1/4"
                label={t('pricePerUnit')}
                required
                errorMessage={{
                  isShown: Boolean(error),
                  text:
                    error?.type === 'required'
                      ? t('formError.required')
                      : t('formError.invalidValue'),
                }}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />
        </div>
        <div className={blockStyle}>
          <div className="w-1/2">
            <div className="flex gap-2 mb-4">
              <Controller
                control={control}
                name="vendorCode"
                render={({ field: { value, onChange } }) => (
                  <Input
                    containerClassName="w-1/2"
                    value={value}
                    onChange={onChange}
                    label={t('vendorCode')}
                    disabled={disabled}
                  />
                )}
              />

              <Controller
                control={control}
                name="barcode"
                render={({ field: { value, onChange } }) => (
                  <NumberInput
                    containerClassName="w-1/2"
                    value={value}
                    onChange={onChange}
                    label={t('barcode')}
                    disabled={disabled}
                  />
                )}
              />
            </div>
            {isShowProductType && (
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <RadioGroupButtons
                    label={t('productType.name')}
                    hint={generateProductTypeHint(areUsedAsConsumables, thereWasAlreadySale, t)}
                    required
                    options={translateItemsName(productTypeOptions, t)}
                    onChange={onChange}
                    value={value}
                    disabled={areUsedAsConsumables || thereWasAlreadySale}
                    errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                  />
                )}
              />
            )}
          </div>

          <Controller
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <Textarea
                rows={5}
                label={t('description')}
                value={value}
                containerClassName="w-1/2"
                onChange={onChange}
                placeholder={t('placeholders.storageDescription')}
                disabled={disabled}
              />
            )}
          />
        </div>
      </div>

      <Controller
        control={control}
        name="photo"
        render={({ field: { value, onChange } }) => (
          <EditablePhoto
            containerClassName="ml-2 mt-4"
            defaultPhoto={value}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      />
    </div>
  )
}

const blockStyle = 'flex w-full gap-2'
