import { ChangeEvent, FC, InputHTMLAttributes, useEffect, useState } from 'react'
import { ErrorMessageType } from '../ErrorMessage'
import { IconType } from 'react-icons'
import { BaseInput, InputHeight } from '../Input/BaseInput'

export interface NumberInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> {
  label?: string
  hint?: string
  Icon?: IconType
  containerClassName?: string
  errorMessage?: ErrorMessageType
  onChange: (value: string) => void
  value: string
  height?: InputHeight
  allowDecimals?: boolean
}

export const NumberInput: FC<NumberInputProps> = ({
  label,
  hint,
  onChange,
  value,
  containerClassName,
  errorMessage,
  Icon,
  allowDecimals = true,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState(value || '')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value

    // Разрешаем ввод только чисел
    const isValidFormat = allowDecimals
      ? /^[0-9]*[.,]?[0-9]*$/.test(rawValue) &&
        !(rawValue.match(/[.,]/g) || []).some((char, i, arr) => i !== arr.indexOf(char))
      : /^[0-9]*$/.test(rawValue)

    if (isValidFormat) {
      // Преобразуем запятую в точку для внутреннего парсинга
      const parsedValue = rawValue.replace(',', '.')

      if (allowDecimals) {
        const fractionalPart = parsedValue.split('.')[1]
        const hasFractionalDecimal = parsedValue.includes('.')

        // Проверяем, что количество знаков после точки не превышает FRACTIONAL_PLACES
        if (hasFractionalDecimal && fractionalPart.length > FRACTIONAL_PLACES) {
          return
        }
      }

      setInputValue(rawValue)

      if (!isNaN(Number(parsedValue)) && parsedValue !== '') {
        onChange(parsedValue)
      } else if (rawValue === '') {
        onChange('')
      }
    }
  }
  // Синхронизируем локальное состояние inputValue с value, переданным через пропсы
  // Это необходимо для компонентов, которые управляются извне,
  // чтобы они корректно реагировали на изменения value, инициированные родительским компонентом
  useEffect(() => {
    setInputValue(value || '')
  }, [value])

  return (
    <BaseInput
      label={label}
      hint={hint}
      Icon={Icon}
      containerClassName={containerClassName}
      errorMessage={errorMessage}
      value={inputValue}
      onChangeInput={handleChange}
      {...rest}
    />
  )
}

const FRACTIONAL_PLACES = 3
