import { useFetchProducts } from '@expane/data'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { convertUnitValueFromServer } from '@expane/logic/product'
import { store } from 'store'
import { Consumable } from '@expane/logic/payment/booking'
import { findById } from '@expane/logic/utils'

export const useGetConsumableAmountInBranch = (branchId: number | undefined) => {
  const { data: products } = useFetchProducts(branchId)

  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const getProductAmount = (
    productId: number | undefined,
    quantity: string,
    price: number | undefined,
  ): string => {
    const product = products?.find(product => product.id === productId)
    if (!product) return ''

    const actualPrice = price ?? product.price

    return convertNumberToMoney(
      actualPrice * convertUnitValueFromServer(Number(quantity), product?.unit),
    )
  }

  return getProductAmount
}

export const useGetConsumableAmount = () => {
  const branchId = store.branch.branchId

  return useGetConsumableAmountInBranch(branchId)
}

export const useGetConsumablesTotalAmountInBranch = (branchId: number | undefined) => {
  const { data: products } = useFetchProducts(branchId)

  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const getTotalAmount = (consumables: Consumable[]): string => {
    const totalAmount = consumables.reduce((acc, consumable) => {
      if (!consumable.productId || !products) return acc

      const price = consumable.price ?? findById(consumable.productId, products)?.price ?? 0
      const unit = findById(consumable.productId, products)?.unit ?? 0

      return acc + price * convertUnitValueFromServer(Number(consumable.quantity), unit)
    }, 0)

    return convertNumberToMoney(totalAmount ?? 0)
  }

  return getTotalAmount
}

export const useGetConsumablesTotalAmount = () => {
  const branchId = store.branch.branchId

  return useGetConsumablesTotalAmountInBranch(branchId)
}
