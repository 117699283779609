import {
  BranchWithSchedule,
  CardTemplateWithSubscriptionInfoType,
  ServerExtendedInterbranchServiceType,
  ServerExtendedServiceType,
  ServerServiceGroupType,
  useArchiveCard,
  useCreateSubscriptionTemplate,
  useUpdateCardTemplateName,
  useUpdateSubscriptionTemplate,
} from '@expane/data'
import { useCheckIsOneBranchInBusiness } from '@expane/logic/branch'
import { getActiveSubscriptions } from '@expane/logic/cards'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  NumberInput,
  PlaceholderDialog,
  PlaceholderInput,
  Switch,
  useShowConfirmationPopup,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ArchiveButton, DuplicateButton, RestoreButton, SaveButton } from 'widgets/Buttons'
import {
  getDirtyFields,
  SubscriptionFormValues,
  SubscriptionServiceDto,
  transformCardServiceDefaultValueTreeItem,
} from './logic'
import { SubscriptionServices, SubscriptionServicesPlaceholder } from './SubscriptionServices'
import { checkIfNameWasNotChanged } from '@expane/logic/utils'

interface SubscriptionDialogLogicProps {
  currentBranch: BranchWithSchedule
  subscriptionById: CardTemplateWithSubscriptionInfoType | undefined
  closeDialog: () => void
  services: ServerExtendedServiceType[]
  interbranchServices: ServerExtendedInterbranchServiceType[]
  serviceGroups: ServerServiceGroupType[]
  isCreate: boolean
}

export const SubscriptionDialogLogic: FC<SubscriptionDialogLogicProps> = ({
  subscriptionById,
  currentBranch,
  closeDialog,
  services,
  interbranchServices,
  serviceGroups,
  isCreate,
}) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const { isOneBranchInBusiness } = useCheckIsOneBranchInBusiness()
  const { data: myPermissions } = useFetchMyPermissions()
  const { mutateAsync: createMutation } = useCreateSubscriptionTemplate()
  const { mutateAsync: updateMutation } = useUpdateSubscriptionTemplate()
  const { mutateAsync: updateNameMutation } = useUpdateCardTemplateName()
  const { mutateAsync: updateArchiveMutation, isLoading: isLoadingOnArchive } = useArchiveCard()

  const defaultCardTemplateServices: SubscriptionServiceDto[] =
    subscriptionById?.cardTemplateServices.map(cS => ({
      serviceItem: transformCardServiceDefaultValueTreeItem(
        cS,
        subscriptionById?.availableToAllBranches ? interbranchServices : services,
      ),
      servicePrice: cS.servicePrice.toString(),
      serviceQuantity: cS.serviceQuantity.toString(),
    })) ?? [{ serviceItem: undefined, servicePrice: '0', serviceQuantity: '1' }]

  const { formState, control, handleSubmit, setValue } = useForm<SubscriptionFormValues>({
    defaultValues: {
      name: subscriptionById?.name ?? '',
      price: subscriptionById?.price.toString() ?? '',
      cardPeriod: subscriptionById?.cardPeriod.toString() ?? '',
      cardTemplateServices: defaultCardTemplateServices,
      availableToAllBranches: subscriptionById?.availableToAllBranches ?? false,
    },
  })

  const { dirtyFields } = useFormState({
    control,
  })

  const watchedAvailableToAllBranches = useWatch({ control, name: 'availableToAllBranches' })

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('subscriptionWarnings.canNotChange'),
  )

  const { warningModal: warningCopyModal, showWarningPopup: showCopyWarningPopup } =
    useShowWarningPopup(t('warning'), t('subscriptionWarnings.canNotCopy'))

  const { warningModal: warningArchiveModal, showWarningPopup: showArchiveWarningPopup } =
    useShowWarningPopup(t('warning'), t('subscriptionWarnings.canNotArchive'))

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const isEditingAllowed = myPermissions?.includes(permissions.card.set)

  const activeCardsByCurrentCardTemplate = getActiveSubscriptions(
    subscriptionById?.cards ?? [],
    currentBranch.timezone,
  )
  const areActiveCardsByCurrentCardTemplate = Boolean(activeCardsByCurrentCardTemplate.length)
  const areCardsByCurrentCardTemplate = Boolean(subscriptionById?.cards.length)

  const archived = Boolean(subscriptionById?.archived)

  const mutateSubscription: SubmitHandler<SubscriptionFormValues> = async ({
    name,
    price,
    cardPeriod,
    cardTemplateServices,
    isItCopy,
    availableToAllBranches,
  }) => {
    if (subscriptionById && !isItCopy) {
      const result = await updateMutation({
        id: subscriptionById.id,
        cardTemplateSetInput: { name, price: Number(price), cardPeriod: Number(cardPeriod) },
        cardTemplateServiceInsertInputs: cardTemplateServices.map(
          ({ serviceItem, serviceQuantity, servicePrice }) => ({
            serviceId: serviceItem?.id,
            serviceQuantity: Number(serviceQuantity),
            servicePrice: Number(servicePrice),
            cardTemplateId: subscriptionById.id,
          }),
        ),
      })

      if (result.updateCardTemplateById?.id) {
        openSnackbar(t('subscription.updatedSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else {
      const result = await createMutation({
        name,
        price: Number(price),
        cardPeriod: Number(cardPeriod),
        cardTemplateServices: {
          data: cardTemplateServices.map(({ servicePrice, serviceQuantity, serviceItem }) => ({
            servicePrice: Number(servicePrice),
            serviceQuantity: Number(serviceQuantity),
            serviceId: serviceItem?.id,
          })),
        },
        branchId: availableToAllBranches ? undefined : branchId,
        availableToAllBranches,
      })

      if (result?.insertCardTemplate?.id) {
        openSnackbar(t('subscription.createdSuccessfully'), 'success')
      } else {
        openSnackbar(t('submitError'), 'error')
      }
    }

    closeDialog()
  }

  const mutateArchiveSubscription = async (archived: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await updateArchiveMutation({ id: subscriptionById!.id, archived })

    if (result?.updateCardTemplateById?.id)
      openSnackbar(
        archived
          ? t('subscription.archivingSuccessfully')
          : t('subscription.restorationSuccessfully'),
        'success',
      )
    else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const { archiveConfirmationModal, showArchiveConfirmationPopup } =
    useShowArchiveConfirmationPopup(`${subscriptionById?.name}`, () =>
      mutateArchiveSubscription(true),
    )

  const handleArchiveProduct = () => {
    if (areActiveCardsByCurrentCardTemplate) showArchiveWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const mutateNameSubscription = async ({ id, name }: { id: number; name: string }) => {
    const result = await updateNameMutation({ id, name })

    if (result.updateCardTemplatesName.affected_rows) {
      openSnackbar(t('subscription.nameUpdatedSuccessfully'), 'success')
    } else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const handleOnSave: SubmitHandler<SubscriptionFormValues> = data => {
    // если создание или если редактируется абонемент, который не был продан клиенту
    if (isCreate || (subscriptionById && !areCardsByCurrentCardTemplate)) {
      mutateSubscription(data)
    }
    // везде в условиях происходят действия с абонементами, которые уже были ПРОДАНЫ клиентам
    else if (subscriptionById) {
      // TODO: не учитывается проверка cardTemplateServices, если изменить название и поля в услугах, то срабатывает mutateNameSubscription без showConfirmation
      // TODO: поправить тут и улучшить проверку на моб
      const { isNameDirty, areFieldsExceptNameDirty } = getDirtyFields(dirtyFields)

      if (isNameDirty && !areFieldsExceptNameDirty && areCardsByCurrentCardTemplate)
        mutateNameSubscription({ id: subscriptionById.id, name: data.name })

      // если не меняется название абонемента и при этом меняются другие поля,
      // показываем ворнинг что мы не можем обновить такой абонемент (так как мы можем менять только имя)
      if (!isNameDirty && areCardsByCurrentCardTemplate) showWarningPopup()

      // если мы меняем и название и другие поля, то показываем диалог подтверждения, что мы поменяем только название
      if (isNameDirty && areFieldsExceptNameDirty && areCardsByCurrentCardTemplate) {
        // ранее есть проверка на isCreate, значит абонемент есть
        showConfirmation({
          title: t('warning'),
          description: t('subscriptionWarnings.onlyNameWillBeChanged'),
          onConfirm: () => mutateNameSubscription({ id: subscriptionById.id, name: data.name }),
        })
      }
    }
  }

  const watchedName = useWatch({ control, name: 'name' })

  const handleOnCopy: SubmitHandler<SubscriptionFormValues> = data => {
    const { isNameDirty, areFieldsExceptNameDirty } = getDirtyFields(dirtyFields)

    // если не поменялось название мы не можем создать копию
    if (!isNameDirty && areFieldsExceptNameDirty) showCopyWarningPopup()
    else mutateSubscription(data)
  }

  const disabledSaveButtons = formState.isSubmitting || !formState.isDirty || isLoadingOnArchive
  const disabledArchiveButton = formState.isSubmitting || isLoadingOnArchive

  const showSaveButtons = isEditingAllowed && !archived

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !disabledSaveButtons) handleSubmit(handleOnSave)()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('subscription.name')}</Dialog.Title>

          <Dialog.Body className="w-240 h-112 flex flex-col">
            <div className="flex justify-start gap-2">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('placeholders.subscriptionName')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed || archived}
                    containerClassName="w-2/5"
                    autoFocus
                  />
                )}
              />

              <Controller
                name="cardPeriod"
                control={control}
                rules={{ required: true, validate: checkOnlyPositiveAmount }}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    label={t('validityDays')}
                    placeholder={PLACEHOLDERS.subscriptionDurations}
                    containerClassName="w-1/5"
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed || archived}
                    hint={t('subscription.validityDaysHint')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.cardPeriod),
                      text:
                        formState.errors?.cardPeriod?.type === 'required'
                          ? t('formError.required')
                          : t('formError.invalidValue'),
                    }}
                    allowDecimals={false}
                  />
                )}
              />

              {!isOneBranchInBusiness && (
                <Controller
                  name="availableToAllBranches"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      label={t('availableForAllBranches')}
                      onChange={isChecked => {
                        setValue('cardTemplateServices', [
                          { serviceItem: undefined, servicePrice: '0', serviceQuantity: '1' },
                        ])
                        onChange(isChecked)
                      }}
                      disabled={!isCreate || !isEditingAllowed || archived}
                      checked={value}
                    />
                  )}
                />
              )}
            </div>

            {watchedAvailableToAllBranches ? (
              <SubscriptionServices
                control={control}
                setValue={setValue}
                services={interbranchServices}
                serviceGroups={[]}
                disabled={!isEditingAllowed || archived}
                branchId={branchId}
              />
            ) : (
              <SubscriptionServices
                control={control}
                setValue={setValue}
                services={services}
                serviceGroups={serviceGroups}
                disabled={!isEditingAllowed || archived}
                branchId={branchId}
              />
            )}

            <Controller
              name="price"
              control={control}
              rules={{ required: true, validate: checkOnlyPositiveAmount }}
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  className="text-right"
                  label={t('subscription.price')}
                  placeholder={PLACEHOLDERS.servicePrice}
                  containerClassName="w-1/5 mt-3 ml-auto"
                  value={value.toString()}
                  onChange={onChange}
                  disabled={!isEditingAllowed || archived}
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.price),
                    text:
                      formState.errors?.price?.type === 'required'
                        ? t('formError.required')
                        : t('formError.invalidValue'),
                  }}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {showSaveButtons && (
              <SaveButton
                onClick={handleSubmit(data => handleOnSave({ ...data, isItCopy: false }))}
                disabled={disabledSaveButtons}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            {!isCreate && showSaveButtons && (
              <DuplicateButton
                onClick={handleSubmit(data => handleOnCopy({ ...data, isItCopy: true }))}
                disabled={
                  formState.isSubmitting ||
                  checkIfNameWasNotChanged(subscriptionById?.name ?? '', watchedName)
                }
              />
            )}
            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />

            {isEditingAllowed &&
              !isCreate &&
              (subscriptionById?.archived ? (
                <RestoreButton
                  className="mr-auto"
                  onClick={() => mutateArchiveSubscription(false)}
                  disabled={disabledArchiveButton}
                  spinner={isLoadingOnArchive}
                />
              ) : (
                <ArchiveButton
                  className="mr-auto"
                  onClick={handleArchiveProduct}
                  disabled={disabledArchiveButton}
                  spinner={isLoadingOnArchive}
                />
              ))}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {warningModal}
      {warningCopyModal}
      {warningArchiveModal}

      {confirmPopup}
      {confirmationModal}
      {archiveConfirmationModal}
    </>
  )
}

export const SubscriptionDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog
      title={t('subscription.name')}
      className="w-240 h-112 flex flex-col"
      closeDialog={closeDialog}
    >
      <div className="flex justify-start mb-4">
        <PlaceholderInput label={t('title')} className="w-2/5 mr-2" />

        <PlaceholderInput label={t('subscription.branchesLabel')} className="w-2/5 mr-2" />

        <PlaceholderInput label={t('validityDays')} className="w-1/5" />
      </div>

      <SubscriptionServicesPlaceholder />

      <PlaceholderInput
        label={t('subscription.price')}
        className="w-1/5 mt-3 ml-auto"
        isRight={true}
      />
    </PlaceholderDialog>
  )
}
