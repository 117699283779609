import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { PlaceholderString, TableCell, TableRow } from '@expane/ui'
import { FC } from 'react'

export const ListItem: FC<
  PropsWithBranchId<{
    title?: string
    value?: number
    className?: string
    isTitle?: boolean
  }>
> = ({ title, value, className, isTitle = false, branchId }) => {
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  let styleTitle = 'text-sm '
  let styleValue = 'text-sm text-right '

  if (isTitle) styleTitle += 'font-medium'
  if (isTitle) styleValue += 'font-medium'

  if (className) styleTitle += className

  return (
    <TableRow>
      <TableCell>
        <div className={styleTitle}>{title ?? <PlaceholderString />}</div>
      </TableCell>
      <TableCell>
        <div className={styleValue}>
          {value !== undefined ? (
            convertNumberToMoney(value)
          ) : (
            <PlaceholderString width="small" className="ml-auto" />
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}
