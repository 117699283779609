import { NOTIFICATION_STATUS, NotificationUnionType } from '@expane/data'
import { Dispatch, FC, SetStateAction } from 'react'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { Checkbox, Tag } from '@expane/ui'
import { useTranslation } from 'react-i18next'
import { checkIsNotificationSelected, getTagProps } from './logic'

interface NotificationItemHeaderProps {
  notification: NotificationUnionType
  selectedNotifications: Array<number>
  setNotifications: Dispatch<SetStateAction<Array<number>>>
}

export const NotificationItemHeader: FC<NotificationItemHeaderProps> = ({
  notification,
  setNotifications,
  selectedNotifications,
}) => {
  const { t } = useTranslation()

  const dateFormatting = useDateFormatting()

  const isSelected = checkIsNotificationSelected(selectedNotifications, notification.id)

  const tagProps = getTagProps(notification, t)

  return (
    <div className="flex items-center border-b border-input-disabled-color pb-1 h-6">
      {notification.status !== NOTIFICATION_STATUS.read && (
        <Checkbox
          checked={isSelected}
          onChange={e => {
            e.stopPropagation()
            if (isSelected) {
              setNotifications(ids => [...ids.filter(id => id !== notification.id)])
            } else setNotifications(ids => [...ids, notification.id])
          }}
        />
      )}

      <div className="flex w-full items-center">
        {tagProps && <Tag {...tagProps} />}

        <p className="ml-auto text-xs text-gray-500 dark:text-gray-400 ml-1">
          {dateFormatting('historyDateTime', notification.sentAt)}
        </p>
      </div>
    </div>
  )
}
