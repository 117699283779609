import { useFetchCurrentBranchTimezone, usePaySalary } from '@expane/data'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { CountedSalariesFullInfoByEmployeeType } from '@expane/logic/salaryIssues/logic'
import { convertDebtSalariesToServer } from '@expane/logic/salaryIssues/server'
import {
  Button,
  CloseButton,
  Dialog,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
  usePopupOpenState,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import {
  SHOW_MANAGING_EMPLOYEE_TITLE,
  useShowSalaryIssuesTitleByBusinessSettings,
} from 'logic/hooks/useShowSalaryIssuesTitleByBusinessSettings'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkDoneOutline } from 'react-icons/io5'
import { store } from 'store'
import { SalaryIssueTotal } from 'widgets/SalaryIssueDialog/SalaryIssueTotal'

interface SalaryDebtIssueDialogProps {
  closeDialog: () => void
  employeeId: number
  countedSalariesFullInfo: Array<CountedSalariesFullInfoByEmployeeType>
}

export interface IssueSalaryDebtDialogFormValues {
  accountId: number
}

export const SalaryDebtIssueDialog: FC<SalaryDebtIssueDialogProps> = observer(
  ({ closeDialog, employeeId, countedSalariesFullInfo }) => {
    const branchId = store.branch.branchId
    const timezone = useFetchCurrentBranchTimezone(branchId)

    const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

    const { t } = useTranslation()

    const { mutateAsync: paySalary } = usePaySalary()

    const { control, formState, handleSubmit } = useForm<IssueSalaryDebtDialogFormValues>()

    const [openSnackbar] = useSnackbar()

    const employeeCountedSalariesFullInfo = countedSalariesFullInfo.find(
      salary => salary.employeeId === employeeId,
    )

    const totalSum = employeeCountedSalariesFullInfo?.debtSalary.totalSum ?? 0

    const submitHandler: SubmitHandler<IssueSalaryDebtDialogFormValues> = async ({ accountId }) => {
      if (!employeeCountedSalariesFullInfo || !timezone) {
        openSnackbar(t('submitError'), 'error')
        closeDialog()
        return
      }

      const employeeIds = [employeeCountedSalariesFullInfo.employeeId]

      const { requestData, startPeriod, endPeriod } = convertDebtSalariesToServer(
        employeeCountedSalariesFullInfo.debtSalary,
        timezone,
      )

      if (startPeriod > endPeriod) {
        openSnackbar(t('submitError'), 'error')
        closeDialog()
        return
      }

      const result = await paySalary({
        accountId,
        startPeriod,
        endPeriod,
        salaryRateIssues: requestData,
        employeeIds,
      })

      if (result?.paySalary?.success) openSnackbar(t('salary.salaryIssuedSuccessfully'), 'success')
      else openSnackbar(t('submitError'), 'error')

      closeDialog()
    }

    const { tableTitlesByShowingManagingEmployee, showManagingEmployee } =
      useShowSalaryIssuesTitleByBusinessSettings(titles)

    return (
      <Modal close={closeDialog}>
        <Dialog>
          <Dialog.Title>{t('salary.paymentOfDebt')}</Dialog.Title>
          <Dialog.Body className="w-148">
            <TableContainer>
              <TableHeader>
                <tr>
                  {tableTitlesByShowingManagingEmployee.map(({ title, width, isAlignRight }) => (
                    <TableHeaderCell
                      key={title}
                      className={isAlignRight ? `${width} text-right` : width}
                    >
                      {t(title)}
                    </TableHeaderCell>
                  ))}
                </tr>
              </TableHeader>

              <TableBody>
                <TableRow>
                  <TableCell className="text-right">
                    {convertNumberToMoney(
                      employeeCountedSalariesFullInfo?.debtSalary.notPaidSalaryRate ?? 0,
                    )}
                  </TableCell>
                  <TableCell className="text-right">
                    {convertNumberToMoney(
                      employeeCountedSalariesFullInfo?.debtSalary.notPaidServices ?? 0,
                    )}
                  </TableCell>
                  <TableCell className="text-right">
                    {convertNumberToMoney(
                      employeeCountedSalariesFullInfo?.debtSalary.notPaidPremiums ?? 0,
                    )}
                  </TableCell>
                  {showManagingEmployee && (
                    <TableCell className="text-right">
                      {convertNumberToMoney(
                        employeeCountedSalariesFullInfo?.debtSalary.notPaidReferralPercentages ?? 0,
                      )}
                    </TableCell>
                  )}
                  <TableCell className="text-right">
                    {convertNumberToMoney(
                      employeeCountedSalariesFullInfo?.debtSalary.totalSum ?? 0,
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </TableContainer>

            <SalaryIssueTotal totalNotPaidSalaries={totalSum} control={control} />
          </Dialog.Body>

          <Dialog.Footer>
            <Button
              onClick={handleSubmit(submitHandler)}
              disabled={formState.isSubmitting || totalSum <= 0}
              spinner={formState.isSubmitting}
              Icon={IoCheckmarkDoneOutline}
            >
              {t('issue')}
            </Button>

            <CloseButton onClick={closeDialog} />
          </Dialog.Footer>
        </Dialog>
      </Modal>
    )
  },
)

export const useOpenSalaryDebtIssueDialog = (
  props: Omit<SalaryDebtIssueDialogProps, 'closeDialog' | 'employeeId'>,
) => {
  const employeeId = useRef<number | undefined>()

  const openSalaryDebtIssueDialog = (id: number) => {
    employeeId.current = id
    openPopup()
  }

  const { isOpen, closePopup, openPopup } = usePopupOpenState()

  const salaryDebtIssueDialog =
    isOpen && employeeId.current ? (
      <SalaryDebtIssueDialog closeDialog={closePopup} employeeId={employeeId.current} {...props} />
    ) : null

  return {
    salaryDebtIssueDialog,
    openSalaryDebtIssueDialog,
  }
}

const titles: { title: string; width: string; isAlignRight?: boolean }[] = [
  { title: 'salary.rate', width: 'w-40', isAlignRight: true },
  { title: 'salary.forServices', width: 'w-40', isAlignRight: true },
  { title: 'salary.premiumsPenalties', width: 'w-40', isAlignRight: true },
  { title: SHOW_MANAGING_EMPLOYEE_TITLE, width: 'w-40', isAlignRight: true },
  { title: 'totalAmount', width: 'w-40', isAlignRight: true },
]
