import { EmployeeByIdExtended, useFetchProductsForSale } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol, useGetCurrencySymbol } from '@expane/logic/currency'
import { ProductsAmount } from '@expane/logic/movement'
import {
  convertUnitValueFromServer,
  getUnitIdForConsumable,
  getUnitNameById,
  useGetUnitsNameForConsumable,
} from '@expane/logic/product'
import { ProductItemForPayment } from '@expane/logic/quickSale/types'
import { calcDiscountSum, findById } from '@expane/logic/utils'
import {
  DeleteButtonCell,
  ErrorMessage,
  ErrorMessageType,
  Input,
  TableCell,
  TableRow,
} from '@expane/ui'
import { TFunction } from 'i18next'
import { FC } from 'react'
import { Control, Controller, FieldErrors, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DiscountInput } from 'ui/DiscountInput'
import {
  getAmountOfFixedDiscount,
  getDiscountErrorTextMessage,
  SubmitQuickSaleDialogFormValues,
} from '../../logic'

type Props = {
  index: number
  item: ProductItemForPayment
  control: Control<SubmitQuickSaleDialogFormValues>
  onDelete: () => void
  leftoverProducts: ProductsAmount
  isDiscountForEveryone: boolean
  myEmployee: EmployeeByIdExtended | undefined
}

export const ProductSaleItem: FC<PropsWithBranchId<Props>> = ({
  control,
  index,
  onDelete,
  item,
  leftoverProducts,
  isDiscountForEveryone,
  myEmployee,
  branchId,
}) => {
  const { t } = useTranslation()
  const getUnitName = useGetUnitsNameForConsumable(branchId)

  const currencySymbol = useGetCurrencySymbol(branchId)
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const { data: products } = useFetchProductsForSale(branchId)

  const maxDiscount = myEmployee?.employeeGroup?.maxDiscount ?? 0

  const { errors } = useFormState({ control })

  const watchedQuantity = useWatch({ control, name: `productItems.${index}.quantity` })

  const unit = products ? findById(item.id, products)?.unit : undefined

  const quantity = convertUnitValueFromServer(watchedQuantity ?? 1, unit ?? 0)

  const totalItemPrice = item.price * (quantity ?? 1)

  const watchedDiscount = useWatch({ control, name: `productItems.${index}.discount` })
  const discountSum = calcDiscountSum({ discount: watchedDiscount, price: totalItemPrice })

  const leftover = leftoverProducts[item.id]

  return (
    <>
      <TableRow>
        <TableCell>{item.name}</TableCell>
        <TableCell className="text-right">
          {convertNumberToMoney(convertUnitValueFromServer(1, unit ?? 0) * item.price)}
        </TableCell>
        <TableCell>
          <Controller
            name={`productItems.${index}.quantity` as 'productItems.0.quantity'}
            control={control}
            rules={{
              validate: {
                greaterThanZero: value => (value ?? 0) > 0,
                leftover: value => {
                  if (!value || !leftover) return false
                  return value <= leftover
                },
                integer: value => Number.isInteger(value),
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                type="number"
                value={value?.toString()}
                onChange={e => {
                  onChange(Number(e.target.value))
                }}
                height="small"
                min="0"
                errorMessage={{
                  isShown: Boolean(error),
                  text: '',
                  reserveIndent: false,
                }}
              />
            )}
          />
        </TableCell>
        <TableCell>{t(getUnitName(item.id))}</TableCell>
        <TableCell>
          <Controller
            name={`productItems.${index}.discount` as 'productItems.0.discount'}
            control={control}
            rules={{
              validate: value => {
                if (value?.type === 'percent') {
                  return Number(value.value) <= maxDiscount
                }
                if (value?.type === 'fixed') {
                  return (
                    Number(value.value) <=
                    getAmountOfFixedDiscount({ price: totalItemPrice, maxDiscount })
                  )
                }
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <DiscountInput
                value={value}
                onChange={e => {
                  if (e.type === 'percent') {
                    if (Number(e.value) >= 0 && Number(e.value) <= 100)
                      onChange({ value: e.value, type: e.type })
                  }

                  if (e.type === 'fixed') {
                    if (Number(e.value) <= totalItemPrice)
                      onChange({ value: e.value, type: e.type })
                  }
                }}
                disabled={isDiscountForEveryone || !maxDiscount}
                height="small"
                errorMessage={{
                  isShown: Boolean(error),
                  text: getDiscountErrorTextMessage({
                    price: totalItemPrice,
                    discount: value,
                    t,
                    currencySymbol,
                    maxDiscount,
                  }),
                  reserveIndent: Boolean(error),
                }}
                disabledReason={
                  !maxDiscount && !isDiscountForEveryone ? t('discountPermission') : undefined
                }
              />
            )}
          />
        </TableCell>
        <TableCell className="text-right">
          {convertNumberToMoney(totalItemPrice - discountSum)}
        </TableCell>
        <DeleteButtonCell onClick={onDelete} />
      </TableRow>
      {errors.productItems?.[index] && (
        <TableRow>
          <td colSpan={7}>
            <ErrorMessage
              className="pl-4"
              errorMessage={generateQuantityErrorMessage({ errors, index, unit, leftover, t })}
            />
          </td>
        </TableRow>
      )}
    </>
  )
}

const generateQuantityErrorMessage = ({
  errors,
  unit,
  leftover,
  index,
  t,
}: {
  errors: FieldErrors<SubmitQuickSaleDialogFormValues>
  index: number
  unit: number | undefined
  leftover: number
  t: TFunction
}): ErrorMessageType => {
  const error = errors.productItems?.[index]?.quantity
  let errorMessage: string | undefined
  if (error?.type === 'integer') errorMessage = t('formError.integersOnly')
  if (error?.type === 'greaterThanZero') errorMessage = t('formError.invalidValue')
  if (error?.type === 'leftover') {
    // leftover приходит в наименьшей единице измерения, поэтому конвертация в единицу измерения товара не требуется
    // В продажах используются наименьшие единицы измерения
    const unitConsumableId = getUnitIdForConsumable(unit ?? 0)
    const unitName = getUnitNameById(unitConsumableId)

    errorMessage = `${t('leftover')}: ${leftover} ${t(unitName)}`
  }

  return { isShown: Boolean(errorMessage), text: errorMessage }
}
