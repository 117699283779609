/* eslint-disable  @typescript-eslint/no-non-null-assertion */
export const config = {
  VERSION: '0.81.0',
  ENV: process.env.REACT_APP_ENV!, // development, production
  LOCAL_RUN: process.env.NODE_ENV === 'development',
  HASURA_ENDPOINT: process.env.REACT_APP_HASURA_ENDPOINT!,
  // Firebase credentials
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY!,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT: process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT!,
  FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  FIREBASE_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID!,
  MEASUREMENT_ID: process.env.REACT_APP_MEASUREMENT_ID!,
  //WayForPay
  WAYFORPAY_SECRET: process.env.REACT_APP_WAYFORPAY_SECRET!,
  WAYFORPAY_MERCHANT_ACCOUNT: process.env.REACT_APP_WAYFORPAY_MERCHANT_ACCOUNT!,
  // === etc ===
  PHOTO_PLACEHOLDER_URL: '/img/photo_placeholder.jpg',
}
