import { gql } from 'graphql-request'

export const subscriptionFragment = gql`
  fragment subscriptionType on card {
    id
    activatedAt
    canceledDate
    subscriptionInfo
    cardPeriod
    cardTemplate {
      id
      name
      price
      cardTemplateServices {
        servicePrice
        serviceQuantity
        service {
          id
          name
          serviceEmployees {
            id
          }
        }
      }
    }
    type
    code
  }
`

export const giftCardFragment = gql`
  fragment giftCardType on card {
    id
    type
    code
    activatedAt
    canceledDate
    cardPeriod
    createdAt
    cardTemplate {
      id
      name
      price
    }
  }
`

export const cardFragment = gql`
  fragment cardType on card {
    id
    activatedAt
    canceledDate
    createdAt
    clientId
    transactionCards {
      transactionId
    }
    subscriptionInfo
    cardPeriod
    cardTemplate {
      id
      name
      price
      cardPeriod
      cardTemplateServices {
        servicePrice
        serviceQuantity
        serviceId
        service {
          id
          name
        }
      }
    }
    type
    code
  }
`
