import { EmployeeByIdExtended } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol, useGetCurrencySymbol } from '@expane/logic/currency'
import { checkOnlyPositiveAmount } from '@expane/logic/form'
import { ServiceItemForPayment } from '@expane/logic/quickSale/types'
import { calcDiscountSum } from '@expane/logic/utils'
import { DeleteButtonCell, Input, TableCell, TableRow } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DiscountInput } from 'ui/DiscountInput'
import {
  getAmountOfFixedDiscount,
  getDiscountErrorTextMessage,
  SubmitQuickSaleDialogFormValues,
} from '../../logic'

type Props = {
  index: number
  control: Control<SubmitQuickSaleDialogFormValues>
  service: ServiceItemForPayment
  isDiscountForEveryone: boolean
  onDelete: () => void
  myEmployee: EmployeeByIdExtended | undefined
}

export const ServiceSaleItem: FC<PropsWithBranchId<Props>> = ({
  onDelete,
  service,
  control,
  index,
  isDiscountForEveryone,
  myEmployee,
  branchId,
}) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)
  const currencySymbol = useGetCurrencySymbol(branchId)

  const maxDiscount = myEmployee?.employeeGroup?.maxDiscount ?? 0

  const watchedQuantity = useWatch({ control, name: `serviceItems.${index}.quantity` })

  const totalPrice = service.price * watchedQuantity

  const watchedDiscount = useWatch({ control, name: `serviceItems.${index}.discount` })
  const discountSum = calcDiscountSum({ discount: watchedDiscount, price: totalPrice })

  return (
    <TableRow>
      <TableCell>{service.name}</TableCell>
      <TableCell className="text-right">{convertNumberToMoney(service.price)}</TableCell>
      <TableCell>
        <Controller
          control={control}
          name={`serviceItems.${index}.quantity`}
          rules={{
            validate: { checkOnlyPositiveAmount, integer: value => Number.isInteger(value) },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              type="number"
              value={value.toString()}
              onChange={e => {
                onChange(Number(e.target.value))
              }}
              height="small"
              errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
            />
          )}
        />
      </TableCell>
      <TableCell />
      <TableCell>
        <Controller
          control={control}
          name={`serviceItems.${index}.discount`}
          rules={{
            validate: value => {
              if (value?.type === 'percent') {
                return Number(value.value) <= maxDiscount
              }
              if (value?.type === 'fixed') {
                return (
                  Number(value.value) <=
                  getAmountOfFixedDiscount({ price: totalPrice, maxDiscount })
                )
              }
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DiscountInput
              value={value}
              onChange={e => {
                if (e.type === 'percent') {
                  if (Number(e.value) >= 0 && Number(e.value) <= 100)
                    onChange({ value: e.value, type: e.type })
                }

                if (e.type === 'fixed') {
                  if (Number(e.value) <= totalPrice) onChange({ value: e.value, type: e.type })
                }
              }}
              disabled={isDiscountForEveryone || !maxDiscount}
              height="small"
              errorMessage={{
                isShown: Boolean(error),
                text: getDiscountErrorTextMessage({
                  price: totalPrice,
                  discount: value,
                  t,
                  currencySymbol,
                  maxDiscount,
                }),
                reserveIndent: Boolean(error),
              }}
              disabledReason={
                !maxDiscount && !isDiscountForEveryone ? t('discountPermission') : undefined
              }
            />
          )}
        />
      </TableCell>
      <TableCell className="text-right">{convertNumberToMoney(totalPrice - discountSum)}</TableCell>
      <DeleteButtonCell onClick={onDelete} />
    </TableRow>
  )
}
