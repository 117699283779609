import {
  useFetchClientsPhones,
  useFetchCurrentBranchTimezone,
  useFetchWaitingLists,
} from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import {
  addPhonesToClientsInWaitingList,
  WaitingListWithClientPhones,
} from '@expane/logic/waitingList'
import { EmptyPlaceholder, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoListOutline } from 'react-icons/io5'
import { store } from 'store'
import { Phone } from 'ui/Phone'
import { WaitingListConvenientTimes } from 'widgets/WaitingListDialog/WaitingListConvenientTimes'

export const WaitingList: FC<{ onRowClick: (waitingList: { id: number }) => void }> = memo(
  ({ onRowClick }) => {
    const { t } = useTranslation()

    const branchId = store.branch.branchId
    const timezone = useFetchCurrentBranchTimezone(branchId)

    const formatDate = useDateFormatting()
    const columns = useMemo<ColumnDef<WaitingListWithClientPhones>[]>(
      () => [
        {
          accessorKey: 'createdAt',
          header: t('dateOfCreation'),
          cell: data => formatDate('historyDateTime', data.getValue<Date>()),
        },
        {
          id: 'clientFullName',
          accessorFn: waitingList => transformPersonName(waitingList.client),
          header: t('client.name'),
          cell: data => data.getValue(),
          size: 200,
        },
        {
          id: 'clientPhone',
          header: t('phone'),
          accessorFn: waitingList => waitingList.client.phone,
          cell: data => <Phone number={data.getValue<string>()} />,
          size: 200,
        },
        {
          id: 'service',
          header: t('service.name'),
          accessorFn: waitingList => waitingList.service.name,
          cell: data => data.getValue(),
        },
        {
          id: 'employeeFullName',
          header: t('employee.name'),
          accessorFn: waitingList =>
            waitingList.employee ? transformPersonName(waitingList.employee) : '-',
          cell: data => data.getValue(),
          size: 200,
        },
        {
          id: 'convenientTime',
          header: t('convenientTime'),
          accessorFn: waitingList => (
            <WaitingListConvenientTimes
              dates={waitingList.dates}
              timezone={timezone}
              withLabel={false}
            />
          ),
          cell: data => data.getValue(),
          size: 330,
        },
      ],
      [formatDate, t, timezone],
    )

    const { data: waitingLists, isLoading: isLoadingWaitingLists } = useFetchWaitingLists(
      timezone,
      branchId,
    )
    const { data: clientsPhones, isLoading: isLoadingClientsPhones } = useFetchClientsPhones()
    const isLoading = isLoadingClientsPhones || isLoadingWaitingLists

    if (waitingLists?.length === 0 && !isLoading)
      return <EmptyPlaceholder Icon={IoListOutline} text={t('emptyPlaceholder.waitingList')} />

    const waitingListsWithClientPhones = addPhonesToClientsInWaitingList(
      waitingLists,
      clientsPhones,
    )

    return (
      <Table
        columns={columns}
        data={waitingListsWithClientPhones}
        isLoading={isLoading}
        onRowClick={onRowClick}
      />
    )
  },
)
