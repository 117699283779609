import {
  EmployeeGroupWithSchedule,
  ServerAccountByIdType,
  ServerAccountType,
  ServerBranchType,
} from '@expane/data'
import {
  ACCOUNT_TYPES,
  filterAccountsWithoutChosen,
  getIsAccountWithPOS,
  isAccountForPayments,
} from '@expane/logic/accounts'
import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { permissions } from '@expane/logic/permission'
import {
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  NumberInput,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropdown,
  SelectDropDownItem,
  Switch,
  Textarea,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { translateData } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { MultiSelect } from 'ui/MultiSelect'
import { PlaceholderSwitch } from 'ui/PlaceholderContent'
import { AccountDialogFooter } from './AccountDialogFooter'

interface AccountDialogLogicProps {
  accountById: ServerAccountByIdType | undefined
  accounts: ServerAccountType[]
  employeeGroups: EmployeeGroupWithSchedule[]
  branch: ServerBranchType
  closeDialog: () => void
}

export const AccountDialogLogic: FC<AccountDialogLogicProps> = observer(
  ({ accountById, accounts, employeeGroups, branch, closeDialog }) => {
    const { t } = useTranslation()

    const { data: myPermission } = useFetchMyPermissions()

    const accountsWithoutThis = filterAccountsWithoutChosen(accounts, accountById?.id)

    const form = useForm<AccountsFormValues>({
      defaultValues: {
        name: accountById?.name ?? '',
        cash: accountById?.cash ?? false,
        commission: accountById?.commission?.toString() ?? '0',
        description: accountById?.description ?? '',
        forPayments: accountById?.forPayments ? ACCOUNT_TYPES[0].id : ACCOUNT_TYPES[1].id,
        employeeGroups: accountById?.accountEmployeeGroups?.map(({ employeeGroup }) => ({
          id: employeeGroup.id,
          name: employeeGroup.name,
        })),
        withdrawal: Boolean(accountById?.withdrawalToAccountId),
        withdrawalToAccountId: accountById?.withdrawalToAccountId ?? accountsWithoutThis?.[0]?.id,
        exchangeAmount: accountById?.exchangeAmount?.toString() ?? '',
        maximumDebt: accountById?.maximumDebt.toString() ?? '0',
      },
    })

    const submitForm = useRef<(() => void) | undefined>(undefined)

    const { control, formState, watch } = form

    const watchedCash = useWatch({ control, name: 'cash' })
    const watchedWithdrawal = useWatch({ control, name: 'withdrawal' })

    const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

    const isPermissionEditingAllowed =
      myPermission?.includes(permissions.rolePermission.set) ?? false

    const isDefaultAccount = branch?.defaultAccount?.id === accountById?.id

    const isEditingAllowed = myPermission?.includes(permissions.account.set) ?? false
    const isBranchDefaultsEditingAllowed = myPermission?.includes(permissions.branch.set)

    const watchedForPayments = watch('forPayments')
    const accountCanBeDefault = isAccountForPayments(watchedForPayments)
    const isShowSetDefaultAccountButton = Boolean(
      isBranchDefaultsEditingAllowed && !isDefaultAccount && accountCanBeDefault,
    )

    const isShowWithdrawal = getIsAccountWithPOS(accountById)

    const myEmployeeGroup = employeeGroups.find(eG =>
      eG.employees.find(e => e.id === store.me.employeeId),
    )

    return (
      <>
        <Modal close={closePopups} confirm={submitForm.current} animation="onlyFadeOut">
          <Dialog>
            <Dialog.Title>{t('account.name')}</Dialog.Title>

            <Dialog.Body className="w-192">
              <div className="flex gap-2">
                <div className="w-1/2">
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        label={t('title')}
                        placeholder={t('placeholders.accountName')}
                        required
                        errorMessage={{
                          isShown: Boolean(formState.errors.name),
                          text: t('formError.required'),
                        }}
                        value={value}
                        onChange={onChange}
                        disabled={!isEditingAllowed}
                        autoFocus
                      />
                    )}
                  />
                  <Controller
                    name="cash"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="mb-3">
                        <Switch
                          onChange={onChange}
                          checked={value}
                          disabled={!isEditingAllowed}
                          label={t('cash')}
                        />
                      </div>
                    )}
                  />

                  {!watchedCash && (
                    <div className="flex justify-between items-end">
                      <Controller
                        name="commission"
                        control={control}
                        rules={{ validate: checkOnlyPositiveAmount }}
                        render={({ field: { onChange, value } }) => (
                          <NumberInput
                            label={t('commissionPercentage')}
                            hint={t('commissionPercentageHint')}
                            placeholder={PLACEHOLDERS.accountCommission}
                            value={value}
                            onChange={onChange}
                            containerClassName="w-1/2 mr-2"
                            disabled={!isEditingAllowed}
                            errorMessage={{
                              isShown: Boolean(formState.errors.commission),
                              text: t('formError.invalidValue'),
                            }}
                          />
                        )}
                      />

                      <Controller
                        name="maximumDebt"
                        control={control}
                        rules={{ validate: checkOnlyPositiveAmount }}
                        render={({ field: { onChange, value } }) => (
                          <NumberInput
                            label={t('maximumDebt')}
                            hint={t('maximumDebtHint')}
                            placeholder={PLACEHOLDERS.accountMaximumDebt}
                            value={value}
                            onChange={onChange}
                            containerClassName="w-1/2"
                            disabled={!isEditingAllowed}
                            errorMessage={{
                              isShown: Boolean(formState.errors.maximumDebt),
                              text: t('formError.invalidValue'),
                            }}
                          />
                        )}
                      />
                    </div>
                  )}

                  {isShowWithdrawal && (
                    <>
                      <Controller
                        name="withdrawal"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <div className="flex items-center">
                            <Switch
                              onChange={onChange}
                              checked={value}
                              containerClassName="mr-3"
                              disabled={!isEditingAllowed}
                              label={t('autoWithdrawal')}
                              hint={t('autoWithdrawalHint')}
                            />
                          </div>
                        )}
                      />

                      {watchedWithdrawal && (
                        <div className="flex mt-3">
                          <Controller
                            name="withdrawalToAccountId"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <SelectDropdown
                                value={value}
                                label={t('toAccount')}
                                placeholder={t('placeholders.defaultSelect')}
                                items={accountsWithoutThis}
                                noDataMessage={t('noAccount')}
                                onSelectChange={onChange}
                                popupHeight="small"
                                errorMessage={{
                                  isShown: Boolean(formState.errors.withdrawalToAccountId),
                                  text: t('formError.required'),
                                }}
                                className="w-1/2 mr-2"
                                disabled={!isEditingAllowed}
                              />
                            )}
                          />

                          <Controller
                            name="exchangeAmount"
                            control={control}
                            rules={{ validate: checkOnlyPositiveAmount }}
                            render={({ field: { onChange, value } }) => (
                              <NumberInput
                                label={t('amountToExchange')}
                                hint={t('amountToExchangeHint')}
                                placeholder={PLACEHOLDERS.servicePrice}
                                value={value}
                                onChange={onChange}
                                containerClassName="w-1/2"
                                disabled={!isEditingAllowed}
                                errorMessage={{
                                  isShown: Boolean(formState.errors.exchangeAmount),
                                  text: t('formError.invalidValue'),
                                }}
                              />
                            )}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>

                <div className="w-1/2">
                  <Controller
                    name="forPayments"
                    control={control}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <SelectDropdown
                          label={t('accountType.name')}
                          items={translateData(ACCOUNT_TYPES, t)}
                          value={value}
                          onSelectChange={onChange}
                          disabled={!isEditingAllowed || isDefaultAccount}
                          hint={isDefaultAccount ? t('accountType.defaultAccountHint') : undefined}
                        />
                      )
                    }}
                  />
                  <Controller
                    control={control}
                    name="employeeGroups"
                    rules={{
                      validate: values => {
                        if (!values || values?.length === 0) return true
                        return Boolean(values.find(group => group.id === myEmployeeGroup?.id))
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <MultiSelect
                        label={t('employeeGroups.name')}
                        items={employeeGroups}
                        onItemSelect={onChange}
                        selectedItems={value as SelectDropDownItem[]}
                        dropdownInputPlaceholder=""
                        hint={t('employeeGroups.accountHint')}
                        disabled={
                          !isPermissionEditingAllowed || !isEditingAllowed || isDefaultAccount
                        }
                        errorMessage={{
                          isShown: Boolean(formState.errors.employeeGroups),
                          text: t('employeeGroups.error', { name: myEmployeeGroup?.name ?? '' }),
                        }}
                        className="mt-2"
                      />
                    )}
                  />

                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Textarea
                        label={t('description')}
                        placeholder={t('placeholders.accountDescription')}
                        value={value}
                        onChange={onChange}
                        rows={5}
                        disabled={!isEditingAllowed}
                      />
                    )}
                  />
                </div>
              </div>
            </Dialog.Body>

            <AccountDialogFooter
              form={form}
              showSetDefaultAccountButton={isShowSetDefaultAccountButton}
              branch={branch}
              accountId={accountById?.id}
              isEditingAllowed={isEditingAllowed}
              isPermissionEditingAllowed={isPermissionEditingAllowed}
              closePopups={closePopups}
              closeDialog={closeDialog}
              onSubmitForm={submitForm}
            />
          </Dialog>
        </Modal>

        {confirmPopup}
      </>
    )
  },
)

export const AccountDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('account.name')} className="w-192" closeDialog={closeDialog}>
      <div className="flex">
        <div className="w-1/2 mr-2 flex-none flex flex-col">
          <PlaceholderInput label={t('title')} className="mb-4" />

          <PlaceholderSwitch containerClassName="mr-3 mb-3" label={t('cash')} />

          <div className="flex justify-between items-end mb-4">
            <PlaceholderInput label={t('commissionPercentage')} className="w-1/2 mr-2" />
            <PlaceholderInput label={t('maximumDebt')} className="w-1/2" />
          </div>

          <PlaceholderSwitch containerClassName="mr-3" label={t('autoWithdrawal')} />
        </div>

        <div className="grow flex flex-col">
          <PlaceholderInput label={t('accountType.name')} />
          <PlaceholderInput label={t('employeeGroups.name')} className="mt-2 mb-4" />

          <PlaceholderTextarea label={t('description')} rows={5} />
        </div>
      </div>
    </PlaceholderDialog>
  )
}

export type AccountsFormValues = {
  name: string
  description: string
  cash: boolean
  commission: string
  forPayments: number
  employeeGroups: SelectDropDownItem[]
  withdrawal: boolean
  withdrawalToAccountId: number | undefined
  exchangeAmount: string
  maximumDebt: string
}
