import { useFetchProducts, useGetLeftovers } from '@expane/data'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { convertUnitValueFromServer, getUnitsName } from '@expane/logic/product'
import {
  generateProductsWithLeftoversList,
  isCriticalProductQuantity,
  ProductWithLeftover,
} from '@expane/logic/storages'
import { EmptyPlaceholder, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { observer } from 'mobx-react-lite'
import { allStoragesItem } from 'pages/StoragePage'
import { FC, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCubeOutline } from 'react-icons/io5'
import { store } from 'store'

interface StorageLeftoversListProps {
  storageId: number
  PrintWrapper: FC<
    PropsWithChildren<{
      className?: string
      onBeforePrint?: () => void
      onAfterPrint?: () => void
    }>
  >
}

export const StorageLeftoversList: FC<StorageLeftoversListProps> = observer(
  ({ storageId, PrintWrapper }) => {
    const branchId = store.branch.branchId

    const { data: products, isLoading: isProductsLoading } = useFetchProducts(branchId)
    const { data: leftovers, isLoading: isLoadingLeftovers } = useGetLeftovers(
      branchId,
      storageId !== allStoragesItem.id ? storageId : undefined,
    )

    const isLoading = isProductsLoading || isLoadingLeftovers

    const { t } = useTranslation()
    const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

    const columns = useMemo<ColumnDef<ProductWithLeftover>[]>(
      () => [
        {
          accessorKey: 'vendorCode',
          header: t('vendorCode'),
          cell: data => data.getValue(),
          size: 100,
        },
        {
          accessorKey: 'name',
          header: t('title'),
          cell: data => data.getValue(),
          size: 400,
        },
        {
          accessorKey: 'costPrice',
          header: () => <span className="w-full text-right">{t('costPrice')}</span>,
          cell: data => (
            <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
          ),
        },
        {
          accessorKey: 'price',
          header: () => <span className="w-full text-right">{t('price')}</span>,
          cell: data => (
            <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
          ),
        },
        {
          accessorKey: 'leftover',
          header: () => <span className="w-full text-right">{t('leftovers')}</span>,
          cell: data => (
            <div className="text-right">
              {convertUnitValueFromServer(data.getValue<number>(), data.row.original.unit)}
            </div>
          ),
        },
        {
          accessorKey: 'unit',
          header: t('unit.shortName'),
          cell: data => getUnitsName(data.getValue<number>(), t),
          size: 60,
        },
      ],
      [convertNumberToMoney, t],
    )

    const leftoversKeys = Object.keys(leftovers ?? {})

    if (leftoversKeys.length === 0 && !isLoading)
      return <EmptyPlaceholder Icon={IoCubeOutline} text={t('storage.noProducts')} />

    const items = generateProductsWithLeftoversList(products, leftovers ?? {})

    return (
      <PrintWrapper className="print:w-256 h-full">
        <Table
          containerClassName="max-h-full"
          columns={columns}
          data={items}
          isLoading={isLoading}
          customRowClassName={addCriticalRowStyles}
        />
      </PrintWrapper>
    )
  },
)

const addCriticalRowStyles = (product: ProductWithLeftover): string | undefined =>
  isCriticalProductQuantity(product) ? 'text-error-500' : undefined
