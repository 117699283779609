import { EmployeeByIdExtended } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol, useGetCurrencySymbol } from '@expane/logic/currency'
import { CardItemForPayment } from '@expane/logic/quickSale/types'
import { calcDiscountSum } from '@expane/logic/utils'
import { DeleteButtonCell, Input, TableCell, TableRow } from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DiscountInput } from 'ui/DiscountInput'
import {
  getAmountOfFixedDiscount,
  getDiscountErrorTextMessage,
  SubmitQuickSaleDialogFormValues,
} from '../../logic'

type Props = {
  index: number
  control: Control<SubmitQuickSaleDialogFormValues>
  subscription: CardItemForPayment
  onDelete: () => void
  isDiscountForEveryone: boolean
  myEmployee: EmployeeByIdExtended | undefined
}

export const SubscriptionSaleItem: FC<PropsWithBranchId<Props>> = ({
  onDelete,
  subscription,
  control,
  index,
  isDiscountForEveryone,
  myEmployee,
  branchId,
}) => {
  const { name, price } = subscription

  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)
  const currencySymbol = useGetCurrencySymbol(branchId)

  const maxDiscount = myEmployee?.employeeGroup?.maxDiscount ?? 0

  const watchedDiscount = useWatch({ control, name: `subscriptionItems.${index}.discount` })
  const discountSum = calcDiscountSum({ discount: watchedDiscount, price })

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell className="text-right">{convertNumberToMoney(price)}</TableCell>
      <TableCell>
        <Input type="number" defaultValue="1" disabled height="small" />
      </TableCell>
      <TableCell />
      <TableCell>
        <Controller
          control={control}
          name={`subscriptionItems.${index}.discount`}
          rules={{
            validate: value => {
              if (value?.type === 'percent') {
                return Number(value.value) <= maxDiscount
              }
              if (value?.type === 'fixed') {
                return Number(value.value) <= getAmountOfFixedDiscount({ price, maxDiscount })
              }
            },
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <DiscountInput
              value={value}
              onChange={e => {
                if (e.type === 'percent') {
                  if (Number(e.value) >= 0 && Number(e.value) <= 100)
                    onChange({ value: e.value, type: e.type })
                }

                if (e.type === 'fixed') {
                  if (Number(e.value) <= price) onChange({ value: e.value, type: e.type })
                }
              }}
              disabled={isDiscountForEveryone || !maxDiscount}
              height="small"
              errorMessage={{
                isShown: Boolean(error),
                text: getDiscountErrorTextMessage({
                  price,
                  discount: value,
                  t,
                  currencySymbol,
                  maxDiscount,
                }),
                reserveIndent: Boolean(error),
              }}
              disabledReason={
                !maxDiscount && !isDiscountForEveryone ? t('discountPermission') : undefined
              }
            />
          )}
        />
      </TableCell>
      <TableCell className="text-right">{convertNumberToMoney(price - discountSum)}</TableCell>
      <DeleteButtonCell onClick={onDelete} />
    </TableRow>
  )
}
