import {
  ServerSupplierType,
  useCreateSupplierBankDetails,
  useUpdateSupplierBankDetails,
} from '@expane/data'
import { Input, NumberInput } from '@expane/ui'
import { FC } from 'react'
import { Controller, FormState, SubmitHandler, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { useSnackbar } from '@expane/widgets'
import {
  SupplierTabAdditionalSavingProps,
  useAttachFunctionAndFormState,
} from 'widgets/SupplierDialog'

interface SupplierBankDetailsTabProps
  extends SupplierTabAdditionalSavingProps<SupplierBankDetailsTabFormValues> {
  supplierById: ServerSupplierType | undefined
  disabled: boolean
}

export const SupplierBankDetailsTab: FC<SupplierBankDetailsTabProps> = ({
  supplierById,
  additionalProps,
  disabled,
}) => {
  const { t } = useTranslation()

  const supplierBankDetails = supplierById?.bankDetails

  const { formState, control, handleSubmit } = useForm<SupplierBankDetailsTabFormValues>({
    defaultValues: {
      name: supplierBankDetails?.name ?? '',
      edrpou: supplierBankDetails?.edrpou ?? '',
      itn: supplierBankDetails?.itn ?? '',
      vatCertificate: supplierBankDetails?.vatCertificate ?? '',
      accountType: supplierBankDetails?.accountType?.toString() ?? '',
      accountNumber: supplierBankDetails?.accountNumber ?? '',
      bic: supplierBankDetails?.bic ?? '',
      iban: supplierBankDetails?.iban ?? '',
    },
  })

  const { mutateAsync: createSupplierBankDetails } = useCreateSupplierBankDetails()
  const { mutateAsync: updateSupplierBankDetails } = useUpdateSupplierBankDetails()
  const [openSnackbar] = useSnackbar()

  const mutateSupplier: SubmitHandler<SupplierBankDetailsTabFormValues> = async ({
    accountNumber,
    accountType,
    bic,
    edrpou,
    iban,
    itn,
    name,
    vatCertificate,
  }) => {
    if (supplierBankDetails) {
      const result = await updateSupplierBankDetails({
        id: supplierBankDetails.id,
        bankDetailsSetInput: {
          accountNumber,
          accountType: accountType === '' ? undefined : Number(accountType),
          bic,
          edrpou,
          iban,
          itn,
          name,
          vatCertificate,
        },
      })

      if (result?.updateBankDetailsById?.id) {
        openSnackbar(t('requisites.updatedSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else {
      const result = await createSupplierBankDetails({
        supplierId: supplierById?.id,
        accountNumber,
        accountType: accountType === '' ? undefined : Number(accountType),
        bic,
        edrpou,
        iban,
        itn,
        name,
        vatCertificate,
      })

      if (result?.insertBankDetails?.id) {
        openSnackbar(t('requisites.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    }
    additionalProps.closeDialog()
  }

  useAttachFunctionAndFormState<SupplierBankDetailsTabFormValues>({
    calledFunction: handleSubmit(mutateSupplier),
    formState,
    additionalProps,
  })
  return (
    <>
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <Input
            label={t('banksName')}
            value={value}
            onChange={onChange}
            containerClassName="w-1/3"
            required
            errorMessage={{
              isShown: Boolean(formState.errors.name),
              text: t('formError.required'),
            }}
            disabled={disabled}
          />
        )}
      />

      <div className="flex">
        <Controller
          name="edrpou"
          control={control}
          rules={{ required: true, validate: validateBankDetails('edrpou') }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label={t('edrpou')}
              placeholder={'40404040'}
              value={value}
              onChange={onChange}
              containerClassName="w-1/4"
              required
              errorMessage={{
                isShown: Boolean(formState.errors.edrpou),
                text: showBankDetailsErrorMessage(formState, 'edrpou', t),
              }}
              disabled={disabled}
            />
          )}
        />
        <Controller
          name="itn"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label={t('itn')}
              placeholder={'313131318'}
              value={value}
              onChange={onChange}
              containerClassName="w-1/3 ml-3"
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="vatCertificate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label={t('vatCertificate')}
              placeholder={'313131318'}
              value={value}
              onChange={onChange}
              containerClassName="grow ml-3"
              disabled={disabled}
            />
          )}
        />
      </div>

      <div className="flex">
        <Controller
          name="accountType"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label={t('accountType.name')}
              type="number"
              value={value}
              onChange={onChange}
              containerClassName="w-1/4"
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="accountNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label={t('accountNumber')}
              placeholder={'12340000056789'}
              value={value}
              onChange={onChange}
              containerClassName="w-1/3 ml-3"
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="bic"
          control={control}
          rules={{ required: true, validate: validateBankDetails('bic') }}
          render={({ field: { onChange, value } }) => (
            <NumberInput
              label={t('bic')}
              placeholder={'307770'}
              value={value}
              onChange={onChange}
              containerClassName="grow ml-3"
              errorMessage={{
                isShown: Boolean(formState.errors.bic),
                text: showBankDetailsErrorMessage(formState, 'bic', t),
              }}
              required
              disabled={disabled}
            />
          )}
        />
      </div>

      <Controller
        name="iban"
        control={control}
        rules={{ required: true, validate: validateBankDetails('iban') }}
        render={({ field: { onChange, value } }) => (
          <Input
            label="IBAN"
            placeholder={'UA221234560000012345678912345'}
            value={value}
            onChange={onChange}
            containerClassName="w-72"
            errorMessage={{
              isShown: Boolean(formState.errors.iban),
              text: showBankDetailsErrorMessage(formState, 'iban', t),
            }}
            required
            disabled={disabled}
          />
        )}
      />
    </>
  )
}

type BankDetailTypes = 'iban' | 'edrpou' | 'bic'

const validateBankDetails =
  (type: BankDetailTypes) =>
  (value: string): boolean => {
    if (type === 'edrpou' && value.length !== 8) return false

    if (type === 'bic' && value.length !== 6) return false

    if (type === 'iban' && value.length !== 27) return false

    return true
  }

const showBankDetailsErrorMessage = (
  formState: FormState<SupplierBankDetailsTabFormValues>,
  type: BankDetailTypes,
  t: TFunction,
): string => {
  const error = formState.errors[type]

  if (!error) return ''

  return error.type === 'validate' ? t('formError.invalid') : t('formError.required')
}

export type SupplierBankDetailsTabFormValues = {
  name: string
  edrpou: string
  itn: string
  vatCertificate: string
  accountType: string
  accountNumber: string
  bic: string
  iban: string
}
