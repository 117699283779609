import { CloseButton, Dialog, Modal, usePopupOpenState } from '@expane/ui'
import React, { FC, useRef, useState } from 'react'
import { store } from 'store'
import { useFetchBranchById } from '@expane/data'
import { WaitingListCalendar } from 'widgets/WaitingListDialog/WaitingListPeriod/Calendar'
import { useTranslation } from 'react-i18next'
import { createCurrentDate } from '@expane/date'
import { DateTimePicker } from '@expane/widgets'
import { Control } from 'react-hook-form'
import { WaitingListFormValues } from 'widgets/WaitingListDialog/waitingListDialogLogic'

interface PremiumDialogProps {
  control: Control<WaitingListFormValues>
  closeDialog: () => void
}

const WaitingListPeriodDialog: FC<PremiumDialogProps> = ({ control, closeDialog }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId

  const { data: branch } = useFetchBranchById(branchId)

  const branchSchedule = branch?.schedule
  const timezone = branch?.timezone

  const [pickedDate, setPickedDate] = useState(createCurrentDate(timezone))

  if (!branchSchedule || !timezone) return null

  return (
    <Modal close={closeDialog}>
      <Dialog>
        <Dialog.Title>{t('convenientTime')}</Dialog.Title>

        <Dialog.Body className={'min-w-256'}>
          <DateTimePicker
            timezone={timezone}
            type="week"
            className="w-1/4 pl-2 mb-2"
            onChange={setPickedDate}
            value={pickedDate}
            customDatePopupPlacement="bottom-start"
            nextPreviousButtons
          />

          <WaitingListCalendar
            branchSchedule={branchSchedule}
            pickedDate={pickedDate}
            control={control}
          />
        </Dialog.Body>
        <Dialog.Footer>
          <CloseButton onClick={closeDialog} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

export const useOpenWaitingListPeriodDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogControl = useRef<Control<WaitingListFormValues> | null>(null)

  const openWaitingListPeriodDialog = (control: Control<WaitingListFormValues>) => {
    dialogControl.current = control
    openPopup()
  }

  const waitingListPeriodDialog =
    isOpen && dialogControl.current ? (
      <WaitingListPeriodDialog closeDialog={closePopup} control={dialogControl.current} />
    ) : null

  return { openWaitingListPeriodDialog, waitingListPeriodDialog }
}
