import {
  useFetchStorages,
  useFetchSuppliersBrief,
  useGetBranchDefaultStorageId,
} from '@expane/data'
import { NumberInput, SelectDropdown } from '@expane/ui'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Controller, UseFormSetValue } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import {
  ArrivalMovementFormValues,
  ArrivalMovementProps,
} from 'widgets/MovementCreateDialogs/ArrivalMovementDialog'
import { StorageSelectDropdown } from 'widgets/StoragesSelectDropdown'
import { SupplierDialog } from 'widgets/SupplierDialog'

interface MovementInfoProps extends ArrivalMovementProps {
  setValue: UseFormSetValue<ArrivalMovementFormValues>
}

export const ArrivalMovementInfo: FC<MovementInfoProps> = ({ control, setValue }) => {
  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const { data: storages } = useFetchStorages(branchId)
  const { data: defaultStorageId } = useGetBranchDefaultStorageId(branchId)
  const { data: suppliers } = useFetchSuppliersBrief(branchId)

  const { openCreateDialog, dialog } = useOpenDialog(SupplierDialog)

  return (
    <div className="flex">
      <Controller
        control={control}
        name="supplierId"
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <SelectDropdown
            label={t('supplier.name')}
            required
            className="w-1/2 pr-2"
            onSelectChange={onChange}
            value={value}
            items={suppliers}
            errorMessage={{ isShown: Boolean(errors.supplierId), text: t('formError.required') }}
            onPlusClick={() => openCreateDialog(id => setValue('supplierId', id))}
          />
        )}
      />
      <Controller
        control={control}
        name="toStorageId"
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <StorageSelectDropdown
            required
            label={t('storage.name')}
            className="w-1/2 pr-2"
            onSelectChange={onChange}
            value={value}
            storages={storages}
            defaultStorageId={defaultStorageId}
            errorMessage={{ isShown: Boolean(errors.toStorageId), text: t('formError.required') }}
          />
        )}
      />
      <Controller
        control={control}
        name="movementNumber"
        rules={{ required: true }}
        render={({ field: { onChange, value }, formState: { errors } }) => (
          <NumberInput
            containerClassName="w-1/2 pr-2"
            required
            label={`${t('invoice')}, ${t('numberFormat', { value: '' })}`}
            onChange={onChange}
            value={value}
            errorMessage={{
              isShown: Boolean(errors.movementNumber),
              text: t('formError.required'),
            }}
          />
        )}
      />
      {dialog}
    </div>
  )
}
