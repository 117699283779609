import { makeAutoObservable } from 'mobx'
import { IdTokenResult } from 'firebase/auth'
import { AllowedRole, XHasuraClaims } from '@expane/logic/auth/types'

export class MeStore {
  constructor() {
    makeAutoObservable(this)
  }

  businessId: number | null = null
  employeeId: number | null = null
  clientId: number | null = null
  role: AllowedRole | null = null

  private setBusinessId = (id: number | null) => {
    this.businessId = id
  }

  private setEmployeeId = (id: number | null) => {
    this.employeeId = id
  }

  private setClientId = (id: number | null) => {
    this.clientId = id
  }

  private setRole = (role: AllowedRole | null) => {
    this.role = role
  }

  get isAuthorised() {
    return this.businessId !== null
  }

  get isEmployeeMode() {
    return this.employeeId !== null
  }

  get isRefererMode() {
    return this.role === 'referrer'
  }

  setMyBusinessAndEmployee = (token: IdTokenResult | undefined) => {
    const xHasuraClaims = token?.claims?.['https://hasura.io/jwt/claims'] as
      | XHasuraClaims
      | string
      | undefined

    if (!xHasuraClaims) return

    this.setRole(xHasuraClaims['x-hasura-default-role'])

    if (xHasuraClaims['x-hasura-business-id']) {
      if (xHasuraClaims['x-hasura-default-role'] === 'client') {
        this.setBusinessId(Number(xHasuraClaims['x-hasura-business-id']))
        this.setClientId(Number(xHasuraClaims['x-hasura-client-id']))
        this.setEmployeeId(null)
      } else {
        this.setBusinessId(Number(xHasuraClaims['x-hasura-business-id']))
        this.setEmployeeId(Number(xHasuraClaims['x-hasura-employee-id']))
        this.setClientId(null)
      }
    }
  }

  resetMe = () => {
    this.setBusinessId(null)
    this.setClientId(null)
    this.setEmployeeId(null)
    this.setRole(null)
  }
}
