import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { checkUnitCanBeFloat, getUnitsName } from '@expane/logic/product'
import {
  Input,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { FC } from 'react'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InventoryFieldValues } from '.'
import { roundValue } from '@expane/logic/utils'

interface EditableInventoryListProps {
  control: Control<InventoryFieldValues>
}

export const EditableInventoryList: FC<PropsWithBranchId<EditableInventoryListProps>> = ({
  control,
  branchId,
}) => {
  const { t } = useTranslation()

  const { fields } = useFieldArray({ control, name: 'products' })

  return (
    <TableContainer className={'overflow-auto'}>
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-32">{t('vendorCode')}</TableHeaderCell>
          <TableHeaderCell className="w-60">{t('product.name')}</TableHeaderCell>
          <TableHeaderCell>{t('unit.shortName')}</TableHeaderCell>
          <TableHeaderCell className="text-right w-28">{t('price')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('calcLeftover')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('actualLeftover')}</TableHeaderCell>
          <TableHeaderCell className="text-right w-28">{t('difference')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('excessShortage')}</TableHeaderCell>
        </tr>
      </TableHeader>
      <TableBody>
        {fields.map((product, index) => (
          <ListItem key={product.id} control={control} index={index} branchId={branchId} />
        ))}
      </TableBody>
    </TableContainer>
  )
}

const ListItem: FC<PropsWithBranchId<EditableInventoryListProps & { index: number }>> = ({
  control,
  index,
  branchId,
}) => {
  const { t } = useTranslation()

  const convertToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  return (
    <Controller
      control={control}
      name={`products.${index}`}
      rules={{
        validate: value => {
          if (!value.unit) return false

          if (checkUnitCanBeFloat(value.unit)) return true

          return Number.isInteger(Number(value.actualQuantity))
        },
      }}
      render={({ field: { value: product, onChange }, fieldState: { error } }) => {
        const actualQuantity = Number(product.actualQuantity)

        const divergence = actualQuantity >= 0 ? actualQuantity - product.calculatedQuantity : 0
        const costOfDivergence = convertToMoney(
          actualQuantity >= 0 ? divergence * (product.costPrice ?? 0) : 0,
        )

        let divergenceCellStyle = ''
        if (divergence > 0) divergenceCellStyle += ' text-primary-500'
        if (divergence < 0) divergenceCellStyle += ' text-error-500'

        return (
          <TableRow>
            <TableCell>{product.vendorCode}</TableCell>
            <TableCell>{product.name}</TableCell>
            <TableCell>{product.unit ? getUnitsName(product.unit, t) : null}</TableCell>
            <TableCell className="text-right">{product.costPrice}</TableCell>
            <TableCell className="text-right">{product.calculatedQuantity}</TableCell>
            <TableCell>
              <Input
                value={product.actualQuantity}
                onChange={e => onChange({ ...product, actualQuantity: e.target.value })}
                height="medium"
                type="number"
                containerClassName="w-20"
                errorMessage={{
                  isShown: Boolean(error),
                  reserveIndent: false,
                }}
              />
            </TableCell>
            <TableCell className="text-right">
              <div className={divergenceCellStyle}>{roundValue(divergence)}</div>
            </TableCell>
            <TableCell className="text-right">
              <div className={divergenceCellStyle}>{costOfDivergence}</div>
            </TableCell>
          </TableRow>
        )
      }}
    />
  )
}
