import { ReviewNotificationType } from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StarRating } from '@expane/nui'
import { Avatar } from 'ui/Avatar'
import { ReviewActions } from 'widgets/ReviewActions/'
import { useFetchMyPermissions } from 'gql/employee'
import { permissions } from '@expane/logic/permission'
import { notificationContainerStyle, NotificationItemProps } from './logic'
import { NotificationItemHeader } from './HeaderItem'

interface ReviewNotificationItemProps
  extends Omit<NotificationItemProps, 'item' | 'openBookingDialog'> {
  notification: ReviewNotificationType
}

export const ReviewNotificationItem: FC<ReviewNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
}) => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const review = notification.review

  const name = transformPersonName(review.client)

  const actionsEnabled = Boolean(myPermissions?.includes(permissions.review.set))

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <p className="text-main-color text-sm my-1">{t('newReviewFromClient')}:</p>

        <div className="mb-2 border border-block-color p-3 rounded-2xl text-sm text-main-color">
          <div className="flex mb-1 justify-between">
            <StarRating rating={review.rating} size="18" />
            {actionsEnabled && <ReviewActions review={review} size="small" />}
          </div>

          <p className="mb-4">{review.text}</p>
          <div className="flex gap-2 items-center">
            <Avatar name={name} url={review.client.photo ?? undefined} />
            <p>{name}</p>
          </div>
        </div>
      </div>
    </li>
  )
}
