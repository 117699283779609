import {
  ServerProductType,
  ServerTransactionBriefWithMovementsType,
  useCreateProduct,
  useFetchCurrentBranchTimezone,
  useFetchProductById,
  useFetchProductGroups,
  useFetchTransactionsBriefWithMovementsByProductId,
  useUpdateProduct,
} from '@expane/data'
import { useUpdateFile } from '@expane/logic/file'
import { useGetIsShownProductsByModules } from '@expane/logic/modules'
import { permissions } from '@expane/logic/permission'
import {
  checkAvailableProductTypeChanges,
  checkProductGroupForAccess,
  convertUnitValueFromServer,
  convertUnitValueToServer,
  UNITS,
} from '@expane/logic/product'
import {
  CloseButton,
  Dialog,
  Modal,
  RadioGroupOption,
  usePopupOpenState,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { FC, useRef } from 'react'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { DuplicateButton, SaveButton } from 'widgets/Buttons'
import { ProductDialogBody } from 'widgets/ProductDialog/ProductDialogBody'
import { ProductDialogPlaceholder } from 'widgets/ProductDialog/ProductDialogPlaceholder'
import {
  generateToProductTypeOptionByModules,
  transformProductTypeOptionToServer,
  transformServerForSaleToProductTypeOption,
} from './logic'
import { checkIfNameWasNotChanged } from '@expane/logic/utils'

const ProductDialog: FC<ProductDialogProps> = observer(
  ({ closeDialog, isCreate, id, onCreate }) => {
    const branchId = store.branch.branchId

    const timezone = useFetchCurrentBranchTimezone(branchId)
    const { data: productById, isLoading: isLoadingProductById } = useFetchProductById(id, branchId)
    const { data: transactions, isLoading: isLoadingTransactions } =
      useFetchTransactionsBriefWithMovementsByProductId(id, timezone, branchId)

    const isLoading = !isCreate && (isLoadingProductById || isLoadingTransactions)
    const isNoData = !isCreate && (!productById || !transactions)

    useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
    if (isLoading) return <ProductDialogPlaceholder closeDialog={closeDialog} />
    else if (isNoData) return null

    return (
      <ProductDialogLogic
        isCreate={isCreate}
        onCreate={onCreate}
        productById={productById}
        closeDialog={closeDialog}
        transactionsWithProduct={transactions}
      />
    )
  },
)

const ProductDialogLogic: FC<ProductDialogLogicProps> = ({
  isCreate,
  onCreate,
  productById,
  transactionsWithProduct,
  closeDialog,
}) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!

  const { isProductsForSaleEnabled, isConsumablesEnabled } = useGetIsShownProductsByModules()

  const { data: productGroups } = useFetchProductGroups(branchId)

  const { data: myPermission } = useFetchMyPermissions()

  const { formState, control, handleSubmit, setValue } = useForm<ProductDialogFormValues>({
    defaultValues: {
      isItCopy: false,
      name: productById?.name ?? '',
      groupId: productById?.groupId ?? undefined,
      description: productById?.description ?? '',
      vendorCode: productById?.vendorCode ?? '',
      type: isCreate
        ? generateToProductTypeOptionByModules({ isConsumablesEnabled, isProductsForSaleEnabled })
        : transformServerForSaleToProductTypeOption(productById?.forSale),
      unit: productById?.unit ?? UNITS[0].id,
      price: productById?.price.toString() ?? '',
      costPrice: productById?.costPrice.toString() ?? '',
      criticalQuantity: productById
        ? convertUnitValueFromServer(productById.criticalQuantity, productById.unit).toString()
        : '0',
      barcode: productById?.barcode ?? '',
      photo: productById?.photo ?? '',
    },
  })
  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const { showWarningPopup, warningModal } = useShowWarningPopup(
    t('warning'),
    t('productGroup.productWarning'),
  )

  const { areUsedAsConsumables, thereWasAlreadySale } = checkAvailableProductTypeChanges(
    productById,
    transactionsWithProduct,
  )

  const { mutateAsync: createProduct } = useCreateProduct()
  const { mutateAsync: updateProduct } = useUpdateProduct()
  const updatePhoto = useUpdateFile()

  const [openSnackbar] = useSnackbar()

  const watchedName = useWatch({ control, name: 'name' })

  const mutateProduct: SubmitHandler<ProductDialogFormValues> = async data => {
    const {
      isItCopy,
      name,
      description,
      price,
      costPrice,
      barcode,
      vendorCode,
      groupId,
      criticalQuantity,
      unit,
      photo,
    } = data

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const type = data.type!

    if (!isCreate && !isItCopy) {
      // если товар использовался в услуге как расходник
      // и мы пытаемся поменять группу товара
      if (
        formState.dirtyFields.groupId &&
        productById &&
        productById.serviceProducts.length > 0 &&
        groupId !== undefined
      ) {
        // проверяем или группа товаров, в которую мы хотим добавить товар, доступна всем сотрудникам
        const allowedToAddGroup = checkProductGroupForAccess(productGroups ?? [], groupId)
        if (!allowedToAddGroup) return showWarningPopup()
      }
    }

    const photoUrl = await updatePhoto({
      prevFile: productById?.photo ?? undefined,
      file: photo,
    })

    const forSale = transformProductTypeOptionToServer(type)

    if (isCreate || isItCopy) {
      const result = await createProduct({
        name,
        description,
        price: Number(price),
        costPrice: Number(costPrice),
        barcode: barcode || null,
        vendorCode: vendorCode || null,
        groupId,
        forSale,
        criticalQuantity: convertUnitValueToServer(Number(criticalQuantity), unit),
        unit,
        photo: photoUrl,
        branchId,
      })
      if (result?.insertProduct?.id) {
        openSnackbar(t('product.createdSuccessfully'), 'success', 3000)

        onCreate?.(result.insertProduct.id, name, forSale, groupId)
      } else openSnackbar(t('submitError'), 'error', 3000)
    } else if (productById) {
      const result = await updateProduct({
        id: productById.id,
        productSetInput: {
          name,
          description,
          price: Number(price),
          costPrice: Number(costPrice),
          barcode: barcode || null,
          vendorCode: vendorCode || null,
          groupId: groupId ?? null,
          forSale: transformProductTypeOptionToServer(type),
          criticalQuantity: convertUnitValueToServer(Number(criticalQuantity), unit),
          unit,
          photo: photoUrl ?? null,
        },
      })
      if (result?.updateProductById?.id)
        openSnackbar(t('product.updatedSuccessfully'), 'success', 3000)
      else openSnackbar(t('submitError'), 'error', 3000)
    }

    closeDialog()
  }

  const isEditingAllowed = myPermission?.includes(permissions.product.set)
  const isShowProductType = isProductsForSaleEnabled && isConsumablesEnabled

  return (
    <Modal
      close={closePopups}
      confirm={() => {
        if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
          handleSubmit(mutateProduct)()
      }}
      animation="onlyFadeOut"
    >
      <Dialog>
        <Dialog.Title>{t('product.name')}</Dialog.Title>

        <Dialog.Body className="w-205">
          <ProductDialogBody
            control={control}
            setValue={setValue}
            productGroups={productGroups}
            disabled={!isEditingAllowed}
            areUsedAsConsumables={areUsedAsConsumables}
            thereWasAlreadySale={thereWasAlreadySale}
            isShowProductType={isShowProductType}
          />
        </Dialog.Body>

        <Dialog.Footer>
          {isEditingAllowed && (
            <>
              <SaveButton
                onClick={handleSubmit(mutateProduct)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />

              {!isCreate && (
                <DuplicateButton
                  onClick={handleSubmit((data: ProductDialogFormValues) => {
                    return mutateProduct({ ...data, isItCopy: true })
                  })}
                  disabled={
                    formState.isSubmitting ||
                    checkIfNameWasNotChanged(productById?.name ?? '', watchedName)
                  }
                />
              )}
            </>
          )}

          <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
        </Dialog.Footer>
      </Dialog>

      {confirmPopup}
      {warningModal}
    </Modal>
  )
}

export type OnCreateProductFunc = (
  id: number,
  name: string,
  forSale: boolean,
  groupId?: number,
) => void

interface ProductDialogProps extends Omit<DialogProps, 'onCreate'> {
  onCreate?: OnCreateProductFunc
}

interface ProductDialogLogicProps extends Omit<ProductDialogProps, 'id'> {
  productById: ServerProductType | undefined
  transactionsWithProduct: ServerTransactionBriefWithMovementsType[] | undefined
}

export type ProductDialogFormValues = {
  isItCopy: boolean
  name: string
  description: string | undefined
  price: string
  costPrice: string
  vendorCode: string | undefined
  barcode: string
  groupId: number | undefined
  type: RadioGroupOption | undefined
  criticalQuantity: string
  unit: number
  photo?: string
}

export const useOpenProductDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogId = useRef<number | undefined>()
  const dialogIsCreate = useRef<boolean>(false)
  const dialogOnCreate = useRef<OnCreateProductFunc>()

  const openEditDialog = (id: number) => {
    dialogId.current = id
    dialogIsCreate.current = false
    openPopup()
  }

  const openCreateDialog = (onCreate?: OnCreateProductFunc) => {
    dialogId.current = undefined
    dialogIsCreate.current = true
    dialogOnCreate.current = onCreate
    openPopup()
  }

  const dialog = isOpen ? (
    <ProductDialog
      closeDialog={closePopup}
      id={dialogId.current}
      isCreate={dialogIsCreate.current}
      onCreate={dialogOnCreate.current}
    />
  ) : null

  return { openEditDialog, openCreateDialog, dialog }
}
export { generateToProductTypeOptionByModules } from 'widgets/ProductDialog/logic'
