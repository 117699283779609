import {
  ServerTransactionByIdType,
  ServerTransactionType,
  TRANSACTION_TYPES,
  useFetchCurrentBranchTimezone,
  useFetchTransactionById,
  useFetchTransactionsByParentId,
} from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import {
  useConvertNumberToMoneyWithoutSymbol,
  useConvertNumberToMoneyCode,
} from '@expane/logic/currency'
import { convertUnitValueFromServer, getUnitsName } from '@expane/logic/product'
import { getQuantityByTransactionType } from '@expane/logic/transaction'
import {
  getCheckForReturn,
  getIsRefundAllowed,
  uniteSameTransactionServices,
} from '@expane/logic/transaction/refund'
import {
  Button,
  CloseButton,
  Dialog,
  Modal,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { translateTableTitles } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoArrowUndoOutline, IoCheckmark, IoReceiptOutline } from 'react-icons/io5'
import { store } from 'store'
import { useOpenReceiptDialog } from 'widgets/ReceiptDialog'
import { ParentTransactionInfo } from 'widgets/TransactionDialog/ParentTransactionInfo'
import { useOpenTransactionRefundDialog } from 'widgets/TransactionDialog/RefundDialog'
import { TransactionDialogPlaceholder } from 'widgets/TransactionDialog/TransactionDialogPlaceholder'
import { TransactionMainInfoDialog } from 'widgets/TransactionDialog/TransactionMainInfo'
import { TransactionRefundInfo } from 'widgets/TransactionDialog/TransactionRefundInfo'

export const TransactionDialog: FC<DialogProps> = observer(({ id: transactionId, closeDialog }) => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()
  const { data: transactionById, isLoading: isLoadingTransactionById } = useFetchTransactionById(
    transactionId,
    timezone,
    branchId,
  )
  const { data: refundsForTransaction, isLoading: isLoadingRefundForTransaction } =
    useFetchTransactionsByParentId(transactionId, timezone, branchId)

  const isLoading =
    isLoadingMyPermissions || isLoadingTransactionById || isLoadingRefundForTransaction
  const isNoData = !myPermissions || !transactionById || !refundsForTransaction

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)
  if (!isLoading && isNoData) return null

  return (
    <Modal close={closeDialog}>
      <Dialog>
        {isLoading ? (
          <TransactionDialogPlaceholder closeDialog={closeDialog} />
        ) : (
          <TransactionDialogLogic
            myPermissions={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              myPermissions!
            }
            transactionById={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              transactionById!
            }
            refundsForTransaction={
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              refundsForTransaction!
            }
            closeDialog={closeDialog}
            branchId={branchId}
          />
        )}
      </Dialog>
    </Modal>
  )
})

interface TransactionDialogLogicProps {
  myPermissions: string[]
  transactionById: ServerTransactionByIdType
  refundsForTransaction: ServerTransactionType[]
  closeDialog: () => void
}

const TransactionDialogLogic: FC<PropsWithBranchId<TransactionDialogLogicProps>> = ({
  myPermissions,
  transactionById,
  refundsForTransaction,
  closeDialog,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertToMoney = useConvertNumberToMoneyCode({ branchId })

  const { openTransactionRefundDialog, transactionRefundDialog } = useOpenTransactionRefundDialog()
  const { openReceiptDialog, receiptDialog } = useOpenReceiptDialog()

  const isReadyToReturn = getCheckForReturn(transactionById, refundsForTransaction)

  const currentTitles = translateTableTitles(
    transactionById.cardId ? [...TITLES_BY_SUBSCRIPTION] : [...TITLES],
    t,
  )

  const unitedServicesWithQuantity = uniteSameTransactionServices(transactionById)

  const isRefundAllowed = getIsRefundAllowed(transactionById, myPermissions)

  const items =
    Boolean(unitedServicesWithQuantity.length) ||
    Boolean(transactionById.transactionsCards.length) ||
    Boolean(transactionById.movement?.movementProducts.length)

  const showTable =
    transactionById.type === TRANSACTION_TYPES.payment.id ||
    transactionById.type === TRANSACTION_TYPES.paymentInCredit.id ||
    transactionById.type === TRANSACTION_TYPES.refund.id

  return (
    <>
      <Dialog.Title>{t('transaction.name')}</Dialog.Title>

      <Dialog.Body className="w-148">
        <TransactionMainInfoDialog transactionById={transactionById} />

        {items && showTable ? (
          <TableContainer>
            <TableHeader>
              <tr>
                {currentTitles.map(({ title, width }) => (
                  <TableHeaderCell key={title} className={width}>
                    {title}
                  </TableHeaderCell>
                ))}
              </tr>
            </TableHeader>

            <TableBody>
              {unitedServicesWithQuantity.map(service => {
                return transactionById.cardId ? (
                  <TransactionBySubscriptionListItem
                    name={service.service.name}
                    key={service.service.id}
                  />
                ) : (
                  <TransactionListItem
                    key={service.service.id}
                    name={service.service.name}
                    discount={service.discount ? service.discount * service.quantity : null}
                    price={service.price * service.quantity}
                    quantity={service.quantity}
                    type="service"
                    branchId={branchId}
                  />
                )
              })}

              {transactionById.transactionsCards.map(card => (
                <TransactionListItem
                  key={card.card.id}
                  name={card.card.cardTemplate.name}
                  discount={card.discount}
                  price={card.price}
                  type="card"
                  branchId={branchId}
                />
              ))}

              {(transactionById.typeVariation === TRANSACTION_TYPES.payment.variations.product.id ||
                transactionById.type === TRANSACTION_TYPES.refund.id) &&
                // do not show consumables, that are embedded in service
                transactionById.movement?.movementProducts.map(movementProduct => (
                  <TransactionListItem
                    key={movementProduct.product.id}
                    name={movementProduct.product.name}
                    quantity={movementProduct.quantity}
                    discount={movementProduct.discount}
                    unit={movementProduct.product.unit}
                    price={
                      movementProduct.price *
                      convertUnitValueFromServer(
                        movementProduct.quantity,
                        movementProduct.product.unit,
                      )
                    }
                    type="product"
                    branchId={branchId}
                  />
                ))}
            </TableBody>
          </TableContainer>
        ) : null}

        {transactionById.cardId ? null : (
          <div className="w-1/2 mt-3 ml-auto text-right text-main-color">
            {`${t('amount')}: ${convertToMoney(transactionById.amount)}`}
          </div>
        )}

        <TransactionRefundInfo refundsForTransaction={refundsForTransaction} />

        <ParentTransactionInfo transactionById={transactionById} />
      </Dialog.Body>

      <Dialog.Footer>
        {isRefundAllowed &&
          (transactionById.type === TRANSACTION_TYPES.payment.id ||
          transactionById.type === TRANSACTION_TYPES.paymentInCredit.id ? (
            <Button
              onClick={() =>
                openTransactionRefundDialog({
                  onSuccess: closeDialog,
                  transactionId: transactionById.id,
                })
              }
              disabled={!isReadyToReturn}
              Icon={IoArrowUndoOutline}
            >
              {t('transaction.createRefund')}
            </Button>
          ) : null)}

        {transactionById.transactionReceipts && transactionById.transactionReceipts.length > 0 && (
          <Button
            type="outline"
            className="ml-auto"
            Icon={IoReceiptOutline}
            onClick={() => openReceiptDialog(transactionById.transactionReceipts[0].receiptId)}
          >
            {t('receipt.title')}
          </Button>
        )}

        <CloseButton onClick={closeDialog} className="mr-auto" />
      </Dialog.Footer>

      {transactionRefundDialog}
      {receiptDialog}
    </>
  )
}

type TransactionType = 'service' | 'card' | 'product'

const TransactionListItem: FC<
  PropsWithBranchId<{
    name: string
    discount: number | null | undefined
    quantity?: number
    unit?: number
    price: number
    type: TransactionType
  }>
> = ({ name, discount, price, quantity, unit, type, branchId }) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const currentQuantity = getQuantityByTransactionType(type, quantity, unit)

  const currentDiscount = discount ? discount * currentQuantity : 0

  // значения конвертируются, поэтому используем ф-ю UnitName,
  // которая возвращет название unit которое хранится на сервере
  const unitLabel = type === 'product' && unit ? getUnitsName(unit, t) : undefined

  return (
    <TableRow>
      <TableCell>
        <div>{name}</div>
      </TableCell>
      <TableCell>
        <div>
          {currentQuantity}
          {unitLabel}
        </div>
      </TableCell>
      <TableCell>
        <div>{currentDiscount !== 0 ? convertNumberToMoney(currentDiscount) : '-'}</div>
      </TableCell>

      <TableCell>
        <div>{convertNumberToMoney(price)}</div>
      </TableCell>
    </TableRow>
  )
}

const TransactionBySubscriptionListItem: FC<{
  name: string
}> = ({ name }) => {
  return (
    <TableRow>
      <TableCell>
        <div>{name}</div>
      </TableCell>
      <TableCell>
        <div>{DEFAULT_QUANTITY}</div>
      </TableCell>
      <TableCell>
        <IoCheckmark size="1.2rem" className="text-primary-500" />
      </TableCell>
    </TableRow>
  )
}

type ColumnTitle = { title: string; width: string }
const TITLES: ColumnTitle[] = [
  { title: 'position', width: 'w-72' },
  { title: 'qty', width: 'w-24' },
  { title: 'discount', width: 'w-24' },
  { title: 'price', width: 'w-24' },
]
const TITLES_BY_SUBSCRIPTION: ColumnTitle[] = [
  { title: 'position', width: 'w-96' },
  { title: 'qty', width: 'w-24' },
  { title: 'subscription.name', width: 'w-24' },
]

const DEFAULT_QUANTITY = 1
