import { ServerCloudFunctionResult } from '../generated/graphql-types'

export enum CloudFunctionResponseCodes {
  // Common codes
  successful = 1,
  apiError,
  badRequest,
  notFound,
  insufficientPermissions,
  insufficientData,
  incorrectData,
  // Specific codes
  onlineBookingNotAllowed = 20,
  giftCardExpired,
  claimsError,
  groupServiceError,
  clientAlreadyExistsInGroupService,
  failedToTopUp,
  maxEmployeeError,
  refundExceedsFee,
  cannotArchiveOwner,
  cannotArchiveHimself,
  cannotArchiveWithActiveSchedule,
  cannotArchiveWithFutureBookings,
  inArchivedGroup,
  groupNonEmpty,
  cannotArchiveWithNotArchivedCardTemplate,
  existFutureBookings,
  existMultiClientAccounts,
  existFutureBookingsAndMultiClientAccounts,
  testUsersCannotBeDeleted,
  // Checkbox codes
  noSuchAccount = 90,
  noCheckboxCredentials,
  noCheckboxToken,
  noCheckboxLicenseKey,
  checkboxError,
  checkboxCredentialsError,
  thereAreNoOpenCheckboxShifts,
}

export interface CloudFunctionResult extends ServerCloudFunctionResult {
  code: CloudFunctionResponseCodes
}
