import { FC } from 'react'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { transformPersonName } from '@expane/logic/utils'
import { useCheckIsOneBranchInBusiness } from '@expane/logic/branch'
import { EditButton } from 'ui/EditButton'
import { NotificationOfBookingCreatedByClientType } from '@expane/data'
import { labelStyle, notificationContainerStyle, NotificationItemProps, textStyle } from './logic'
import { useTranslation } from 'react-i18next'
import { NotificationItemHeader } from './HeaderItem'

interface NotificationOfBookingCreatedByClientItemProps
  extends Omit<NotificationItemProps, 'item'> {
  notification: NotificationOfBookingCreatedByClientType
}

export const NotificationOfBookingCreatedByClientItem: FC<
  NotificationOfBookingCreatedByClientItemProps
> = ({ notification, selectedNotifications, setNotifications, openBookingDialog }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()

  const clientName = notification.booking?.client
    ? transformPersonName(notification.booking.client)
    : ''

  const employeeName = notification.booking?.employee
    ? transformPersonName(notification.booking.employee)
    : ''

  const services = notification.booking?.bookingServices.map(({ service }) => service.name) ?? []

  const startDate = notification.booking?.startDate
    ? dateFormatting('dateTime', notification.booking.startDate)
    : ''

  const { isOneBranchInBusiness } = useCheckIsOneBranchInBusiness()

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="flex justify-between">
          <div className="text-sm text-gray-600 py-1">
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('client.name')}:`}</span>
              {clientName}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('employee.name')}:`}</span>
              {employeeName}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t(
                services.length > 1 ? 'services' : 'service.name',
              )}:`}</span>
              {services.join(', ')}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('startDate')}: `}</span>
              {startDate}
            </p>
            <p className={textStyle}>
              <span className={labelStyle}>{`${t('duration')}: `}</span>
              {notification.booking?.duration ?? 0}
            </p>

            {!isOneBranchInBusiness && (
              <p className={textStyle}>
                <span className={labelStyle}>{`${t('branch.name')}: `}</span>
                {notification.booking?.branch.name}
              </p>
            )}

            {notification.booking?.clientNote && (
              <p className={textStyle}>
                <span className={labelStyle}>{`${t('commentTitle')}: `}</span>
                {notification.booking.clientNote}
              </p>
            )}
          </div>

          <div className="mr-1">
            <EditButton
              onClick={() => {
                if (notification.booking?.id) openBookingDialog?.(notification.booking.id)
              }}
            />
          </div>
        </div>
      </div>
    </li>
  )
}
