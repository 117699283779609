import { ServerBranchBriefType, ServerProductType } from '@expane/data'
import { checkOnlyPositiveAmount } from '@expane/logic/form'
import { useGetUnitsNameForConsumable } from '@expane/logic/product'
import {
  AddButtonHeaderCell,
  Button,
  CloseButton,
  DeleteButtonCell,
  Dialog,
  Modal,
  NumberInput,
  SelectDropdown,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import {
  useGetConsumableAmountInBranch,
  useGetConsumablesTotalAmountInBranch,
} from 'logic/consumable'
import { customSelectDropDownProductSearchFilterFunction } from 'logic/product'
import { DEFAULT_CONSUMABLE_QUANTITY } from 'logic/service'
import { FC } from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { SaveButton } from 'widgets/Buttons'
import { InterbranchServiceConsumableDialogProps } from './'

type InterbranchServiceConsumableDialogLogicProps = InterbranchServiceConsumableDialogProps & {
  branch: ServerBranchBriefType
  products: ServerProductType[]
}
export const InterbranchServiceConsumableDialogLogic: FC<
  InterbranchServiceConsumableDialogLogicProps
> = ({
  branch,
  branchId,
  closeDialog,
  control,
  disabled,
  formGetFieldState,
  formTrigger,
  index,
  products,
  setValue,
}) => {
  const { t } = useTranslation()

  const { fields, remove, append } = useFieldArray({
    control,
    name: `branchServices.${index}.consumables`,
  })
  const watchedConsumables = useWatch({ control, name: `branchServices.${index}.consumables` })

  const getUnitName = useGetUnitsNameForConsumable(branchId)
  const getProductAmount = useGetConsumableAmountInBranch(branchId)
  const getTotalAmount = useGetConsumablesTotalAmountInBranch(branchId)

  const onEdit = async () => {
    for (let i = 0; i < fields.length; i += 1) {
      await formTrigger(`branchServices.${index}.consumables.${i}.productId`)
    }

    const { error } = formGetFieldState(`branchServices.${index}.consumables`)

    if (!error) closeDialog()
  }

  const onClose = async () => {
    const consumables = fields.filter(({ productId }) => productId)
    setValue(`branchServices.${index}.consumables`, consumables)

    closeDialog()
  }

  return (
    <Modal close={onClose} animation="onlyFadeOut">
      <Dialog>
        <Dialog.Title>
          {t('consumables')} {t('forBranch')} "{branch.name}"
        </Dialog.Title>
        <Dialog.Body className="w-144 h-58">
          <div className=" flex flex-col justify-between h-full">
            {fields.length ? (
              <TableContainer>
                <TableHeader>
                  <tr className={'w-full'}>
                    <TableHeaderCell className="w-48">{t('title')}</TableHeaderCell>
                    <TableHeaderCell className="w-28">{t('qty')}</TableHeaderCell>
                    <TableHeaderCell className="w-28">{t('unit.shortName')}</TableHeaderCell>
                    <TableHeaderCell className="w-24 text-right">{t('price')}</TableHeaderCell>
                    <AddButtonHeaderCell
                      disabled={disabled}
                      onClick={() =>
                        append({
                          productId: undefined,
                          bookingProductId: undefined,
                          quantity: DEFAULT_CONSUMABLE_QUANTITY,
                        })
                      }
                    />
                  </tr>
                </TableHeader>
                <TableBody>
                  {fields.map((consumable, consumableIndex) => {
                    const { error } = formGetFieldState(
                      `branchServices.${index}.consumables.${consumableIndex}`,
                    )

                    return (
                      <TableRow
                        key={consumable.id}
                        errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                      >
                        <TableCell>
                          <Controller
                            control={control}
                            rules={{ required: true }}
                            name={
                              `branchServices.${index}.consumables.${consumableIndex}.productId` as 'branchServices.0.consumables.0.productId'
                            }
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <SelectDropdown
                                value={value}
                                onSelectChange={onChange}
                                items={products}
                                errorMessage={{
                                  isShown: Boolean(error),
                                  text: ' ',
                                  reserveIndent: false,
                                }}
                                height="small"
                                disabled={disabled}
                                customSearchFilterFunction={
                                  customSelectDropDownProductSearchFilterFunction
                                }
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            control={control}
                            name={
                              `branchServices.${index}.consumables.${consumableIndex}.quantity` as 'branchServices.0.consumables.0.quantity'
                            }
                            rules={{
                              required: true,
                              validate: {
                                checkOnlyPositiveAmount,
                                integer: quantity => Number.isInteger(Number(quantity)),
                              },
                            }}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                              <NumberInput
                                value={value}
                                onChange={onChange}
                                errorMessage={{
                                  isShown: Boolean(error),
                                  text: ' ',
                                  reserveIndent: false,
                                }}
                                height="small"
                                disabled={disabled}
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell className="text-right">
                          {t(getUnitName(watchedConsumables[consumableIndex]?.productId))}
                        </TableCell>
                        <TableCell className="text-right">
                          {getProductAmount(
                            watchedConsumables[consumableIndex]?.productId,
                            watchedConsumables[consumableIndex]?.quantity,
                            undefined,
                          )}
                        </TableCell>
                        <DeleteButtonCell
                          onClick={() => remove(consumableIndex)}
                          disabled={disabled}
                        />
                      </TableRow>
                    )
                  })}
                </TableBody>
              </TableContainer>
            ) : (
              <Button
                className="w-48 self-center"
                onClick={() => {
                  append({
                    productId: undefined,
                    bookingProductId: undefined,
                    quantity: DEFAULT_CONSUMABLE_QUANTITY,
                  })
                }}
                Icon={IoAddCircleOutline}
                type="outline"
                disabled={disabled}
              >
                {t('consumable')}
              </Button>
            )}

            <div className="py-2 text-right text-sm text-gray-500">
              {t('total')}: {getTotalAmount(watchedConsumables)}
            </div>
          </div>
        </Dialog.Body>
        <Dialog.Footer>
          <SaveButton onClick={onEdit} disabled={disabled} />
          <CloseButton onClick={onClose} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}
