import { FC } from 'react'
import { NOTIFICATION_TYPE } from '@expane/data'
import { NotificationItemProps } from './logic'
import { WaitingListNotificationItem } from './WaitingListNotificationItem'
import { NotificationOfBookingCreatedByClientItem } from './NotificationOfBookingCreatedByClientItem'
import { ToEmployeeNotificationItem } from './ToEmployeeNotificationItem'
import { BillingNotificationItem } from './BillingNotificationItem'
import { ReviewNotificationItem } from './ReviewNotificationItem'

export const NotificationItem: FC<NotificationItemProps> = ({
  item,
  openBookingDialog,
  setNotifications,
  selectedNotifications,
  timezone,
}) => (
  <>
    {item.type === NOTIFICATION_TYPE.waitingList && (
      <WaitingListNotificationItem
        notification={item}
        timezone={timezone}
        setNotifications={setNotifications}
        selectedNotifications={selectedNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.bookingCreatedByClient && (
      <NotificationOfBookingCreatedByClientItem
        timezone={timezone}
        notification={item}
        openBookingDialog={openBookingDialog}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.toEmployee && (
      <ToEmployeeNotificationItem
        timezone={timezone}
        notification={item}
        openBookingDialog={openBookingDialog}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.billingReminder && (
      <BillingNotificationItem
        timezone={timezone}
        notification={item}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
    {item.type === NOTIFICATION_TYPE.review && (
      <ReviewNotificationItem
        timezone={timezone}
        notification={item}
        selectedNotifications={selectedNotifications}
        setNotifications={setNotifications}
      />
    )}
  </>
)
