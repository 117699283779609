import { ServerProductType } from '@expane/data'
import { useGetUnitsNameForConsumable } from '@expane/logic/product'
import {
  AddButtonHeaderCell,
  DeleteButtonCell,
  InputLabel,
  NumberInput,
  SelectDropdown,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { useGetConsumableAmount, useGetConsumablesTotalAmount } from 'logic/consumable'
import { customSelectDropDownProductSearchFilterFunction } from 'logic/product'
import { DEFAULT_CONSUMABLE_QUANTITY } from 'logic/service'
import { FC } from 'react'
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { store } from 'store'
import { ServiceDialogFormData } from 'widgets/ServiceDialog/ServiceDialogLogic'
import { permissions } from '@expane/logic/permission'
import { useFetchMyPermissions } from 'gql/employee'
import { OnCreateProductFunc, useOpenProductDialog } from 'widgets/ProductDialog'
import { checkOnlyPositiveAmount } from '@expane/logic/form'

interface Props {
  control: Control<ServiceDialogFormData>
  products: ServerProductType[]
  disabled?: boolean
  className?: string
}

export const ServiceConsumablesInputs: FC<Props> = ({ control, className, products, disabled }) => {
  const { t } = useTranslation()
  const branchId = store.branch.branchId

  const { data: myPermissions } = useFetchMyPermissions()

  const { fields, remove, append } = useFieldArray({ control, name: 'consumables' })
  const watchedConsumables = useWatch({ control, name: 'consumables' })

  const getUnitName = useGetUnitsNameForConsumable(branchId)
  const getProductAmount = useGetConsumableAmount()
  const getTotalAmount = useGetConsumablesTotalAmount()

  const isProductCreateAllowed = myPermissions?.includes(permissions.product.set)

  const { openCreateDialog: openCreateProductDialog, dialog: productDialog } =
    useOpenProductDialog()

  const onCreate: OnCreateProductFunc = (id, name, forSale) => {
    if (forSale) {
      return
    } else {
      const index = watchedConsumables.findIndex(consumable => consumable.productId === undefined)
      remove(index)
      append({ productId: id, bookingProductId: undefined, quantity: DEFAULT_QUANTITY })
    }
  }

  return (
    <div className={className + ' flex flex-col h-full'}>
      {fields.length ? (
        <>
          <InputLabel
            label={t('consumables')}
            onPlusClick={
              isProductCreateAllowed ? () => openCreateProductDialog(onCreate) : undefined
            }
          />

          <TableContainer>
            <TableHeader>
              <tr>
                <TableHeaderCell className="w-40">{t('title')}</TableHeaderCell>
                <TableHeaderCell className="w-25">{t('qty')}</TableHeaderCell>
                <TableHeaderCell className="w-12 text-right">{t('unit.shortName')}</TableHeaderCell>
                <TableHeaderCell className="w-25 text-right">{t('price')}</TableHeaderCell>
                <AddButtonHeaderCell
                  disabled={disabled}
                  onClick={() =>
                    append({
                      productId: undefined,
                      bookingProductId: undefined,
                      quantity: DEFAULT_CONSUMABLE_QUANTITY,
                    })
                  }
                />
              </tr>
            </TableHeader>
            <TableBody>
              {fields.map((consumable, index) => (
                <TableRow key={consumable.id}>
                  <TableCell>
                    <Controller
                      control={control}
                      rules={{ required: true }}
                      name={`consumables.${index}.productId` as 'consumables.0.productId'}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <SelectDropdown
                          value={value}
                          onSelectChange={onChange}
                          items={products}
                          errorMessage={{
                            isShown: Boolean(error),
                            text: ' ',
                            reserveIndent: false,
                          }}
                          height="small"
                          disabled={disabled}
                          customSearchFilterFunction={
                            customSelectDropDownProductSearchFilterFunction
                          }
                          className={'w-40'}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`consumables.${index}.quantity` as 'consumables.0.quantity'}
                      rules={{
                        required: true,
                        validate: {
                          checkOnlyPositiveAmount,
                          integer: quantity => Number.isInteger(Number(quantity)),
                        },
                      }}
                      render={({ field: { value, onChange }, fieldState: { error } }) => (
                        <NumberInput
                          value={value}
                          onChange={onChange}
                          errorMessage={{
                            isShown: Boolean(error),
                            text: ' ',
                            reserveIndent: false,
                          }}
                          height="small"
                          disabled={disabled}
                          className={'w-25'}
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell className="text-right w-12">
                    {t(getUnitName(watchedConsumables[index]?.productId))}
                  </TableCell>
                  <TableCell className="text-right shrink-0">
                    <div className={'w-25'}>
                      {getProductAmount(
                        watchedConsumables[index]?.productId,
                        watchedConsumables[index]?.quantity,
                        undefined,
                      )}
                    </div>
                  </TableCell>
                  <DeleteButtonCell onClick={() => remove(index)} disabled={disabled} />
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>

          <div className="py-2 text-right text-sm text-gray-500">
            {t('total')}: {getTotalAmount(watchedConsumables)}
          </div>
        </>
      ) : (
        !disabled && (
          <button
            onClick={() =>
              append({
                productId: undefined,
                bookingProductId: undefined,
                quantity: DEFAULT_CONSUMABLE_QUANTITY,
              })
            }
            className="text-gray-500 hover:text-primary-400 mt-2 self-end flex items-center"
          >
            <IoAddCircleOutline size="1.3rem" className="mr-1" />
            <p>{t('addConsumable')}</p>
          </button>
        )
      )}

      {productDialog}
    </div>
  )
}

const DEFAULT_QUANTITY = '1'
