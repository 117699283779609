import {
  ServerTransactionToCalcBalanceType,
  ServerTransactionType,
  useFetchAccounts,
} from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import {
  calcClientsDeposits,
  calcClientsPayments,
  calcOtherBusinessExpenses,
  calcOtherBusinessRevenue,
  calcProductsPurchase,
  calcProductsReturn,
  calcRefundsExpenses,
  calcSalariesExpenses,
  calcTransportCosts,
} from '@expane/logic/finances/calculation'
import { observer } from 'mobx-react-lite'
import {
  AccountsValuesEndOfDay,
  AccountsValuesStartOfDay,
} from 'pages/FinancesPage/FinancesInfoBlock/AccountsValuesByDayPart'
import { ExpensesValues } from 'pages/FinancesPage/FinancesInfoBlock/ExpensesValues'
import { RevenueValues } from 'pages/FinancesPage/FinancesInfoBlock/RevenueValues'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { PaperResizable } from 'ui/PaperResizable'

interface CashBoxInfoBlockProps {
  date: Date
  transactions: ServerTransactionType[]
  transactionsToCalcBalance: ServerTransactionToCalcBalanceType[]
}

export const CashBoxInfoBlock: FC<CashBoxInfoBlockProps> = observer(
  ({ date, transactions, transactionsToCalcBalance }) => {
    const branchId = store.branch.branchId
    const { data: accounts } = useFetchAccounts(branchId)

    const { t } = useTranslation()

    // доходы
    const productsReturnTotalValue = calcProductsReturn(transactions)
    const otherBusinessRevenueTotalValue = calcOtherBusinessRevenue(transactions)

    const depositsValue = calcClientsDeposits(transactions)
    const { clientsPaymentsTotalValue } = calcClientsPayments(transactions)

    const revenueWithoutDepositsValue = productsReturnTotalValue + otherBusinessRevenueTotalValue
    const dailyRevenueValue = depositsValue + revenueWithoutDepositsValue

    // расходы
    const refundsTotalValue = calcRefundsExpenses(transactions)
    const salariesTotalValue = calcSalariesExpenses(transactions)
    const productsPurchaseTotalValue = calcProductsPurchase(transactions)
    const transportCostsTotalValue = calcTransportCosts(transactions)
    const otherBusinessExpensesTotalValue = calcOtherBusinessExpenses(transactions)

    const dailyExpensesWithoutRefundsValue =
      otherBusinessExpensesTotalValue +
      salariesTotalValue +
      productsPurchaseTotalValue +
      transportCostsTotalValue

    const dailyExpensesValue = dailyExpensesWithoutRefundsValue + refundsTotalValue

    // расчет дохода, расхода, прибыли за день
    const dailyProfit = dailyRevenueValue - dailyExpensesValue

    return (
      <PaperResizable
        className={'mr-4 p-4 self-start max-h-full overflow-y-auto'}
        defaultWidth={'1/5'}
      >
        <AccountsValuesStartOfDay
          accounts={accounts}
          date={date}
          transactionsToCalcBalance={transactionsToCalcBalance}
          branchId={branchId}
        />

        <CashBoxInfoItemTitle
          title={t('revenues')}
          className="text-primary-600"
          value={dailyRevenueValue}
          branchId={branchId}
        />

        <RevenueValues
          transactions={transactions}
          accounts={accounts}
          revenueWithoutDepositsValue={revenueWithoutDepositsValue}
          depositsValue={depositsValue}
          branchId={branchId}
        />

        <CashBoxInfoItemTitle
          title={t('expenses')}
          className="text-error-700"
          value={dailyExpensesValue}
          branchId={branchId}
        />

        <ExpensesValues
          transactions={transactions}
          accounts={accounts}
          refundsValue={refundsTotalValue}
          dailyExpensesWithoutRefundsValue={dailyExpensesWithoutRefundsValue}
          branchId={branchId}
        />

        <AccountsValuesEndOfDay
          accounts={accounts}
          date={date}
          transactionsToCalcBalance={transactionsToCalcBalance}
          branchId={branchId}
        />

        <ul className="border-t border-primary-50 mt-3 pt-3">
          <CashBoxInfoItem
            title={t('profit')}
            className="text-primary-600 font-medium"
            value={dailyProfit}
            branchId={branchId}
          />

          {clientsPaymentsTotalValue !== 0 ? (
            <CashBoxInfoItem
              title={t('spentFromClientAccounts')}
              className="text-gray-600 dark:text-gray-400 font-medium text-sm mt-3"
              value={clientsPaymentsTotalValue}
              branchId={branchId}
            />
          ) : null}
        </ul>
      </PaperResizable>
    )
  },
)

interface CashBoxInfoItemProps {
  title: string
  value: number
  className?: string
}

export const CashBoxInfoItem: FC<PropsWithBranchId<CashBoxInfoItemProps>> = ({
  title,
  value,
  className = '',
  branchId,
}) => {
  const convertToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  return (
    <li className={'flex justify-between items-center ' + className}>
      <p className="truncate mr-1">{title}</p>
      <p>{convertToMoney(value)}</p>
    </li>
  )
}

interface CashBoxInfoItemTitleProps {
  title: string
  value: number
  className?: string
}

export const CashBoxInfoItemTitle: FC<PropsWithBranchId<CashBoxInfoItemTitleProps>> = ({
  title,
  value,
  className,
  branchId,
}) => {
  const convertToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  return (
    <div className="flex justify-between items-center">
      <p className={'font-medium my-2 ' + className}>{title}</p>
      <p className={'font-medium my-2 ' + className}>{convertToMoney(value)}</p>
    </div>
  )
}
