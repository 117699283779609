import { EmployeeFromGroup } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { getEmployeeSalaryFromCountedSalariesFullInfo } from '@expane/logic/salaryIssues/helpers'
import { CountedSalariesFullInfoType } from '@expane/logic/salaryIssues/logic'
import { transformPersonName } from '@expane/logic/utils'
import { PlaceholderString, TableCell, TableRow } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchCurrentBusiness } from 'gql/business'
import { DatePickerType } from 'logic/hooks/useCustomDatePicker'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useOpenSalaryEmployeeDialog } from 'widgets/SalaryEmployeeDialog'

interface SalaryEmployeeListItemProps {
  employee: EmployeeFromGroup
  fromDate: Date
  toDate: Date
  countedSalariesFullInfo: Array<CountedSalariesFullInfoType>
  datePickerType: DatePickerType
}

export const SalaryEmployeeListItem: FC<PropsWithBranchId<SalaryEmployeeListItemProps>> = ({
  employee,
  fromDate,
  toDate,
  countedSalariesFullInfo,
  datePickerType,
  branchId,
}) => {
  const { data: currentBusiness } = useFetchCurrentBusiness()

  const [openSnackbar] = useSnackbar()

  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const employeeCountedSalariesFullInfo = getEmployeeSalaryFromCountedSalariesFullInfo(
    countedSalariesFullInfo,
    employee.id,
  )!

  const salaryToPayAmount =
    employeeCountedSalariesFullInfo.accruedSalary - employeeCountedSalariesFullInfo.paidSalary

  const { openEditSalaryEmployeeDialog, salaryEmployeeDialog } = useOpenSalaryEmployeeDialog({
    employee,
    fromDate,
    toDate,
    datePickerType,
  })

  const handleSalaryEmployeeDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openEditSalaryEmployeeDialog()
  }

  return (
    <>
      <TableRow onClick={handleSalaryEmployeeDialog}>
        <TableCell>{transformPersonName(employee)}</TableCell>
        <TableCell className={rowStyle}>
          {convertNumberToMoney(employeeCountedSalariesFullInfo.debtSalary.totalSum)}
        </TableCell>
        <TableCell className={rowStyle}>
          {convertNumberToMoney(employeeCountedSalariesFullInfo.accruedSalary)}
        </TableCell>
        <TableCell className={rowStyle}>
          {convertNumberToMoney(employeeCountedSalariesFullInfo.paidSalary)}
        </TableCell>
        <TableCell className={rowStyle + ' pr-4'}>
          {convertNumberToMoney(salaryToPayAmount)}
        </TableCell>
      </TableRow>
      {salaryEmployeeDialog}
    </>
  )
}

const rowStyle = 'text-right'

export const SalaryEmployeeListItemLoading = () => {
  return (
    <TableRow>
      <TableCell className="pl-4 py-1 w-2/5">
        <PlaceholderString />
      </TableCell>
      <TableCell className={rowStyle}>
        <PlaceholderString className="ml-auto" />
      </TableCell>
      <TableCell className={rowStyle}>
        <PlaceholderString className="ml-auto" />
      </TableCell>
      <TableCell className={rowStyle}>
        <PlaceholderString className="ml-auto" />
      </TableCell>
      <TableCell className={rowStyle + ' pr-4'}>
        <PlaceholderString className="ml-auto" />
      </TableCell>
    </TableRow>
  )
}
