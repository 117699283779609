import { TFunction } from 'react-i18next'

// OTHER

export const showPhoneErrorMessage = (type: string, t: TFunction) => {
  if (type === 'required') return t('formError.required')
  if (type === 'validate') return t('formError.invalid')
  if (type === 'minLength') return t('formError.invalid')
  if (type === 'notInUse') return t('alreadyInUse')
}

export const showAccountErrorMessage = (type: string, t: TFunction) => {
  if (type === 'required') return t('formError.required')
  if (type === 'checkWithdrawPossibility') return t('formError.insufficientFunds')
  if (type === 'checkOnlyPositiveAmount') return t('formError.invalidValue')
}

export const showIntegerNumberFieldErrorMessage = (type: string, t: TFunction) => {
  if (type === 'required') return t('formError.required')
  if (type === 'checkValueIsIntegerNumber') return t('formError.invalidValue')
}

export const showRecurringIntervalFieldErrorMessage = (type: string, t: TFunction) => {
  if (type === 'required') return t('formError.required')
  if (type === 'checkValueIsIntegerNumber') return t('formError.invalidValue')
  if (type === 'checkMaxDayInterval') return t('recurringBookings.formError.invalidMaxDayInterval')
  if (type === 'checkMaxWeekInterval')
    return t('recurringBookings.formError.invalidMaxWeekInterval')
  if (type === 'checkMaxMonthInterval')
    return t('recurringBookings.formError.invalidMaxMonthInterval')
}
