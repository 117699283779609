import {
  BranchWithSchedule,
  ExtendedEmployee,
  PaymentTransaction,
  ServerAccountType,
  ServerProductType,
} from '@expane/data'
import { getTotalSumOfInvoice, SubmitQuickSaleDialogFormValues } from './logic'
import {
  CLIENT_ACCOUNT_ID,
  ClientDepositTransactionInputDto,
  generateOneAccountDepositTransactionsDto,
  generateSeveralAccountDepositTransactionsDto,
  PAYMENT_OPTIONS,
  spreadPaymentItemsQuantity,
} from '@expane/logic/payment'
import { calcTotalPrice } from '@expane/logic/utils'
import {
  calcTotalProductsPriceForServer,
  getGiftCardsWithCodeAndDiscount,
  getProductsWithDiscount,
  getServicesWithDiscount,
  getSubscriptionWithDiscount,
} from '@expane/logic/quickSale'
import {
  generateGiftCardsPaymentTransactionsForQuickSale,
  generateProductsPaymentTransactionsForQuickSale,
  generateServicesPaymentTransactionsForQuickSale,
  generateSubscriptionsPaymentTransactionsForQuickSale,
} from '@expane/logic/quickSale/submitLogic'
import { extractItemsFromFolders } from 'ui/TreeMenu/logic.common'
import { ProductItemForPayment, ServiceItemForPayment } from '@expane/logic/quickSale/types'

export const generateDepositTransactionsForQuickSale = (
  data: SubmitQuickSaleDialogFormValues,
  standingAccounts: ServerAccountType[],
  branchId: number,
) => {
  const {
    productItems,
    serviceItems,
    subscriptionItems,
    giftCardItems,
    paymentOption,
    accountId,
    addToClientAccount,
    paymentAmount,
    clientId,
    paymentToAccounts,
  } = data

  const totalInvoiceSum = getTotalSumOfInvoice({
    products: productItems,
    services: serviceItems,
    subscriptions: subscriptionItems,
    giftCards: giftCardItems,
  })
  let depositTransactionsDto: ClientDepositTransactionInputDto[] = []

  if (totalInvoiceSum <= 0) return depositTransactionsDto

  const isDepositToOneAccount =
    paymentOption.id === PAYMENT_OPTIONS[0].id && accountId !== CLIENT_ACCOUNT_ID
  if (isDepositToOneAccount) {
    const account = standingAccounts.find(acc => acc.id === data.accountId)

    depositTransactionsDto = generateOneAccountDepositTransactionsDto({
      accountId,
      amount: addToClientAccount ? Number(paymentAmount) : totalInvoiceSum,
      commission: account?.commission ?? null,
      clientId,
      branchId,
    })
  }

  const isDepositToSeveralAccounts = paymentOption.id === PAYMENT_OPTIONS[1].id
  if (isDepositToSeveralAccounts) {
    depositTransactionsDto = generateSeveralAccountDepositTransactionsDto({
      paymentToAccounts,
      standingAccounts,
      clientId,
      branchId,
    })
  }

  return depositTransactionsDto
}

export const generatePaymentTransactionsForQuickSale = ({
  data,
  allProducts,
  employees,
  branch,
  debt,
  branchId,
}: {
  data: SubmitQuickSaleDialogFormValues
  allProducts: ServerProductType[] | undefined
  employees: ExtendedEmployee[] | undefined
  branch: BranchWithSchedule | undefined
  debt: number
  branchId: number
}): PaymentTransaction[] => {
  const { subscriptionItems, giftCardItems, productItems, serviceItems, storageId, clientId } = data

  // нужно для того, чтобы дальше не прокидывать undefined
  const employeeId = data.employeeId ?? null

  let mutableDebt = debt

  const transactions: PaymentTransaction[] = []

  // SUBSCRIPTIONS
  const subscriptionsWithDiscount = getSubscriptionWithDiscount(subscriptionItems, clientId)
  const totalPriceForSubscriptions = calcTotalPrice(subscriptionsWithDiscount)

  if (subscriptionsWithDiscount.length) {
    const servicePaymentIsInCredit = mutableDebt > 0

    const subscriptionsPaymentTransactions = generateSubscriptionsPaymentTransactionsForQuickSale({
      debt,
      totalPriceForSubscriptions,
      clientId,
      subscriptionsWithDiscount,
      isPaymentOnCredit: servicePaymentIsInCredit,
      branchId,
    })

    transactions.push(...subscriptionsPaymentTransactions)

    if (mutableDebt > 0) mutableDebt = mutableDebt - totalPriceForSubscriptions
  }

  // GIFT CARDS
  const giftCardsWithCodeAndDiscount = getGiftCardsWithCodeAndDiscount(giftCardItems, clientId)
  const totalPriceForGiftCards = calcTotalPrice(giftCardsWithCodeAndDiscount)

  if (giftCardsWithCodeAndDiscount.length) {
    const giftCardPaymentIsInCredit = mutableDebt > 0

    const giftCardsPaymentTransactions = generateGiftCardsPaymentTransactionsForQuickSale({
      debt,
      isPaymentOnCredit: giftCardPaymentIsInCredit,
      totalPriceForGiftCards,
      clientId,
      giftCardsWithCodeAndDiscount,
      branchId,
    })

    transactions.push(...giftCardsPaymentTransactions)

    if (mutableDebt > 0) mutableDebt = mutableDebt - totalPriceForGiftCards
  }

  // PRODUCTS
  const products = extractItemsFromFolders<ProductItemForPayment>(productItems)
  const productsWithDiscount = getProductsWithDiscount(products as ProductItemForPayment[])
  const totalPriceForProducts = calcTotalProductsPriceForServer(productsWithDiscount)

  if (productsWithDiscount.length) {
    const productPaymentIsInCredit = mutableDebt > 0

    const productsPaymentTransactions = generateProductsPaymentTransactionsForQuickSale({
      debt,
      isPaymentOnCredit: productPaymentIsInCredit,
      storageId,
      totalPriceForProducts,
      clientId,
      productsWithDiscount,
      branchId,
    })

    transactions.push(...productsPaymentTransactions)

    if (mutableDebt > 0) mutableDebt = mutableDebt - totalPriceForProducts
  }

  // SERVICES
  const spreadedServices = spreadPaymentItemsQuantity(serviceItems)
  const services = extractItemsFromFolders(spreadedServices)
  const servicesWithDiscount = getServicesWithDiscount(services as ServiceItemForPayment[])
  const totalPriceForServices = calcTotalPrice(servicesWithDiscount)

  if (servicesWithDiscount.length) {
    const servicePaymentIsInCredit = mutableDebt > 0

    const servicesPaymentTransactions = generateServicesPaymentTransactionsForQuickSale({
      debt,
      serviceItems,
      allProducts,
      employees,
      employeeId,
      branch,
      isPaymentOnCredit: servicePaymentIsInCredit,
      servicesWithDiscount,
      clientId,
      totalPriceForServices,
      branchId,
    })

    transactions.push(...servicesPaymentTransactions)
  }

  return transactions
}
