import {
  createCurrentDate,
  differenceInHours,
  isDateSameDayOrAfter,
  isDateSameDayOrBefore,
  isSameDay,
  startOfDay,
  useStartOfCurrentDay,
} from '@expane/date'
import {
  DayTiming,
  DynamicSchedule,
  ScheduleDto,
  ScheduleType,
  ServerDynamicScheduleDateType,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { defineDayTiming, getIsWorkingDayFromIntervalSchedule } from '@expane/logic/calendar'
import { store } from 'store'
import { useFetchMyPermissions } from 'gql/employee'
import { permissions } from '@expane/logic/permission'

export const useCheckBookingDateEditable = (bookingDate: Date) => {
  const { data: myPermissions } = useFetchMyPermissions()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const startOfCurrentDay = useStartOfCurrentDay(timezone)

  // За правилом використання хуків опціональний return повинен бути після них
  if (myPermissions?.includes(permissions.booking.editPast)) return true

  const startOfBookingDay = startOfDay(bookingDate)

  return differenceInHours(startOfBookingDay, startOfCurrentDay) >= 0
}

export const checkBookingIsTodayOrLater = (bookingDate: Date, timezone: string | undefined) => {
  const startOfCurrentDay = startOfDay(createCurrentDate(timezone))
  return differenceInHours(bookingDate, startOfCurrentDay) >= 0
}

const checkStartHourWithinDayTiming = (startHour: number, dayTiming: DayTiming) => {
  return startHour >= dayTiming[0] && startHour < dayTiming[1]
}
const findDynamicDate = (dynamicDates: ServerDynamicScheduleDateType[], currentDate: Date) =>
  dynamicDates.find(dynamicDate => isSameDay(currentDate, dynamicDate.date))

export const checkCellIsDisabledByDynamicSchedule = ({
  employeeSchedule,
  currentWeekDate,
  startHour,
}: {
  employeeSchedule: DynamicSchedule
  currentWeekDate: Date
  startHour: number
}) => {
  const { dynamicDates } = employeeSchedule
  const dynamicDate = findDynamicDate(dynamicDates, currentWeekDate)
  if (!dynamicDate) return true

  return !checkStartHourWithinDayTiming(startHour, [dynamicDate.timingStart, dynamicDate.timingEnd])
}
export const checkCellIsDisabledByRegularSchedule = (params: {
  schedule: ScheduleDto | undefined
  startHour: number
  currentWeekDate: Date
  // ignore if branch schedule
  scheduleStartDate?: Date
  scheduleEndDate?: Date
}): boolean => {
  const { schedule, startHour, currentWeekDate, scheduleStartDate, scheduleEndDate } = params

  // handle date out of schedule range
  if (scheduleStartDate && !isDateSameDayOrAfter(currentWeekDate, scheduleStartDate)) return true
  if (scheduleEndDate && !isDateSameDayOrBefore(currentWeekDate, scheduleEndDate)) return true

  const dayTiming = defineDayTiming(schedule, currentWeekDate)

  if (schedule?.type === ScheduleType.WEEKLY) {
    return !checkStartHourWithinDayTiming(startHour, dayTiming)
  }
  if (schedule?.type === ScheduleType.INTERVAL) {
    const isWorkingDay = getIsWorkingDayFromIntervalSchedule(
      schedule,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scheduleStartDate!, // если у расписания тип интервал, то scheduleStartDate точно есть
      currentWeekDate,
    )

    return !isWorkingDay || !checkStartHourWithinDayTiming(startHour, dayTiming)
  }
  return false
}
