import { FC } from 'react'
import { EditButton } from 'ui/EditButton'
import { ToEmployeeNotificationType } from '@expane/data'
import { notificationContainerStyle, NotificationItemProps, textStyle } from './logic'
import { NotificationItemHeader } from './HeaderItem'

interface ToEmployeeNotificationItemProps extends Omit<NotificationItemProps, 'item'> {
  notification: ToEmployeeNotificationType
}

export const ToEmployeeNotificationItem: FC<ToEmployeeNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
  openBookingDialog,
}) => {
  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="flex justify-between">
          <div className="text-sm py-1">
            <p className={textStyle}>{notification.message}</p>
          </div>

          <div className="pt-1">
            <EditButton
              onClick={() => {
                if (notification.bookingId) openBookingDialog?.(notification.bookingId)
              }}
            />
          </div>
        </div>
      </div>
    </li>
  )
}
