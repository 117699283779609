import { checkOnlyPositiveAmount, PLACEHOLDERS } from '@expane/logic/form'
import { translateItemsName } from '@expane/logic/utils'
import { Input, NumberInput, SelectDropdown, Switch, Textarea } from '@expane/ui'
import { DURATIONS, SERVICE_COLORS_LIST } from 'logic/service'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ColorPicker } from 'ui/ColorPicker'
import { InputWithDropDownMenu } from 'ui/InputWithDropDownMenu'
import { EditablePhoto } from 'widgets/EditablePhoto'
import {
  InterbranchServiceFormControl,
  InterbranchServiceFormSetValue,
  interbranchServiceTypes,
} from '../logic'

interface InterbranchServiceCommonFieldsProps {
  control: InterbranchServiceFormControl
  disabled: boolean
  setValue: InterbranchServiceFormSetValue
}

export const InterbranchServiceCommonFields: FC<InterbranchServiceCommonFieldsProps> = ({
  control,
  disabled,
  setValue,
}) => {
  const { t } = useTranslation()

  // TODO додати перевірку - якщо послуга вже десь використовувалася
  // const isServiceInCardTemplate =
  //   !isCreate && Boolean(interbranchServiceById?.cardTemplateServices.length)
  // const isServiceInBookings =
  //   !isCreate &&
  //   (Boolean(interbranchServiceById?.bookingServices.length) ||
  //     Boolean(interbranchServiceById?.groupBookings.length))
  // const isServiceInTransactions =
  //   !isCreate && Boolean(interbranchServiceById?.transactionsServices.length)
  // const isServiceTypeChangingAllowed =
  //   !isServiceInBookings && !isServiceInCardTemplate && !isServiceInTransactions
  const isServiceTypeChangingAllowed = true
  // END TODO

  return (
    <div className="flex w-full mb-2">
      <div className="w-5/6">
        <div className="flex gap-2">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                containerClassName="w-1/2"
                label={t('title')}
                placeholder={t('placeholders.serviceName')}
                required
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.required'),
                }}
                value={value}
                onChange={onChange}
                disabled={disabled}
                autoFocus
              />
            )}
          />

          <Controller
            control={control}
            name="type"
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectDropdown
                label={t('type')}
                required={isServiceTypeChangingAllowed}
                placeholder={t('placeholders.defaultSelect')}
                hint={isServiceTypeChangingAllowed ? undefined : t('serviceTypeHint')}
                items={translateItemsName(interbranchServiceTypes, t)}
                onSelectChange={onChange}
                className="w-1/4"
                value={value}
                disabled={disabled || !isServiceTypeChangingAllowed}
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.required'),
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="defaultDuration"
            rules={{ required: true, validate: checkOnlyPositiveAmount }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputWithDropDownMenu
                className="w-1/4"
                label={t('defaultDuration.name')}
                items={DURATIONS}
                value={value}
                onInputChange={onChange}
                placeholder={PLACEHOLDERS.subscriptionDurations}
                disabled={disabled}
                type="number"
                hint={t('defaultDuration.hint')}
                errorMessage={{
                  isShown: Boolean(error),
                  text:
                    error?.type === 'required'
                      ? t('formError.required')
                      : t('formError.invalidValue'),
                  reserveIndent: true,
                }}
                required
              />
            )}
          />
        </div>
        <div className="flex gap-2">
          <Controller
            name="description"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textarea
                containerClassName="w-1/2"
                label={t('description')}
                placeholder={t('placeholders.serviceDescription')}
                value={value}
                rows={2}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />

          <Controller
            name="price"
            control={control}
            rules={{ required: true, validate: checkOnlyPositiveAmount }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberInput
                containerClassName="w-1/4"
                label={t('price')}
                placeholder={PLACEHOLDERS.servicePrice}
                required
                errorMessage={{
                  isShown: Boolean(error),
                  text:
                    error?.type === 'required'
                      ? t('formError.required')
                      : t('formError.invalidValue'),
                }}
                value={value}
                onChange={onChange}
                disabled={disabled}
              />
            )}
          />

          <Controller
            name="costPrice"
            control={control}
            rules={{ validate: checkOnlyPositiveAmount }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <NumberInput
                containerClassName="w-1/4"
                label={t('costPrice')}
                placeholder={PLACEHOLDERS.servicePrice}
                value={value}
                onChange={onChange}
                disabled={disabled}
                errorMessage={{
                  isShown: Boolean(error),
                  text: t('formError.invalidValue'),
                }}
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="availableForClient"
          render={({ field: { onChange, value } }) => (
            <Switch
              containerClassName="mt-4"
              onChange={onChange}
              checked={value}
              disabled={disabled}
              label={t('availableForClient.name')}
              hint={t('availableForClient.hint')}
            />
          )}
        />
      </div>
      <div className="flex flex-col items-center w-1/6 gap-2">
        <Controller
          name="picture"
          control={control}
          render={({ field: { value, onChange } }) => (
            <EditablePhoto
              containerClassName="mt-5"
              defaultPhoto={value}
              onChange={onChange}
              disabled={disabled}
              size="medium"
            />
          )}
        />

        <Controller
          control={control}
          name="color"
          render={({ field: { value, onChange } }) => (
            <ColorPicker
              value={value}
              label={t('color')}
              hint={t('colorHint')}
              onChange={onChange}
              colors={SERVICE_COLORS_LIST.map(color => color.bgColor)}
              disabled={disabled}
              clearFunction={() => setValue('color', undefined, { shouldDirty: true })}
            />
          )}
        />
      </div>
    </div>
  )
}
