import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_VARIATION, NotificationUnionType } from '@expane/data'
import { Dispatch, SetStateAction } from 'react'
import { TagType } from '@expane/ui'
import { TFunction } from 'i18next'

export interface NotificationItemProps {
  item: NotificationUnionType
  openBookingDialog: ((number) => void) | undefined
  selectedNotifications: Array<number>
  setNotifications: Dispatch<SetStateAction<Array<number>>>
  timezone: string
}

export const notificationContainerStyle =
  'w-full pt-1 px-3 border-2 border-gray-100 dark:border-gray-500 rounded-lg'
export const labelStyle = 'mr-1 text-gray-400 text-sm mb-1'
export const textStyle = 'text-main-color font-medium text-sm mb-1'

export const checkIsNotificationSelected = (notifications: Array<number>, id: number) =>
  notifications.includes(id)

export const getTagProps = (
  notification: NotificationUnionType,
  t: TFunction,
): TagType | undefined => {
  if (notification.type === NOTIFICATION_TYPE.waitingList)
    return {
      name: t('notificationsTypes.freeTimeForBooking'),
      color: 'yellow-300',
    }
  if (notification.type === NOTIFICATION_TYPE.bookingCreatedByClient)
    return {
      name: t('notificationsTypes.bookingCreatedByTheClient'),
      color: 'indigo-400',
    }
  if (notification.type === NOTIFICATION_TYPE.billingReminder)
    return {
      name: t('notificationsTypes.billingReminder'),
      color: 'cyan-700',
    }

  if (notification.type === NOTIFICATION_TYPE.review)
    return {
      name: t('notificationsTypes.review'),
      color: 'amber-400',
    }

  if (notification.type === NOTIFICATION_TYPE.toEmployee) {
    if (notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingUpdateTime)
      return {
        name: t('notificationsTypes.updateBookingTime'),
        color: 'yellow-600',
      }
    if (notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingCancel)
      return {
        name: t('notificationsTypes.bookingCancel'),
        color: 'red-500',
      }
    if (notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingInPlaceStatus)
      return {
        name: t('notificationsTypes.receivingOnInPlaceStatus'),
        color: 'green-400',
      }
    // notification.typeVariation === NOTIFICATION_TYPE_VARIATION.bookingInsert or !notification.typeVariation
    return {
      name: t('notificationsTypes.bookingInsert'),
      color: 'green-600',
    }
  }
}
