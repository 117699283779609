import {
  BranchToSwitchAsClientType,
  ScheduleDtoWithBranchIdAndEmployee,
  ServerBusinessToSwitch,
  ServerProductType,
  useFetchAccounts,
  useFetchBranches,
  useFetchClientsBalance,
  useFetchProducts,
} from '@expane/data'
import { TFunction } from 'react-i18next'

export type PropsWithBranchId<P = unknown> = P & { branchId: number | undefined }

export const useCheckIsOneBranchInBusiness = () => {
  const { data: branches, isLoading } = useFetchBranches()

  const isOneBranchInBusiness = branches ? branches.length === 1 : false

  return { isOneBranchInBusiness, isLoading }
}

export const checkIsBranchSchedule = (schedule: ScheduleDtoWithBranchIdAndEmployee | undefined) =>
  Boolean(schedule?.branch.length)

export const getBranchOrBusinessNameForSelect = (
  branchName: string,
  businessName: string,
  address?: string | null,
) => {
  const businessWithBranchName =
    businessName.includes(branchName) || branchName.includes(businessName)
      ? branchName
      : `${businessName}, ${branchName}`

  return address ? `${businessWithBranchName}, ${address}` : businessWithBranchName
}

export const getBranchOrBusinessNameToSwitchAsClient = (
  branch: BranchToSwitchAsClientType,
  businesses: ServerBusinessToSwitch[],
) => {
  const businessName = businesses.find(business => business.id === branch.businessId)?.name

  return getBranchOrBusinessNameForSelect(branch.name, businessName ?? '', branch?.address)
}

export const generateDisplayCoinsWarningMessage = ({
  areAccountsWithNonZeroCents,
  areClientBalanceWithNonZeroCents,
  areProductsWithNonZeroCents,
  t,
}: {
  areAccountsWithNonZeroCents: boolean
  areClientBalanceWithNonZeroCents: boolean
  areProductsWithNonZeroCents: boolean
  t: TFunction
}): string => {
  const reasons: string[] = []

  if (areAccountsWithNonZeroCents) {
    reasons.push(t('displayCoinsWarnings.accountsHaveNonZeroCents'))
  }

  if (areClientBalanceWithNonZeroCents) {
    reasons.push(t('displayCoinsWarnings.clientsBalanceHaveNonZeroCents'))
  }

  if (areProductsWithNonZeroCents) {
    reasons.push(t('displayCoinsWarnings.productsHaveNonZeroCents'))
  }

  if (reasons.length > 0) {
    return t('displayCoinsWarnings.cannotHideDisplayCoins', {
      reasons: reasons.join(', '),
    })
  }

  return ''
}

export const useGetNonZeroCentsBranchStatus = (branchId: number | undefined) => {
  const { data: accounts } = useFetchAccounts(branchId)
  const { data: clientsBalance } = useFetchClientsBalance(branchId)
  const { data: products } = useFetchProducts(branchId)

  if (!accounts || !clientsBalance || !products)
    return {
      areAccountsWithNonZeroCents: true,
      areClientBalanceWithNonZeroCents: true,
      areProductsWithNonZeroCents: true,
    }

  const areAccountsWithNonZeroCents =
    getIsWithNonZeroCentsBalance(
      accounts.map(account => ({ ...account, balance: account.balance ?? 0 })),
    ) ?? false
  const areClientBalanceWithNonZeroCents = getIsWithNonZeroCentsBalance(clientsBalance) ?? false
  const areProductsWithNonZeroCents = getIsWithNonZeroCentsProducts(products) ?? false

  return {
    areAccountsWithNonZeroCents,
    areClientBalanceWithNonZeroCents,
    areProductsWithNonZeroCents,
  }
}

export const useHasBranchNonZeroCents = (branchId: number | undefined) => {
  const {
    areAccountsWithNonZeroCents,
    areClientBalanceWithNonZeroCents,
    areProductsWithNonZeroCents,
  } = useGetNonZeroCentsBranchStatus(branchId)

  return (
    areAccountsWithNonZeroCents && areClientBalanceWithNonZeroCents && areProductsWithNonZeroCents
  )
}

const getIsWithNonZeroCentsBalance = <T extends { balance: number }>(data: T[] | undefined) => {
  return data?.some(item => {
    const decimalPart = item.balance % 1
    return decimalPart !== 0
  })
}

const getIsWithNonZeroCentsProducts = (products: ServerProductType[] | undefined) => {
  return products?.some(product => {
    const decimalPartPrice = product.price % 1
    const decimalPartCostPrice = product.costPrice % 1

    return decimalPartPrice !== 0 && decimalPartCostPrice !== 0
  })
}
