import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { SalaryItem } from '@expane/logic/salaryIssues/converting'
import {
  getClassNameForArrow,
  getPlacementStylesForArrow,
  modalsHTMLElement,
  Spinner,
  Table,
  useShowWarningPopup,
} from '@expane/ui'
import { arrow, offset, Side, useFloating } from '@floating-ui/react-dom'
import type { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline, IoCheckmark } from 'react-icons/io5'
import { TransactionDialog } from 'widgets/TransactionDialog'

interface SalaryPayListProps {
  salaryItems: Array<SalaryItem>
  isLoading: boolean
}

export const SalaryPayList: FC<PropsWithBranchId<SalaryPayListProps>> = ({
  salaryItems,
  isLoading,
  branchId,
}) => {
  const dateFormatting = useDateFormatting()

  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const columns = useMemo<ColumnDef<SalaryItem>[]>(
    () => [
      {
        id: 'icon',
        cell: data => {
          if (showIcon(data.row.original)) return <SalaryPayListInfo />
        },
        size: 30,
      },
      {
        accessorKey: 'date',
        header: t('dateTitle'),
        cell: data =>
          data.row.original.type === 'salaryRate' || data.row.original.type === 'referralPercentage'
            ? ''
            : dateFormatting('shortDate', data.getValue<Date>()),
        size: 80,
      },
      {
        accessorKey: 'name',
        header: t('name'),
        cell: data =>
          data.row.original.type === 'salary' ||
          data.row.original.type === 'salaryRate' ||
          data.row.original.type === 'premium'
            ? t(data.getValue<string>())
            : data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'additionalInfo',
        header: t('additionalInfo.short'),
        size: 300,
      },
      {
        accessorKey: 'totalSum',
        header: () => <span className="w-full text-right">{t('salary.name')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
      },
      {
        id: 'isSalaryPaid',
        accessorFn: salaryItem => salaryItem.isSalaryPaid || salaryItem.type === 'salary',
        header: () => <span className="w-full text-right">{t('salary.paid')}</span>,
        cell: data =>
          data.getValue<boolean>() ? <IoCheckmark size="1rem" className="ml-auto" /> : ' ',
      },
    ],
    [convertNumberToMoney, dateFormatting, t],
  )

  const { dialog: transactionDialog, openEditDialog: editTransactionDialog } =
    useOpenDialog(TransactionDialog)

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('salary.noPaymentForTheService'),
  )

  const showIcon = (salaryItem: SalaryItem) =>
    salaryItem.type === 'service' && salaryItem.isCountedByBackUpFormula

  if (isLoading) return <Spinner expandCentered />

  return (
    <>
      <Table
        columns={columns}
        data={salaryItems}
        onRowClick={salaryItem => {
          if (salaryItem.transactionId) editTransactionDialog(salaryItem.transactionId)
          if (salaryItem.type === 'service' && !salaryItem.transactionId) showWarningPopup()
        }}
      />
      {warningModal}
      {transactionDialog}
    </>
  )
}

export const SalaryPayListInfo: FC = () => {
  const { t } = useTranslation()

  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const {
    reference,
    floating,
    strategy,
    x,
    y,
    middlewareData: { arrow: arrowData },
    placement,
  } = useFloating({
    middleware: [
      offset(5),
      arrow({
        element: arrowRef,
      }),
    ],
  })
  const side = placement.split('-')[0] as Side
  const arrowPlacementStyles = getPlacementStylesForArrow(side, arrowData)
  const arrowClassName = getClassNameForArrow(side)

  return (
    <>
      <div
        ref={reference}
        onClick={e => {
          e.stopPropagation()
          setIsOpen(true)
        }}
        onMouseLeave={() => setIsOpen(false)}
        className="cursor-pointer"
      >
        <IoAlertCircleOutline />
      </div>

      {isOpen &&
        createPortal(
          <div
            ref={floating}
            style={{
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
            }}
            className={messageStyle + ' w-64'}
          >
            <div ref={arrowRef} style={arrowPlacementStyles} className={arrowClassName} />

            <p>{t('salary.isCountedByBackUpFormula')}</p>
          </div>,
          modalsHTMLElement,
        )}
    </>
  )
}

const messageStyle =
  'max-w-sm p-2 text-sm rounded text-gray-500 dark:text-gray-400 bg-block border border-gray-500 space-y-1'
