import { FC } from 'react'
import { CalendarCell, CalendarColumn, CalendarTitleCell } from 'ui/Calendar'
import {
  getCalendarStartHour,
  getCalendarFinishHour,
  generateHourCells,
} from '@expane/logic/calendar'
import { generateWeeklyColumns } from 'pages/BookingsPage/BookingsCalendar/logic'
import { WeeklySchedule } from '@expane/data'
import { addHours, formatDayMonth, isEqual, isPast, set } from '@expane/date'
import i18next from 'i18next'
import { IoAddCircleOutline, IoCloseCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5'
import { Control, useFieldArray } from 'react-hook-form'
import { WaitingListFormValues } from 'widgets/WaitingListDialog/waitingListDialogLogic'

interface Props {
  branchSchedule: WeeklySchedule
  pickedDate: Date
  control: Control<WaitingListFormValues>
}

export const WaitingListCalendar: FC<Props> = ({ control, branchSchedule, pickedDate }) => {
  const startHour = Math.trunc(getCalendarStartHour(branchSchedule))
  const endHour = getCalendarFinishHour(branchSchedule)

  const columns = generateHourCells(startHour, endHour, HOUR_STEP)

  const rows = generateWeeklyColumns(pickedDate)

  const { append, remove, fields } = useFieldArray<WaitingListFormValues>({
    control,
    name: 'dates',
  })

  return (
    <div className={'overflow-hidden flex'}>
      <CalendarColumn className="w-32 border-r border-l border-calendar-cell bg-block">
        <CalendarTitleCell height={'middle'} />

        {rows.map((column, index) => {
          const locale = i18next.language
          const columnName = column.date.toLocaleDateString(locale, {
            weekday: 'short',
          })
          const columnWeekdayLabel = formatDayMonth(column.date)

          return (
            <CalendarCell key={index} solidBorder fullHour lastColumn>
              <p className={'mr-2'}>{columnName}</p>
              <p>{columnWeekdayLabel}</p>
            </CalendarCell>
          )
        })}
      </CalendarColumn>

      {columns.map((timeColumn, index) => (
        <CalendarColumn key={index} className="grow basis-0 relative border-calendar-cell">
          <div>
            {rows.map((row, index) => {
              const date = set(row.date, {
                hours: timeColumn.startHour,
              })

              const isSelected = fields.some(field => isEqual(field.start, date))

              const arrayIndex = fields.findIndex(field => isEqual(field.start, date))

              const isCellPast = isPast(date)

              return (
                <CalendarCell
                  key={index}
                  solidBorder
                  fullHour
                  className={`${
                    isSelected ? 'bg-primary-500' : isCellPast ? 'bg-gray-100' : 'bg-block'
                  }`}
                  disabled={isCellPast}
                >
                  {isSelected ? (
                    <RemoveDateButton onClick={() => remove(arrayIndex)} />
                  ) : isCellPast ? (
                    <DisabledButton />
                  ) : (
                    <AddDynamicDateButton
                      onClick={() => append({ start: date, end: addHours(date, 1) })}
                    />
                  )}
                </CalendarCell>
              )
            })}
          </div>

          <CalendarTitleCell height={'middle'} rightBorder>
            {timeColumn.title}
          </CalendarTitleCell>
        </CalendarColumn>
      ))}
    </div>
  )
}

const HOUR_STEP = 1

const AddDynamicDateButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={'opacity-0 hover:opacity-100'}>
      <IoAddCircleOutline size="1.5rem" className="text-primary-600" />
    </button>
  )
}

const RemoveDateButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={'cursor-default opacity-0 hover:opacity-100'}>
      <IoCloseCircleOutline size="1.5rem" className="text-error-600" />
    </button>
  )
}

const DisabledButton = () => {
  return (
    <button className={'cursor-default opacity-0 hover:opacity-100'}>
      <IoRemoveCircleOutline size="1.5rem" className="text-error-600" />
    </button>
  )
}
