import { ServerProductType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import {
  useConvertNumberToMoneyWithoutSymbol,
  useConvertNumberToMoneySymbol,
} from '@expane/logic/currency'
import { ProductsAmount } from '@expane/logic/movement'
import {
  checkUnitCanBeFloat,
  convertUnitValueFromServer,
  getUnitsName,
} from '@expane/logic/product'
import { findById, roundValue } from '@expane/logic/utils'
import {
  DeleteButtonCell,
  NumberInput,
  PlaceholderString,
  SelectDropdown,
  SelectDropDownItem,
  TableCell,
} from '@expane/ui'
import { FC } from 'react'
import { Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  InventoryCreateDialogFormValues,
  InventoryCreateProps,
} from 'widgets/InventoryCreateDialog'

interface InventoryCreateListItemProps extends InventoryCreateProps {
  setValue: UseFormSetValue<InventoryCreateDialogFormValues>
  index: number
  products: ServerProductType[]
  leftovers: ProductsAmount | undefined
  isLoadingLeftovers: boolean
  onDelete: () => void
  disabled: boolean
}

export const InventoryCreateListItem: FC<PropsWithBranchId<InventoryCreateListItemProps>> = ({
  setValue,
  control,
  index,
  leftovers,
  isLoadingLeftovers,
  products,
  onDelete,
  disabled,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertToMoneySymbol = useConvertNumberToMoneySymbol(branchId)
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const product = useWatch({ control, name: `products.${index}` })

  const actualQuantity = Number(product?.actualQuantity)

  const divergence =
    actualQuantity >= 0 && product.unit && product.id
      ? roundValue(
          actualQuantity -
            (leftovers?.[product.id]
              ? convertUnitValueFromServer(leftovers[product.id], product.unit)
              : 0),
          'thousandths',
        )
      : 0

  const costOfDivergence = convertToMoneySymbol(
    actualQuantity >= 0 ? divergence * (product.costPrice ?? 0) : 0,
  )

  let divergenceCellStyle = ''

  if (divergence > 0) divergenceCellStyle += ' text-primary-500'
  if (divergence < 0) divergenceCellStyle += ' text-error-500'

  const onSelectChange = (id: number | null) => {
    if (id) {
      const selectedProduct = findById(id, products)

      if (selectedProduct) {
        setValue(
          `products.${index}`,
          {
            id: selectedProduct.id,
            name: selectedProduct.name,
            costPrice: selectedProduct.costPrice,
            price: selectedProduct.price,
            unit: selectedProduct.unit,
            vendorCode: selectedProduct.vendorCode,
            actualQuantity: product.actualQuantity,
          },
          { shouldTouch: true },
        )
      }
    }
  }

  return (
    <>
      <Controller
        control={control}
        name={`products.${index}.id` as 'products.0.id'}
        render={({ field: { value } }) => (
          <>
            <TableCell className={selectCellStyle}>
              <SelectDropdown
                className="w-32"
                height="small"
                onSelectChange={onSelectChange}
                value={value}
                items={products.map(
                  (item): SelectDropDownItem => ({
                    id: item.id,
                    name: item?.vendorCode ?? '-',
                  }),
                )}
              />
            </TableCell>
            <TableCell className={selectCellStyle}>
              <SelectDropdown
                className="w-64"
                height="small"
                onSelectChange={onSelectChange}
                value={value}
                items={products}
              />
            </TableCell>
          </>
        )}
      />
      <Controller
        control={control}
        name={`products.${index}.unit` as 'products.0.unit'}
        render={({ field: { value } }) => (
          <TableCell className="text-right">{value ? getUnitsName(value, t) : '-'}</TableCell>
        )}
      />

      <Controller
        control={control}
        name={`products.${index}.costPrice` as 'products.0.costPrice'}
        render={({ field: { value } }) => (
          <TableCell className="text-right">{value ? convertNumberToMoney(value) : '-'}</TableCell>
        )}
      />

      <Controller
        control={control}
        name={`products.${index}.price` as 'products.0.price'}
        render={({ field: { value } }) => (
          <TableCell className="text-right">{value ? convertNumberToMoney(value) : '-'}</TableCell>
        )}
      />

      <TableCell className="text-right">
        {isLoadingLeftovers ? (
          <PlaceholderString width="small" />
        ) : product.id && leftovers?.[product.id] && product.unit ? (
          convertUnitValueFromServer(leftovers[product.id], product.unit)
        ) : (
          '0'
        )}
      </TableCell>
      <Controller
        control={control}
        name={`products.${index}.actualQuantity` as 'products.0.actualQuantity'}
        rules={{
          required: true,
          validate: quantity => {
            if (product.unit === undefined) return false

            if (checkUnitCanBeFloat(product.unit)) return true

            return Number.isInteger(Number(quantity))
          },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <TableCell>
            <NumberInput
              className="text-right"
              value={value}
              onChange={onChange}
              height="small"
              disabled={!product?.id}
              errorMessage={{
                isShown: Boolean(error),
                text: ' ',
                reserveIndent: false,
              }}
            />
          </TableCell>
        )}
      />
      <TableCell className={divergenceCellStyle + ' text-right'}>{divergence ?? '-'}</TableCell>
      <TableCell className={divergenceCellStyle + ' text-right'}>
        {divergence ? costOfDivergence : '-'}
      </TableCell>
      <DeleteButtonCell onClick={onDelete} disabled={disabled} />
    </>
  )
}

const selectCellStyle = 'px-0.5 py-0.5'
