import { ServerExtendedServiceType, ServerServiceGroupType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { findById } from '@expane/logic/utils'
import {
  AddButtonHeaderCell,
  DeleteButtonCell,
  NumberInput,
  PlaceholderInput,
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { FC, useEffect, useMemo } from 'react'
import {
  Control,
  Controller,
  useFieldArray,
  UseFormSetValue,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ArrowButton } from 'ui/ArrowButton'
import { TreeMenuItem } from 'ui/TreeMenu'
import { transformDataForTreeMenu } from 'ui/TreeMenu/logic.common'
import { TreeSelect } from 'ui/TreeSelect'
import {
  calcTotalSum,
  generateSubscriptionServicesErrorMessage,
  getServiceItemsWithInactiveReason,
  SubscriptionFormValues,
} from '../logic'
import { tableItemStyle, TotalSumCell } from './TotalSumCell'

interface Props {
  control: Control<SubscriptionFormValues>
  setValue: UseFormSetValue<SubscriptionFormValues>
  services: ServerExtendedServiceType[]
  serviceGroups: ServerServiceGroupType[]
  disabled: boolean
}

export const SubscriptionServices: FC<PropsWithBranchId<Props>> = ({
  disabled,
  control,
  setValue,
  services,
  serviceGroups,
  branchId,
}) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoneyCode({ branchId })

  const watchedServices = useWatch({ control, name: 'cardTemplateServices' })
  const watchedFormState = useFormState({ control })

  const { fields, remove, append } = useFieldArray({ control, name: 'cardTemplateServices' })

  const serviceItems = transformDataForTreeMenu(serviceGroups, services, {
    keepEmptyFolders: false,
  })

  const serviceItemsWithInactiveReason = getServiceItemsWithInactiveReason(serviceItems, fields, t)

  const sum = useMemo(() => calcTotalSum(watchedServices), [watchedServices])

  useEffect(() => {
    setValue('price', sum.toString())
  }, [setValue, sum])

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-95">{t('service.name')}</TableHeaderCell>
          <TableHeaderCell className="w-28 text-right">{t('oneTimePrice')}</TableHeaderCell>
          <TableHeaderCell className="w-32">{t('qty')}</TableHeaderCell>
          <TableHeaderCell className="w-32 tex-right">{t('subscription.priceIn')}</TableHeaderCell>
          <TableHeaderCell className="w-8"></TableHeaderCell>
          <TableHeaderCell className="w-32 text-right">{t('amount')}</TableHeaderCell>
          <AddButtonHeaderCell
            onClick={() =>
              append({
                serviceItem: undefined,
                servicePrice: '0',
                serviceQuantity: '1',
              })
            }
            disabled={disabled}
          />
        </tr>
      </TableHeader>

      <TableBody>
        {fields?.map((cardTemplateService, index) => (
          <TableRow
            key={cardTemplateService?.id ?? -index}
            errorMessage={{
              isShown: Boolean(watchedFormState.errors.cardTemplateServices?.[index]),
              text: generateSubscriptionServicesErrorMessage(watchedFormState, index, t),
            }}
          >
            <Controller
              name={`cardTemplateServices.${index}.serviceItem`}
              control={control}
              rules={{ validate: value => value !== undefined }}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const price = findById(value?.id, services)?.price ?? 0

                return (
                  <>
                    <TableCell>
                      <TreeSelect
                        type="SinglePickMode"
                        items={serviceItemsWithInactiveReason}
                        onSelected={onChange}
                        selected={value as TreeMenuItem}
                        placeholder={t('placeholders.defaultSelect')}
                        errorMessage={{
                          isShown: Boolean(error),
                          text: ' ',
                          reserveIndent: false,
                        }}
                        disabled={disabled}
                      />
                    </TableCell>
                    <TableCell className="text-right">{convertNumberToMoney(price)}</TableCell>
                  </>
                )
              }}
            />

            <TableCell>
              <Controller
                control={control}
                name={`cardTemplateServices.${index}.serviceQuantity`}
                rules={{ validate: value => Number(value) > 0 }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <NumberInput
                    value={value}
                    onChange={onChange}
                    errorMessage={{ isShown: Boolean(error), text: ' ', reserveIndent: false }}
                    disabled={disabled}
                    allowDecimals={false}
                  />
                )}
              />
            </TableCell>

            <TotalSumCell
              service={watchedServices[index]}
              serviceCostPrice={
                services.find(services => services.id === watchedServices[index]?.serviceItem?.id)
                  ?.costPrice
              }
              index={index}
              control={control}
              disabled={disabled}
              branchId={branchId}
            />
            <DeleteButtonCell
              disabled={disabled || fields.length === MINIMUM_ROW_IN_TABLE}
              onClick={() => remove(index)}
            />
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  )
}

export const SubscriptionServicesPlaceholder: FC = () => {
  const { t } = useTranslation()

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-95">{t('service.name')}</TableHeaderCell>
          <TableHeaderCell className="w-28 text-right">{t('oneTimePrice')}</TableHeaderCell>
          <TableHeaderCell className="w-32">{t('qty')}</TableHeaderCell>
          <TableHeaderCell className="w-32 tex-right">{t('subscription.priceIn')}</TableHeaderCell>
          <TableHeaderCell className="w-8"></TableHeaderCell>
          <TableHeaderCell className="w-32 text-right">{t('amount')}</TableHeaderCell>
          <TableHeaderCell />
        </tr>
      </TableHeader>

      <TableBody>
        <TableRow>
          <TableCell className="p-1 w-95">
            <PlaceholderInput />
          </TableCell>
          <TableCell className={tableItemStyle + ' pr-4 w-28 text-sm'}>
            <PlaceholderString isRight={true} width="small" />
          </TableCell>

          <TableCell className={tableItemStyle + ' w-32'}>
            <PlaceholderInput width="small" />
          </TableCell>

          <TableCell className={tableItemStyle + ' w-32'}>
            <PlaceholderInput width="small" isRight={true} />
          </TableCell>
          <TableCell className="w-8">
            <ArrowButton
              arrowDirection="right"
              active={false}
              onClick={() => {
                return
              }}
            />
          </TableCell>
          <TableCell className="pl-1 w-32">
            <PlaceholderInput width="small" isRight={true} />
          </TableCell>
        </TableRow>
      </TableBody>
    </TableContainer>
  )
}

const MINIMUM_ROW_IN_TABLE = 1
