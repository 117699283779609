import { toZonedTime } from '@expane/date'
import { ServerWaitingListType } from '../../generated/graphql-types'

export interface WaitingListDate {
  start: Date
  end: Date
}

export interface WaitingListType extends ServerWaitingListType {
  dates: WaitingListDate[]
}

type WaitingListToParse = {
  createdAt?: string | Date | null
  dates: WaitingListDate[]
}
export const parseDatesInWaitingListGqlResponse = <T extends WaitingListToParse>(
  waitingList: T,
  timezone: string,
) => {
  if (waitingList.createdAt) {
    waitingList.createdAt = toZonedTime(waitingList.createdAt, timezone)
  }

  return {
    ...waitingList,
    dates: waitingList.dates.map(({ start, end }) => ({
      start: toZonedTime(start, timezone),
      end: toZonedTime(end, timezone),
    })),
  }
}
