import {
  addHours,
  differenceInDays,
  formatTimeFromDate,
  isBefore,
  isEqual,
  isToday,
  isTomorrow,
  max,
  min,
} from '@expane/date'
import { ClientPhones, ServerWaitingListType } from '@expane/data'
import { addPhonesToClient, ClientWithPhones } from '../client'
import { TFunction } from 'react-i18next'

// Function to format the date
const formatDate = (date: Date, language: string) => {
  return date.toLocaleDateString(language, {
    day: 'numeric',
    month: 'long',
  })
}

export const getCustomWaitingListFormatData = ({
  dateStart,
  dateEnd,
  timezone,
  t,
  language,
}: {
  dateStart: Date
  dateEnd: Date
  timezone: string | undefined
  t: TFunction
  language: string
}) => {
  if (differenceInDays(dateStart, dateEnd) === 0) {
    if (isToday(dateStart, timezone)) return t('today')
    if (isTomorrow(dateStart)) return t('tomorrow')

    return formatDate(dateStart, language)
  }

  return `${formatDate(dateStart, language)} - ${formatDate(dateEnd, language)}`
}

export const getCustomWaitingListFormatDataList = ({
  date,
  timezone,
  t,
  language,
}: {
  date: { start: Date; end: Date }
  timezone: string | undefined
  t: TFunction
  language: string
}) => {
  const { start, end } = date

  const customWaitingListFormatData = getCustomWaitingListFormatData({
    dateStart: date.start,
    dateEnd: date.end,
    timezone,
    t,
    language,
  })

  const startTime = formatTimeFromDate(start)
  const endTime = formatTimeFromDate(end)

  return `${customWaitingListFormatData} ${startTime}-${endTime};`
}

export type WaitingListWithClientPhones = ServerWaitingListType & {
  client: ClientWithPhones<ServerWaitingListType['client']>
}

export const addPhonesToClientsInWaitingList = (
  waitingLists: ServerWaitingListType[] | undefined,
  clientPhones: ClientPhones[] | undefined,
): WaitingListWithClientPhones[] | undefined => {
  return waitingLists?.map(waitingList => {
    const clientPhone = clientPhones?.find(clientPhone => clientPhone.id === waitingList.client.id)
    return {
      ...waitingList,
      client: addPhonesToClient(waitingList.client, clientPhone),
    }
  })
}

export interface WaitingListPeriodType {
  start: Date
  end: Date
}

export const sortWaitingListDates = (dates: WaitingListPeriodType[]) =>
  dates.sort((a, b) => a.start.getDate() - b.start.getDate())

export const mergeWaitingListDatesPeriods = (
  periods: WaitingListPeriodType[],
): { start: Date; end: Date }[] => {
  const sortedPeriods = sortWaitingListDates(periods)

  const merged: WaitingListPeriodType[] = []
  let current = sortedPeriods[0]

  for (let i = 1; i < sortedPeriods.length; i++) {
    const next = sortedPeriods[i]

    // Проверяем, пересекаются ли текущий и следующий периоды
    if (isBefore(next.start, current.end) || isEqual(next.start, current.end)) {
      // Объединяем периоды
      current = {
        start: min([current.start, next.start]),
        end: max([current.end, next.end]),
      }
    } else {
      merged.push(current)
      current = next
    }
  }
  merged.push(current)

  return merged
}

export const splitWaitingListDatesPeriods = (
  mergedPeriods: WaitingListPeriodType[] | undefined,
): WaitingListPeriodType[] => {
  const splitPeriods: WaitingListPeriodType[] = []

  if (!mergedPeriods) return splitPeriods

  for (const period of mergedPeriods) {
    let currentStart = period.start

    while (isBefore(currentStart, period.end)) {
      // колонки у нас по 1 часу поэтому 1
      const currentEnd = addHours(currentStart, 1)

      splitPeriods.push({
        start: currentStart,
        end: isBefore(currentEnd, period.end) ? currentEnd : period.end,
      })

      currentStart = currentEnd
    }
  }

  return splitPeriods
}
