import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { TableCell, TableHeader, TableHeaderCell, TableRow } from '@expane/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const TransactionSubscriptionTableHeader = () => {
  const { t } = useTranslation()

  return (
    <TableHeader>
      <tr>
        <TableHeaderCell className="w-112">{t('position')}</TableHeaderCell>
        <TableHeaderCell className="w-32">{t('discount')}</TableHeaderCell>
        <TableHeaderCell className="w-32">{t('price')}</TableHeaderCell>
      </tr>
    </TableHeader>
  )
}

export const TransactionSubscriptionTableListItem: FC<
  PropsWithBranchId<{
    name: string
    discount: number | null | undefined
    price: number
  }>
> = ({ name, discount, price, branchId }) => {
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const currentDiscount = discount ? convertNumberToMoney(discount) : 0

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{currentDiscount !== 0 ? currentDiscount : '-'}</TableCell>

      <TableCell>{convertNumberToMoney(price)}</TableCell>
    </TableRow>
  )
}
