import { useFetchBranchFinancialSettings } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { useHasBranchNonZeroCents } from '../branch'

const convertNumberToMoneyWithoutSymbol = (value: number) =>
  new Intl.NumberFormat('uk-UA', { maximumFractionDigits: 0 }).format(value)

export const convertNumberToFractionalMoneyWithoutSymbol = (value: number) =>
  new Intl.NumberFormat('uk-UA', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
    value,
  )

// учитывает отображение копеек
export const useConvertNumberToMoneyWithoutSymbol = (branchId: number | undefined) => {
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  const hasBranchNonZeroCents = useHasBranchNonZeroCents(branchId)

  const convert = (value: number) =>
    businessSettings?.displayCoins || !hasBranchNonZeroCents
      ? convertNumberToFractionalMoneyWithoutSymbol(value)
      : convertNumberToMoneyWithoutSymbol(value)

  return convert
}

// USD 1000,00 (с кодом валюты USD, грн) учитывает отображение копеек
export const useConvertNumberToMoneyCode = ({
  branchId,
  strictNoFraction = false,
}: {
  strictNoFraction?: boolean
  branchId: number | undefined
}) => {
  const { i18n } = useTranslation()
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  const hasBranchNonZeroCents = useHasBranchNonZeroCents(branchId)

  const convert = (value: number) => {
    if (!businessSettings) return value.toString()
    let maximumFractionDigits = 0
    let minimumFractionDigits = 0

    if ((businessSettings?.displayCoins && !strictNoFraction) || !hasBranchNonZeroCents) {
      maximumFractionDigits = 2
      minimumFractionDigits = 2
    }

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits,
      minimumFractionDigits,
      style: 'currency',
      currency: businessSettings?.currency,
    }).format(value)

    return formattedValue
  }

  return convert
}

// более универсальный хук, который не зависит от данных businessSettings
// используется на этапах создания бизнеса и мы вручную указываем отображать копейки или нет
// при этом у нас нет данных для проверки (товары, финансы и тд)
export const useConvertNumberToMoneyCodeManually = () => {
  const { i18n } = useTranslation()

  const convert = (value: number, options: { currency: string; displayCoins?: boolean }) => {
    let maximumFractionDigits = 0
    let minimumFractionDigits = 0
    if (options.displayCoins) {
      maximumFractionDigits = 2
      minimumFractionDigits = 2
    }

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits,
      minimumFractionDigits,
      style: 'currency',
      currency: options.currency,
    }).format(value)

    return formattedValue
  }

  return convert
}

// $ 1000,00 (с заменой на символ $ ₴), учитывая отображение копеек
export const useConvertNumberToMoneySymbol = (branchId: number | undefined) => {
  const { i18n } = useTranslation()
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  const hasBranchNonZeroCents = useHasBranchNonZeroCents(branchId)

  const convert = (value: number) => {
    if (!businessSettings) return value.toString()

    let maximumFractionDigits = 0
    let minimumFractionDigits = 0
    if (businessSettings?.displayCoins || !hasBranchNonZeroCents) {
      maximumFractionDigits = 2
      minimumFractionDigits = 2
    }

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits,
      minimumFractionDigits,
      style: 'currency',
      currencyDisplay: 'code',
      currency: businessSettings.currency,
    }).format(value)

    return formattedValue.replace(
      businessSettings.currency,
      currencySymbolMap[businessSettings.currency],
    )
  }

  return convert
}

export const useGetCurrencySymbol = (branchId: number | undefined) => {
  const { data: businessSettings } = useFetchBranchFinancialSettings(branchId)

  if (!businessSettings) return ''

  return currencySymbolMap[businessSettings.currency]
}

export const currencySymbolMap = {
  UAH: '₴',
  USD: '$',
  EUR: '€',
}

// Code in ISO4217
export const currencyItems: Array<{ id: number; name: string }> = [
  {
    id: 1,
    name: 'USD',
  },
  {
    id: 2,
    name: 'UAH',
  },
  {
    id: 3,
    name: 'EUR',
  },
]

export const getCurrencyIdByCode = (code: string): number => {
  const currencyItem = currencyItems.find(item => item.name === code)

  if (currencyItem === undefined) return currencyItems[0].id

  return currencyItem.id
}

export const getCurrencyCodeById = (id: number): string => {
  const currencyItem = currencyItems.find(item => item.id === id)

  if (currencyItem === undefined) return currencyItems[0].name

  return currencyItem.name
}

// используется только в BUSINESS / CLIENT отображение настроек копеек бизнеса не влияет
// всегда показываем копейки в этих проектах
export const useConvertNumberToMoneySymbolByCurrency = () => {
  const { i18n } = useTranslation()

  const convert = (value: number, currency: string | undefined) => {
    if (!currency) return value.toString()

    const formattedValue = new Intl.NumberFormat(i18n.language, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: 'currency',
      currencyDisplay: 'code',
      currency,
    }).format(value)

    return formattedValue.replace(currency, currencySymbolMap[currency])
  }

  return convert
}
