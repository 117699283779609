import React, { FC } from 'react'
import { WaitingListDate } from '@expane/data'
import { InputLabel } from '@expane/ui'
import {
  getCustomWaitingListFormatDataList,
  mergeWaitingListDatesPeriods,
} from '@expane/logic/waitingList'
import { useTranslation } from 'react-i18next'

export const WaitingListConvenientTimes: FC<{
  dates: WaitingListDate[]
  timezone: string | undefined
  withLabel?: boolean
  className?: string
}> = ({ timezone, dates, withLabel = true, className }) => {
  const { t, i18n } = useTranslation()

  if (dates.length === 0) return null

  return (
    <div className={`flex gap-2 flex-wrap ${className || ''}`}>
      {withLabel && <InputLabel label={`${t('convenientTime')}:`} />}

      {mergeWaitingListDatesPeriods(dates).map((date, index) => {
        const dateTitle = getCustomWaitingListFormatDataList({
          date,
          timezone,
          t,
          language: i18n.language,
        })

        return (
          <div key={index}>
            <InputLabel label={dateTitle} />
          </div>
        )
      })}
    </div>
  )
}
