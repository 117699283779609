import { ServerAccountType, ServerTransactionType, useFetchAccounts } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import { calcBalanceOnAccount } from '@expane/logic/finances/calculation'
import { getTransactionsWithClientsDeposits } from '@expane/logic/finances/filters'
import { useAccountsWithCheckbox } from '@expane/logic/payment/checkbox'
import { calcValues } from '@expane/logic/utils'
import {
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { ReportProps } from 'pages/ReportsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

export const RevenueByAccountReport: FC<PropsWithBranchId<ReportProps>> = ({
  transactions,
  isLoading,
  branchId,
}) => {
  const { data: accounts, isLoading: isLoadingAccounts } = useFetchAccounts(branchId)

  const { accountsWithPOSLabels } = useAccountsWithCheckbox(accounts ?? [])

  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  // в этом отчете отображается 'распределение клиентских денег' то есть на какие счета поступали только КЛИЕНТСКИЕ депозиты
  // здесь не учитываются пополнение счетов через "другой доход бизнеса"
  const transactionsWithClientsDeposits = getTransactionsWithClientsDeposits(transactions)

  const clientsDepositsTotalValue = calcValues(transactionsWithClientsDeposits ?? [])

  return (
    <>
      <TableContainer>
        <TableHeader>
          <tr>
            <TableHeaderCell className="w-64">{t('account.name')}</TableHeaderCell>
            <TableHeaderCell className="text-right w-64">{t('amount')}</TableHeaderCell>
          </tr>
        </TableHeader>

        <TableBody>
          {isLoadingAccounts || isLoading ? (
            <>
              <ListItem />
              <ListItem />
              <ListItem />
            </>
          ) : (
            <>
              {accountsWithPOSLabels?.map(acc => (
                <ListItem
                  transactions={transactionsWithClientsDeposits}
                  account={acc}
                  key={acc.id}
                  branchId={branchId}
                />
              ))}
            </>
          )}
        </TableBody>
        <TableFooter>
          <TableCell>{t('total')}</TableCell>

          <TableCell>
            <div className="text-right">{convertNumberToMoney(clientsDepositsTotalValue)}</div>
          </TableCell>
        </TableFooter>
      </TableContainer>
    </>
  )
}

const ListItem: FC<{
  transactions?: ServerTransactionType[]
  account?: ServerAccountType
  branchId?: number | undefined
}> = ({ transactions, account, branchId }) => {
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)
  const value = account ? calcBalanceOnAccount(transactions, account.id) : 0

  if (value === 0 && account) return null

  return (
    <TableRow>
      <TableCell>{account ? account.name : <PlaceholderString />}</TableCell>
      <TableCell>
        <div className="text-right">
          {account ? convertNumberToMoney(value) : <PlaceholderString className="ml-auto" />}
        </div>
      </TableCell>
    </TableRow>
  )
}
