import { ServerAccountType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { CLIENT_ACCOUNT_ID, PAYMENT_OPTIONS } from '@expane/logic/payment'
import { translateItemsName } from '@expane/logic/utils'
import { Checkbox, Input, InputLabel, Paper, RadioGroupButtons } from '@expane/ui'
import { getDifference } from 'logic/payment'
import { convertNumberToFractionalMoneyString } from 'logic/utils'
import { FC, useEffect, useMemo } from 'react'
import { Control, Controller, UseFormClearErrors, UseFormSetValue, useWatch } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { AccountSelectDropdown, AccountSelectDropdownType } from 'widgets/AccountSelectDropdown'
import { getTotalSumOfInvoice, SubmitQuickSaleDialogFormValues } from '../logic'
import { getIsAccountWithPOS } from '@expane/logic/accounts'
import { getAreNaughtItemsForSale } from '@expane/logic/quickSale'

interface Props {
  control: Control<SubmitQuickSaleDialogFormValues>
  setValue: UseFormSetValue<SubmitQuickSaleDialogFormValues>
  clearErrors: UseFormClearErrors<SubmitQuickSaleDialogFormValues>
  accounts: ServerAccountType[]
  clientBalance: number
  isErrors: boolean
}

export const PaymentInputs: FC<PropsWithBranchId<Props>> = ({
  control,
  setValue,
  accounts,
  clientBalance,
  clearErrors,
  isErrors,
  branchId,
}) => {
  const { t } = useTranslation()
  const convertToMoney = useConvertNumberToMoneyCode({ branchId })

  const watchedProducts = useWatch({ control, name: 'productItems' })
  const watchedServices = useWatch({ control, name: 'serviceItems' })
  const watchedSubscriptions = useWatch({ control, name: 'subscriptionItems' })
  const watchedGiftCards = useWatch({ control, name: 'giftCardItems' })

  const totalInvoiceSum = getTotalSumOfInvoice({
    products: watchedProducts,
    services: watchedServices,
    subscriptions: watchedSubscriptions,
    giftCards: watchedGiftCards,
  })

  const watchedPaymentAmount = useWatch({ control, name: 'paymentAmount' })
  const watchedAccount = useWatch({ control, name: 'accountId' })
  const watchedPaymentOptions = useWatch({ control, name: 'paymentOption' })
  const watchedPaymentToAccounts = useWatch({ control, name: 'paymentToAccounts' })

  const selectedAccount = accounts.find(account => account.id === watchedAccount)

  const restMoney = getDifference(totalInvoiceSum, watchedPaymentAmount)

  const totalSumToPay = watchedPaymentToAccounts.reduce((sum, item) => sum + Number(item.value), 0)

  const isShowRestMoney =
    Boolean(restMoney) && watchedAccount !== CLIENT_ACCOUNT_ID && Boolean(selectedAccount?.cash)

  const memoizedPaymentOptions = useMemo(() => translateItemsName(PAYMENT_OPTIONS, t), [t])

  const isOneAccount = watchedPaymentOptions.id === memoizedPaymentOptions[0].id
  const isMultiAccounts = watchedPaymentOptions.id === memoizedPaymentOptions[1].id

  const isTotalInvoiceSumNaught = totalInvoiceSum === 0
  const areNaughtItemsForSale = getAreNaughtItemsForSale({
    watchedProducts,
    watchedServices,
    watchedSubscriptions,
    watchedGiftCards,
  })

  // Предзаполнение общей суммы в оплате
  useEffect(() => {
    setValue('paymentAmount', convertNumberToFractionalMoneyString(totalInvoiceSum))
    clearErrors('paymentAmount')

    if (isTotalInvoiceSumNaught || areNaughtItemsForSale)
      setValue('paymentOption', memoizedPaymentOptions[0])
  }, [
    clearErrors,
    setValue,
    totalInvoiceSum,
    areNaughtItemsForSale,
    isTotalInvoiceSumNaught,
    memoizedPaymentOptions,
  ])

  return (
    <Paper className={'h-34'}>
      <div className="p-3 flex max-h-full relative">
        <div className="w-1/3 pr-3 sticky top-0">
          {isTotalInvoiceSumNaught || areNaughtItemsForSale ? null : (
            <Controller
              name="paymentOption"
              defaultValue={memoizedPaymentOptions[0]}
              control={control}
              render={({ field: { value, onChange } }) => (
                <RadioGroupButtons
                  label={t('paymentMethod')}
                  options={memoizedPaymentOptions}
                  value={value}
                  onChange={e => {
                    clearErrors()
                    onChange(e)
                  }}
                />
              )}
            />
          )}
        </div>
        <div className="grow">
          {/* контролы должны быть в ДОМ чтобы проходила валидация инпутов */}
          <div className={isOneAccount ? '' : 'hidden'}>
            <div className="flex justify-between">
              <Controller
                name="accountId"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <AccountSelectDropdown
                    label={t('payment.name')}
                    value={value}
                    accounts={accounts}
                    onSelectChange={value => {
                      onChange(value)
                      clearErrors('paymentAmount')

                      if (!accounts?.find(account => account.id === value)?.cash) {
                        // при оплате по безналу ложим всю сумму на счет
                        setValue('addToClientAccount', true)
                      } else {
                        // при оплате наличными опционально
                        setValue('addToClientAccount', false)
                      }
                    }}
                    className="w-2/3"
                    popupHeight="small"
                    height="small"
                  />
                )}
              />

              <Controller
                name="paymentAmount"
                control={control}
                rules={{
                  validate: {
                    CheckAmountPayment: value => {
                      if (isOneAccount && totalInvoiceSum > 0) {
                        return Number(value) >= totalInvoiceSum
                      }
                    },
                    CheckClientAccountBalance: value => {
                      if (isOneAccount && watchedAccount === CLIENT_ACCOUNT_ID) {
                        return clientBalance >= Number(value)
                      }
                    },
                    CheckIsAccountWithPOS: value => {
                      if (Number(value) === 0) {
                        const paymentToAccount = accounts.find(
                          account => watchedAccount === account.id,
                        )

                        return getIsAccountWithPOS(paymentToAccount)
                      }
                    },
                    CheckIsItemWithNaughtPrice: () => {
                      if (
                        getAreNaughtItemsForSale({
                          watchedProducts,
                          watchedServices,
                          watchedSubscriptions,
                          watchedGiftCards,
                        })
                      ) {
                        const paymentToAccount = accounts.find(
                          account => watchedAccount === account.id,
                        )

                        return getIsAccountWithPOS(paymentToAccount)
                      }
                    },
                  },
                }}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Input
                    label={t('amount')}
                    type="number"
                    value={value}
                    onChange={onChange}
                    placeholder="100"
                    containerClassName="pl-3 grow"
                    errorMessage={{
                      isShown: Boolean(error),
                      text: defineIsFormOrValueErrorMessage(
                        {
                          errorType: error?.type ?? '',
                          totalInvoiceSum,
                          clientBalance,
                        },
                        t,
                        convertToMoney,
                      ),
                    }}
                    height="small"
                  />
                )}
              />
            </div>

            {isShowRestMoney && (
              <>
                <div className="mt-3 mb-1 text-sm flex items-center text-gray-500">
                  {t('change(money)')}: {convertToMoney(restMoney ?? 0)}
                </div>
                <Controller
                  name="addToClientAccount"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      label={t('depositToClientsAccount')}
                      checked={value}
                      onChange={onChange}
                      disabled={restMoney === null}
                    />
                  )}
                />
              </>
            )}
          </div>
          <div className={isMultiAccounts ? 'pb-3' : 'hidden'}>
            <div className="flex">
              <InputLabel className="w-1/2" label={t('payment.name')} />
              <InputLabel className="pl-3 grow" label={t('amount')} />
            </div>

            <div className="h-16 overflow-auto">
              {accounts?.map((account, index) => {
                return (
                  <div key={account.id} className="flex justify-between mb-1">
                    <Controller
                      name={`paymentToAccounts.${index}.id` as 'paymentToAccounts.0.id'}
                      control={control}
                      defaultValue={account.id}
                      render={({ field: { value, onChange } }) => (
                        <AccountSelectDropdown
                          type={AccountSelectDropdownType.labels}
                          value={value}
                          accounts={accounts}
                          onSelectChange={onChange}
                          className="w-2/3"
                          disabled
                          popupHeight="small"
                          height="small"
                        />
                      )}
                    />

                    <Controller
                      name={`paymentToAccounts.${index}.value` as 'paymentToAccounts.0.value'}
                      control={control}
                      defaultValue={''}
                      rules={{
                        validate: {
                          CheckClientAccountBalance: value => {
                            if (isMultiAccounts && account.id === CLIENT_ACCOUNT_ID) {
                              return clientBalance >= Number(value)
                            }
                          },
                          CheckAmountPayment: value => {
                            if (isMultiAccounts) {
                              if (watchedPaymentToAccounts.length === 0) return false
                              return checkAmountOfPayments(
                                watchedPaymentToAccounts,
                                { id: account.id, value },
                                totalInvoiceSum,
                              )
                            }
                          },
                        },
                      }}
                      render={({ field: { value, onChange }, formState }) => (
                        <Input
                          type="number"
                          value={value}
                          onChange={event => {
                            const newValue = event.target.value
                            const isEnoughForPay = checkAmountOfPayments(
                              watchedPaymentToAccounts,
                              { id: account.id, value: newValue },
                              totalInvoiceSum,
                            )
                            if (isEnoughForPay) clearErrors('paymentToAccounts')

                            onChange(newValue)
                          }}
                          placeholder="100"
                          containerClassName="pl-3 grow"
                          errorMessage={{
                            isShown: Boolean(formState.errors.paymentToAccounts?.[index]?.value),
                            reserveIndent: false,
                          }}
                          height="small"
                        />
                      )}
                    />
                  </div>
                )
              })}
            </div>

            {isErrors ? (
              <span className="mt-3 text-sm text-error-500">
                {t('toPay')}: {convertToMoney(totalInvoiceSum)}
              </span>
            ) : (
              <span className="mt-3 text-sm text-gray-500">
                {t('toPay')}: {convertToMoney(totalSumToPay)}
              </span>
            )}
          </div>
        </div>
      </div>
    </Paper>
  )
}

const defineIsFormOrValueErrorMessage = (
  dto: {
    errorType: string
    totalInvoiceSum: number
    clientBalance: number
    hideTotalErrorMessage?: boolean
  },
  t: TFunction,
  convertToMoney: (value: number) => string,
) => {
  const { errorType, hideTotalErrorMessage, totalInvoiceSum, clientBalance } = dto

  if (errorType === 'CheckAmountPayment' && hideTotalErrorMessage) return ' '
  if (errorType === 'CheckAmountPayment')
    return `${t('totalPayable')}: ${convertToMoney(totalInvoiceSum)}`
  if (errorType === 'CheckClientAccountBalance')
    return `${t('availableOnAccount')}: ${convertToMoney(clientBalance)}`
  if (errorType === 'CheckIsAccountWithPOS') return `${t('formError.accountWithPOS')}`
  if (errorType === 'CheckIsItemWithNaughtPrice') return `${t('formError.naughtPriceItemWithPOS')}`
}

const checkAmountOfPayments = (
  paymentToAccounts: { id: number; value: string }[],
  currentAccount: { id: number; value: string },
  totalInvoiceSum: number,
) => {
  const totalSumToPay = paymentToAccounts
    .filter(account => account.id !== currentAccount.id)
    .reduce((sum, item) => sum + Number(item.value), Number(currentAccount.value))

  return totalSumToPay >= totalInvoiceSum
}
