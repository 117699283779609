import { ServerSupplierMutualSettlementType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import {
  Document,
  Period,
  transformMovementsToMutualSettlementsList,
} from '@expane/logic/suppliers'
import { EmptyPlaceholder, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { OpenMovementOrTransactionDialog } from 'pages/SuppliersPage/MutualSettlements'
import { SupplierMutualSettlementsInfoBlock } from 'pages/SuppliersPage/SupplierMutualSettlementsInfoBlock'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoRepeatSharp } from 'react-icons/io5'

interface SupplierMutualSettlementsListProps {
  supplier?: ServerSupplierMutualSettlementType
  onClick: OpenMovementOrTransactionDialog
  period?: Period
}

export const SupplierMutualSettlementsList: FC<
  PropsWithBranchId<SupplierMutualSettlementsListProps>
> = ({ supplier, onClick, period, branchId }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()
  const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

  const columns = useMemo<ColumnDef<Document>[]>(
    () => [
      {
        accessorKey: 'type',
        header: t('documentType'),
        cell: data => {
          const type = data.getValue<string>()
          return type === 'movement'
            ? t('invoice')
            : t('transaction.name') + ` ${data.row.original.name}`
        },
        size: 300,
      },
      {
        accessorKey: 'number',
        header: t('number'),
        cell: data => data.getValue(),
        size: 50,
      },
      {
        accessorKey: 'date',
        header: t('timeTitle'),
        cell: data => dateFormatting('shortDateTime', data.getValue<Date>()),
      },
      {
        accessorKey: 'arrival',
        header: () => <span className="text-right w-full">{t('coming')}</span>,
        cell: data => {
          const arrival = data.getValue<number>()
          return <div className="text-right">{arrival ? convertNumberToMoney(arrival) : '-'}</div>
        },
        size: 100,
      },
      {
        accessorKey: 'expense',
        header: () => <span className="text-right w-full">{t('expense')}</span>,
        cell: data => {
          const expense = data.getValue<number>()
          return <div className="text-right">{expense ? convertNumberToMoney(expense) : '-'}</div>
        },
        size: 100,
      },
      {
        accessorKey: 'comment',
        header: t('note'),
        cell: data => data.getValue(),
        size: 350,
      },
    ],
    [convertNumberToMoney, dateFormatting, t],
  )
  if (!supplier) return null

  const docs = transformMovementsToMutualSettlementsList(supplier.movements, t, period)

  if (docs.length === 0)
    return <EmptyPlaceholder Icon={IoRepeatSharp} text={t('noMovementsInPeriod')} />

  return (
    <>
      <SupplierMutualSettlementsInfoBlock
        supplier={supplier}
        period={period}
        className="mb-3"
        branchId={branchId}
      />

      <Table columns={columns} data={docs} onRowClick={doc => onClick(doc.id, doc.type)} />
    </>
  )
}
