import { firebase } from '../firebase'
import { getWebPreferredUserLanguage } from '../language'

export enum LoginWithPopupType {
  google,
  apple,
}

export enum LoginWithPopupResponse {
  success,
  failed,
  closedByUser,
  canceledByUser,
}

export type SafetyLoginWithPopupFunc = (
  loginType: LoginWithPopupType,
  languageLocalStorageKey: string,
) => Promise<LoginWithPopupResponse>

export const safetyLoginWithPopup: SafetyLoginWithPopupFunc = async (
  loginType,
  languageLocalStorageKey,
) => {
  const auth = firebase.getAuth()

  const locale = getWebPreferredUserLanguage(languageLocalStorageKey)

  try {
    if (loginType === LoginWithPopupType.google) {
      const googleProvider = firebase.getGoogleAuthProvider()
      auth.languageCode = locale
      await firebase.signInWithPopup(googleProvider)
    } else {
      const appleProvider = firebase.getAppleAuthProvider()
      appleProvider.setCustomParameters({ locale })

      await firebase.signInWithPopup(appleProvider)
    }

    return LoginWithPopupResponse.success
  } catch (e) {
    if (e instanceof Error) {
      if (e.message.includes('auth/popup-closed-by-user'))
        return LoginWithPopupResponse.closedByUser
      else if (e.message.includes('auth/user-cancelled'))
        return LoginWithPopupResponse.canceledByUser
    }
    return LoginWithPopupResponse.failed
  }
}
