import { ServerSupplierType, useCreateSupplier, useUpdateSupplier } from '@expane/data'
import { PLACEHOLDERS, validateEmail } from '@expane/logic/form'
import { checkValidPhone } from '@expane/logic/phone'
import { Input, NumberInput, Paper, Textarea } from '@expane/ui'
import { showPhoneErrorMessage } from 'logic/form'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { PhoneInput } from 'ui/PhoneInput'
import { useSnackbar } from '@expane/widgets'
import {
  SupplierTabAdditionalSavingProps,
  useAttachFunctionAndFormState,
} from 'widgets/SupplierDialog'

interface SupplierInfoTabProps extends SupplierTabAdditionalSavingProps<SupplierInfoTabFormValues> {
  supplierById: ServerSupplierType | undefined
  isCreate: boolean
  disabled: boolean
  onCreate?: (id: number) => void
}

export const SupplierInfoTab: FC<SupplierInfoTabProps> = observer(
  ({ supplierById, isCreate, additionalProps, disabled, onCreate }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()

    const { formState, control, handleSubmit } = useForm<SupplierInfoTabFormValues>({
      defaultValues: {
        name: supplierById?.name ?? '',
        phone: supplierById?.phone ?? '',
        webSite: supplierById?.webSite ?? '',
        email: supplierById?.email ?? '',
        country: supplierById?.address?.country ?? t('Ukraine'),
        city: supplierById?.address?.city ?? '',
        postcode: supplierById?.address?.postcode ?? '',
        street: supplierById?.address?.street ?? '',
        house: supplierById?.address?.house ?? '',
        office: supplierById?.address?.office ?? '',
        note: supplierById?.note ?? '',
      },
    })
    const [openSnackbar] = useSnackbar()

    const { mutateAsync: createSupplier } = useCreateSupplier()
    const { mutateAsync: updateSupplier } = useUpdateSupplier()

    const mutateSupplier: SubmitHandler<SupplierInfoTabFormValues> = async ({
      name,
      email,
      note,
      phone,
      webSite,
      city,
      country,
      house,
      office,
      postcode,
      street,
    }) => {
      if (isCreate) {
        if (!branchId) {
          openSnackbar(t('submitError'), 'error')

          additionalProps.closeDialog()
          return
        }

        const result = await createSupplier({
          name,
          email,
          note,
          phone,
          webSite,
          address: {
            data: { city, country, house, office, postcode, street },
          },
          branchId,
        })

        if (result?.insertSupplier?.id) {
          openSnackbar(t('supplier.createdSuccessfully'), 'success')
          onCreate?.(result.insertSupplier?.id)
        } else openSnackbar(t('submitError'), 'error')
      } else if (supplierById) {
        const result = await updateSupplier({
          supplierId: supplierById.id,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          addressId: supplierById.addressId!,
          supplierSetInput: { email, name, note, phone, webSite },
          addressSetInput: { city, country, house, office, postcode, street },
        })

        if (result?.updateSuppliersById?.id) {
          openSnackbar(t('supplier.updatedSuccessfully'), 'success')
        } else openSnackbar(t('submitError'), 'error')
      }

      additionalProps.closeDialog()
    }

    useAttachFunctionAndFormState<SupplierInfoTabFormValues>({
      calledFunction: handleSubmit(mutateSupplier),
      formState,
      additionalProps,
    })

    return (
      <>
        <div className="flex">
          <div className="w-1/2 mr-3">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('title')}
                  placeholder={t('title')}
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.name),
                    text: t('formError.required'),
                  }}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  autoFocus
                />
              )}
            />

            <Controller
              name="phone"
              control={control}
              rules={{ required: true, validate: checkValidPhone }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  label={t('phone')}
                  placeholder={PLACEHOLDERS.phone}
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.phone),
                    text: showPhoneErrorMessage(formState.errors.phone?.type ?? '', t),
                  }}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            />
          </div>

          <div className="grow">
            <Controller
              name="webSite"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('website')}
                  placeholder={'www.test.com'}
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                validate: email => (email ? validateEmail(email) : true),
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  label={t('email')}
                  placeholder={t('placeholders.email')}
                  value={value}
                  onChange={onChange}
                  containerClassName="mt-4"
                  disabled={disabled}
                  errorMessage={{ isShown: Boolean(error), text: t('authForm.wrongEmail') }}
                />
              )}
            />
          </div>
        </div>

        <Paper className="p-2">
          <div className="flex">
            <Controller
              name="country"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('country')}
                  placeholder={t('Ukraine')}
                  value={value}
                  onChange={onChange}
                  containerClassName="w-1/3 mr-3"
                  disabled={disabled}
                />
              )}
            />

            <Controller
              name="city"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('city')}
                  placeholder={t('Kyiv')}
                  value={value}
                  onChange={onChange}
                  containerClassName="w-1/3"
                  disabled={disabled}
                />
              )}
            />
          </div>

          <div className="flex mt-2">
            <Controller
              name="postcode"
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumberInput
                  label={t('postcode')}
                  placeholder={'01001'}
                  value={value}
                  onChange={onChange}
                  containerClassName="w-24 mr-2"
                  disabled={disabled}
                />
              )}
            />

            <Controller
              name="street"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('street')}
                  placeholder={t('Khreshchatyk')}
                  value={value}
                  onChange={onChange}
                  containerClassName="w-1/3 mr-2"
                  disabled={disabled}
                />
              )}
            />

            <Controller
              name="house"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('house')}
                  placeholder={'1'}
                  value={value}
                  onChange={onChange}
                  containerClassName="w-24 mr-2"
                  disabled={disabled}
                />
              )}
            />
            <Controller
              name="office"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('office')}
                  placeholder={'12'}
                  value={value}
                  onChange={onChange}
                  containerClassName="w-24"
                  disabled={disabled}
                />
              )}
            />
          </div>
        </Paper>

        <Controller
          name="note"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Textarea
              label={t('note')}
              placeholder={''}
              containerClassName="w-1/2 mt-3"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </>
    )
  },
)

export type SupplierInfoTabFormValues = {
  name: string
  phone: string
  email?: string
  webSite?: string
  note?: string
  postcode: string
  country: string
  city: string
  street: string
  house: string
  office: string
}
