import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { NumberInput } from '@expane/ui'
import { FC, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ArrowButton } from 'ui/ArrowButton'
import {
  calcServiceInSubscriptionPrice,
  generateServicePriceErrorMessage,
  SubscriptionFormValues,
  SubscriptionServiceDto,
} from '../logic'

interface TotalSumCellProps {
  service: SubscriptionServiceDto | undefined
  serviceCostPrice?: number
  index: number
  control: Control<SubscriptionFormValues>
  disabled?: boolean
}

export const TotalSumCell: FC<PropsWithBranchId<TotalSumCellProps>> = ({
  service,
  serviceCostPrice,
  index,
  control,
  disabled,
  branchId,
}) => {
  const { t } = useTranslation()

  const [modifiedPrice, setModifiedPrice] = useState<string | null>(null)
  const [modifiedTotalSum, setModifiedTotalSum] = useState<string | null>(null)
  const [arrowDirection, setArrowDirection] = useState<'left' | 'right'>('right')
  const convertToMoneyCode = useConvertNumberToMoneyCode({ strictNoFraction: true, branchId })

  return (
    <Controller
      control={control}
      name={`cardTemplateServices.${index}.servicePrice`}
      rules={{
        required: true,
        validate: {
          checkValueNotZero: value => Number(value) > 0,
          checkPriceMoreCostPrice: value => {
            if (serviceCostPrice) return Number(value) >= serviceCostPrice
          },
        },
      }}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const setNewPrice = () => {
          if (modifiedPrice !== null && modifiedTotalSum === null) {
            onChange(modifiedPrice)
            setModifiedPrice(null)
          }
          if (modifiedPrice === null && modifiedTotalSum !== null) {
            const newServicePrice =
              Number(modifiedTotalSum) / Number(service?.serviceQuantity ?? '0')
            onChange(Math.round(newServicePrice).toString())
            setModifiedTotalSum(null)
          }
        }

        const serviceInSubscriptionPrice = modifiedPrice ?? value

        return (
          <>
            <td className={tableItemStyle + ' w-32'}>
              <NumberInput
                className="text-right"
                value={serviceInSubscriptionPrice}
                onChange={value => {
                  if (arrowDirection === 'left') setArrowDirection('right')
                  if (modifiedTotalSum !== null) setModifiedTotalSum(null)
                  setModifiedPrice(value)
                }}
                errorMessage={{
                  isShown: Boolean(error),
                  text: generateServicePriceErrorMessage({
                    convertToMoneyCode,
                    type: error?.type,
                    costPrice: serviceCostPrice,
                    serviceInSubscriptionPrice,
                    t,
                  }),
                  reserveIndent: false,
                }}
                onKeyDown={event => {
                  if (event.code === 'Enter') setNewPrice()
                }}
                onBlur={setNewPrice}
                disabled={disabled}
              />
            </td>
            <td className="w-8">
              <ArrowButton
                arrowDirection={arrowDirection}
                active={Boolean(modifiedPrice) || Boolean(modifiedTotalSum)}
                onClick={setNewPrice}
              />
            </td>
            <td className="pl-1 w-32">
              <NumberInput
                className="text-right"
                value={
                  modifiedTotalSum ??
                  calcServiceInSubscriptionPrice(
                    service?.serviceQuantity ?? '0',
                    service?.servicePrice ?? '0',
                  )
                }
                onChange={value => {
                  if (arrowDirection === 'right') setArrowDirection('left')
                  if (modifiedPrice !== null) setModifiedPrice(null)
                  setModifiedTotalSum(value)
                }}
                onKeyDown={event => {
                  if (event.code === 'Enter') setNewPrice()
                }}
                onBlur={setNewPrice}
                type="number"
                errorMessage={{
                  isShown: Boolean(error),
                  text: generateServicePriceErrorMessage({
                    convertToMoneyCode,
                    type: error?.type,
                    t,
                  }),
                  reserveIndent: false,
                }}
                disabled={disabled}
              />
            </td>
          </>
        )
      }}
    />
  )
}

export const tableItemStyle = 'px-1 w-1/7'
