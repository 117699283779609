import { TimeOption, WaitingListNotificationType } from '@expane/data'
import { toZonedTime } from '@expane/date'
import { FC } from 'react'
import { transformPersonName } from '@expane/logic/utils'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { observer } from 'mobx-react-lite'
import { NotificationItemHeader } from './HeaderItem'
import { useTranslation } from 'react-i18next'
import { labelStyle, notificationContainerStyle, NotificationItemProps, textStyle } from './logic'
import { IoCalendarOutline } from 'react-icons/io5'
import { useOpenBookingDialog } from 'widgets/BookingDialog'

interface WaitingListNotificationItemProps
  extends Omit<NotificationItemProps, 'item' | 'openBookingDialog'> {
  notification: WaitingListNotificationType
}

const parseWaitingListMessageData = (message: string, timezone: string) => {
  const timeOptions: TimeOption[] = []

  try {
    const parsed = JSON.parse(message)

    if (Array.isArray(parsed)) {
      for (const item of parsed) {
        if (
          typeof item === 'object' &&
          Object.prototype.hasOwnProperty.call(item, 'date') &&
          Object.prototype.hasOwnProperty.call(item, 'locationId')
        ) {
          timeOptions.push({ ...item, date: toZonedTime(item.date, timezone) })
        }
      }
    }

    return timeOptions
  } catch {
    return []
  }
}

export const WaitingListNotificationItem: FC<WaitingListNotificationItemProps> = ({
  notification,
  selectedNotifications,
  setNotifications,
  timezone,
}) => {
  const { t } = useTranslation()

  const timeOptions = parseWaitingListMessageData(notification.message, timezone)

  const serviceName = notification.waitingList?.service.name ?? ''
  const clientName = notification.waitingList?.client
    ? transformPersonName(notification.waitingList.client)
    : ''

  return (
    <li className="flex items-center mb-2 last:mb-0">
      <div className={notificationContainerStyle}>
        <NotificationItemHeader
          selectedNotifications={selectedNotifications}
          setNotifications={setNotifications}
          notification={notification}
        />

        <div className="text-sm text-gray-600 dark:text-gray-300 py-1">
          <p className={textStyle}>
            <span className={labelStyle}>{`${t('client.name')}:`}</span>
            {clientName}
          </p>
          <p className={textStyle}>
            <span className={labelStyle}>{`${t('service.name')}:`}</span>
            {serviceName}
          </p>

          <div className={textStyle}>
            <span className={labelStyle}>{`${t('availableTime')}:`}</span>
          </div>

          <TimeOptionsInfo
            timeOptions={timeOptions}
            clientId={notification.waitingList?.client?.id}
            serviceId={notification.waitingList?.service?.id}
          />
        </div>
      </div>
    </li>
  )
}

const TimeOptionsInfo: FC<{
  timeOptions: TimeOption[]
  clientId: number | undefined
  serviceId: number | undefined
}> = observer(({ timeOptions, clientId, serviceId }) => {
  const format = useDateFormatting()

  const { openCreateBookingDialog, bookingDialog } = useOpenBookingDialog()

  return (
    <ul className="flex flex-wrap gap-1.5">
      {timeOptions.map((timeOption, index) => {
        return (
          <li key={index}>
            <button
              className={
                'flex-centered rounded-md border-2 border-primary-500 bg-primary-500 text-btn-primary font-medium text-xs p-2'
              }
              onClick={() =>
                openCreateBookingDialog({
                  startDate: timeOption.date,
                  locationId: timeOption.locationId,
                  employeeId: timeOption.employeeId,
                  clientId,
                  serviceId,
                })
              }
            >
              <IoCalendarOutline size={'1rem'} className={'mr-1'} />
              <p>{format('historyDateTime', timeOption.date)}</p>
            </button>
          </li>
        )
      })}
      {bookingDialog}
    </ul>
  )
})
