import { gql } from 'graphql-request'
import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import {
  ServerLocationAllBranchesBriefType,
  ServerLocationBriefType,
  ServerLocationByIdType,
  ServerLocationWithServiceIdsType,
} from '../../generated/graphql-types'
import {
  ARCHIVED_LOCATION_QUERY_KEY,
  FETCH_LOCATIONS_KEYS,
  LOCATIONS_BRIEFS_QUERY_KEY,
  LOCATIONS_QUERY_KEY,
  LOCATIONS_WITH_DEFAULT_FOR_BRANCH_QUERY_KEY,
} from './queryKeys'
import {
  locationAllBranchesBriefFragment,
  locationBriefFragment,
  locationByIdFragment,
  locationWithServiceIdsFragment,
} from './location.fragments'
import { ALL_BRANCHES_QUERY_KEYS } from '../branch/queryKeys'

export function useFetchLocationsByServicesBriefs(
  serviceIds: Array<number> | undefined,
  branchId: number | undefined,
) {
  return useQuery<ServerLocationBriefType[] | undefined>(
    [...FETCH_LOCATIONS_KEYS, { serviceIds, branchId }],
    async () => {
      const result = await request(
        gql`
          ${locationBriefFragment}
          query ($serviceIds: [Int!]!, $branchId: Int!) {
            locations(
              where: {
                _not: { defaultForBranch: {} }
                serviceLocations: {
                  _and: [{ serviceId: { _in: $serviceIds } }, { branchId: { _eq: $branchId } }]
                }
                branchId: { _eq: $branchId }
                archived: { _is_null: true }
              }
            ) {
              ...locationBriefType
            }
          }
        `,
        { serviceIds, branchId },
      )

      if (result?.locations) return result.locations

      reportError(new Error('Error while trying to return locations brief by services'), 'error', {
        result,
        serviceIds,
      })
    },
    {
      queryName: 'useFetchLocationsByServicesBriefs',
      enabled: Boolean(serviceIds) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}

export function useFetchLocationsWithDefaultForBranch(branchId: number | undefined) {
  return useQuery<ServerLocationWithServiceIdsType[]>(
    [LOCATIONS_QUERY_KEY, LOCATIONS_WITH_DEFAULT_FOR_BRANCH_QUERY_KEY, { branchId }],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${locationWithServiceIdsFragment}
            query ($branchId: Int!) {
              locations(
                where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }
                order_by: { name: asc }
              ) {
                ...locationWithServiceIdsType
              }
            }
          `,
          { branchId },
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (Array.isArray(result?.locations)) {
        return result.locations
      } else {
        reportError(new Error('locations is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchLocations', onError: reportGqlError, enabled: Boolean(branchId) },
  )
}

export function useFetchLocations(branchId: number | undefined | null) {
  return useQuery<ServerLocationWithServiceIdsType[]>(
    [...FETCH_LOCATIONS_KEYS, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${locationWithServiceIdsFragment}
          query ($branchId: Int!) {
            locations(
              where: {
                _not: { defaultForBranch: {} }
                branchId: { _eq: $branchId }
                archived: { _is_null: true }
              }
              order_by: { groupId: asc }
            ) {
              ...locationWithServiceIdsType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.locations)) {
        return result.locations
      } else {
        reportError(new Error('locations is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchLocations', onError: reportGqlError, enabled: Boolean(branchId) },
  )
}

export function useFetchLocationsBrief(branchId: number | undefined | null) {
  return useQuery<{ id: number; name: string }[]>(
    [...FETCH_LOCATIONS_KEYS, LOCATIONS_BRIEFS_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          query ($branchId: Int!) {
            locations(
              where: {
                _not: { defaultForBranch: {} }
                branchId: { _eq: $branchId }
                archived: { _is_null: true }
              }
            ) {
              id
              name
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.locations)) {
        return result.locations
      } else {
        reportError(new Error('locations is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchLocationsBrief', onError: reportGqlError, enabled: Boolean(branchId) },
  )
}

export function useFetchArchivedLocations(branchId: number | undefined) {
  return useQuery<ServerLocationWithServiceIdsType[]>(
    [...FETCH_LOCATIONS_KEYS, ARCHIVED_LOCATION_QUERY_KEY, { branchId }],
    async () => {
      const result = await request(
        gql`
          ${locationWithServiceIdsFragment}
          query ($branchId: Int!) {
            locations(
              where: {
                _not: { defaultForBranch: {} }
                branchId: { _eq: $branchId }
                archived: { _is_null: false }
              }
              order_by: { groupId: asc }
            ) {
              ...locationWithServiceIdsType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.locations)) {
        return result.locations
      } else {
        reportError(new Error('locations is not an array'), 'warning', { result })
        return []
      }
    },
    { queryName: 'useFetchArchivedLocations', onError: reportGqlError, enabled: Boolean(branchId) },
  )
}

export function useFetchAllBranchesLocations() {
  return useQuery<ServerLocationAllBranchesBriefType[]>(
    [...FETCH_LOCATIONS_KEYS, ALL_BRANCHES_QUERY_KEYS],
    async () => {
      const result = await request(
        gql`
          ${locationAllBranchesBriefFragment}
          query {
            locations(
              where: { _not: { defaultForBranch: {} }, archived: { _is_null: true } }
              order_by: { groupId: asc }
            ) {
              ...locationAllBranchesBriefType
              branchId
            }
          }
        `,
      )

      if (Array.isArray(result?.locations)) {
        return result.locations
      } else {
        reportError(new Error('locations is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchAllBranchesLocations',
      onError: reportGqlError,
    },
  )
}

export function useFetchLocationById(id: number | undefined, options?: { enabled?: boolean }) {
  return useQuery<ServerLocationByIdType | undefined>(
    [LOCATIONS_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${locationByIdFragment}
          query ($id: Int!) {
            locationById(id: $id) {
              ...locationByIdType
            }
          }
        `,
        { id },
      )

      if (result?.locationById) {
        return result.locationById
      } else {
        reportError(new Error('locationById does not exist'), 'warning', {
          id,
          result,
        })
        return undefined
      }
    },
    {
      queryName: 'useFetchLocationById',
      enabled: Boolean(id) && (options?.enabled ?? true),
      onError: reportGqlError,
      placeholderData: () => {
        const cachedLocations = queryClient.getQueryData([LOCATIONS_QUERY_KEY]) as
          | ServerLocationWithServiceIdsType[]
          | undefined
        return cachedLocations?.find(location => location.id === id)
      },
    },
  )
}
