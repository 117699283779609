import { gql } from 'graphql-request'
import { DEFAULT_TIMEZONE } from '@expane/date'
import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import { SERVICES_QUERY_KEY } from '../service/queryKeys'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'
import { CLIENTS_QUERY_KEY } from '../client/queryKeys'
import { waitingListFragment } from './waitingList.fragments'
import { WAITING_LISTS_QUERY_KEY } from './queryKeys'
import { parseDatesInWaitingListGqlResponse, WaitingListType } from './logic'

export function useFetchWaitingLists(timezone: string | undefined, branchId: number | undefined) {
  return useQuery(
    [
      WAITING_LISTS_QUERY_KEY,
      SERVICES_QUERY_KEY,
      EMPLOYEES_QUERY_KEY,
      CLIENTS_QUERY_KEY,
      { branchId },
    ],
    async (): Promise<WaitingListType[]> => {
      const result = await request(
        gql`
          ${waitingListFragment}
          query ($branchId: Int!) {
            waitingLists(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, closedAt: { _is_null: true } }
            ) {
              ...waitingListType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.waitingLists)) {
        return result.waitingLists.map(wl =>
          parseDatesInWaitingListGqlResponse(wl, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('waitingLists is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(timezone) && Boolean(branchId),
      queryName: 'useFetchWaitingLists',
      onError: reportGqlError,
      onSuccess: waitingLists =>
        waitingLists.forEach(waitingList =>
          queryClient.setQueryData(
            [
              WAITING_LISTS_QUERY_KEY,
              SERVICES_QUERY_KEY,
              EMPLOYEES_QUERY_KEY,
              CLIENTS_QUERY_KEY,
              waitingList.id,
            ],
            waitingList,
          ),
        ),
    },
  )
}

export function useFetchWaitingListById(id: number | undefined, timezone: string | undefined) {
  return useQuery(
    [WAITING_LISTS_QUERY_KEY, SERVICES_QUERY_KEY, EMPLOYEES_QUERY_KEY, CLIENTS_QUERY_KEY, id],
    async (): Promise<WaitingListType | undefined> => {
      const result = await request(
        gql`
          ${waitingListFragment}
          query ($id: Int!) {
            waitingListById(id: $id) {
              ...waitingListType
            }
          }
        `,
        { id },
      )

      if (result?.waitingListById) {
        return parseDatesInWaitingListGqlResponse(
          result.waitingListById,
          timezone ?? DEFAULT_TIMEZONE,
        )
      }

      reportError(new Error('waitingListById does not exist'), 'warning', { id, result })
      return undefined
    },
    {
      queryName: 'useFetchWaitingListById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )
}
