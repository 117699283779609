import {
  ServerLocationByIdType,
  ServerLocationGroupType,
  useCreateLocation,
  useFetchBranches,
  useFetchLocationById,
  useFetchLocationGroups,
  useUpdateLocation,
} from '@expane/data'
import { checkOnlyPositiveAmount } from '@expane/logic/form'
import { getLocationDefaultValueForMaxClientsOrEmployees } from '@expane/logic/locations/locations'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  NumberInput,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropdown,
  Textarea,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { useErrorOpeningDialog } from 'logic/hooks/useErrorOpeningDialog'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'

type LocationFormValues = {
  name: string
  description: string
  groupId: number | null
  maxClients: string
  maxEmployees: string
}

export interface OnCreateLocationDto {
  name: string
}

export const LocationDialog: FC<DialogProps<OnCreateLocationDto>> = ({
  id,
  closeDialog,
  isCreate,
  onCreate,
}) => {
  const branchId = store.branch.branchId

  const { data: myPermissions, isLoading: myPermissionsIsLoading } = useFetchMyPermissions()
  const { data: locationById, isLoading: locationByIdIsLoading } = useFetchLocationById(id)
  const { data: locationGroups, isLoading: locationGroupsIsLoading } =
    useFetchLocationGroups(branchId)
  const { data: branches, isLoading: branchesIsLoading } = useFetchBranches()

  const isLoading =
    (locationByIdIsLoading && !isCreate) ||
    myPermissionsIsLoading ||
    locationGroupsIsLoading ||
    branchesIsLoading
  const isNoData = (!isCreate && !locationById) || !locationGroups || !myPermissions || !branches

  useErrorOpeningDialog(!isLoading && isNoData, closeDialog)

  if (isLoading) return <LocationDialogPlaceholder closeDialog={closeDialog} />
  else if (isNoData) return null

  return (
    <LocationDialogLogic
      id={id}
      closeDialog={closeDialog}
      isCreate={isCreate}
      onCreate={onCreate}
      locationGroups={locationGroups}
      locationById={locationById}
      defaultBranchId={branchId}
      myPermissions={myPermissions}
    />
  )
}

export const LocationDialogLogic: FC<
  DialogProps<OnCreateLocationDto> & {
    locationGroups: ServerLocationGroupType[]
    locationById: ServerLocationByIdType | undefined
    defaultBranchId: number | undefined
    myPermissions: string[]
  }
> = ({
  id: locationId,
  isCreate,
  closeDialog,
  onCreate,
  locationGroups,
  locationById,
  defaultBranchId,
  myPermissions,
}) => {
  const { t } = useTranslation()

  const { mutateAsync: createMutation } = useCreateLocation()
  const { mutateAsync: updateMutation } = useUpdateLocation()

  const [openSnackbar] = useSnackbar()

  const isDefaultLocationForBranch = Boolean(locationById?.defaultForBranch?.id)

  const { formState, control, handleSubmit, setValue } = useForm<LocationFormValues>({
    defaultValues: {
      name: locationById?.name ?? '',
      groupId: locationById?.groupId,
      maxClients: getLocationDefaultValueForMaxClientsOrEmployees(
        isCreate,
        isDefaultLocationForBranch,
        locationById?.maxClients.toString(),
      ),
      maxEmployees: getLocationDefaultValueForMaxClientsOrEmployees(
        isCreate,
        isDefaultLocationForBranch,
        locationById?.maxEmployees.toString(),
      ),
      description: isCreate
        ? ''
        : isDefaultLocationForBranch
        ? t('defaultLocationDescription')
        : locationById?.description ?? undefined,
    },
  })
  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)

  const mutateLocation: SubmitHandler<LocationFormValues> = async ({
    name,
    groupId,
    maxClients,
    maxEmployees,
    description,
  }) => {
    if (isCreate) {
      const result = await createMutation({
        name,
        description,
        groupId,
        maxClients: Number(maxClients),
        maxEmployees: Number(maxEmployees),
        branchId: defaultBranchId ?? null,
      })

      if (result?.insertLocation?.id) {
        onCreate?.(result.insertLocation.id, { name })
        openSnackbar(t('location.createSuccess'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else {
      const result = await updateMutation({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: locationId!,
        locationSetInput: {
          name,
          description,
          groupId,
          maxClients: Number(maxClients),
          maxEmployees: Number(maxEmployees),
        },
      })

      if (result?.updateLocationById?.id) {
        openSnackbar(t('location.updateSuccess'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  // Редагування локації за замовчуванням та архівної локації неможливе
  const isEditingAllowed =
    !isDefaultLocationForBranch &&
    myPermissions.includes(permissions.location.set) &&
    !Boolean(locationById?.archived)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateLocation)()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('location.name')}</Dialog.Title>

          <Dialog.Body>
            <div className="flex justify-between">
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('title')}
                    placeholder={t('placeholders.locationName')}
                    required
                    errorMessage={{
                      isShown: Boolean(formState.errors.name),
                      text: t('formError.required'),
                    }}
                    value={value}
                    containerClassName="w-1/2 mr-2"
                    onChange={onChange}
                    disabled={!isEditingAllowed}
                    autoFocus
                  />
                )}
              />

              <Controller
                control={control}
                name="groupId"
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    label={t('category')}
                    items={locationGroups}
                    placeholder={t('placeholders.defaultSelect')}
                    noDataMessage={t('noCategory')}
                    className="w-1/2"
                    onSelectChange={onChange}
                    value={value ?? undefined}
                    errorMessage={{
                      isShown: Boolean(formState.errors.groupId),
                      text: t('formError.findDurations'),
                    }}
                    disabled={!isEditingAllowed}
                    isClearable
                    customClearFunction={() => setValue('groupId', null, { shouldDirty: true })}
                  />
                )}
              />
            </div>

            <div className="flex justify-between">
              <Controller
                name="maxClients"
                control={control}
                rules={{ validate: checkOnlyPositiveAmount }}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    label={t('maximumClients')}
                    placeholder=""
                    containerClassName="w-1/2 mr-2"
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed}
                    errorMessage={{
                      isShown: Boolean(formState.errors.maxClients),
                      text: t('formError.invalidValue'),
                    }}
                    hint={t('maximumClientsHint')}
                    allowDecimals={false}
                  />
                )}
              />

              <Controller
                name="maxEmployees"
                control={control}
                rules={{ validate: checkOnlyPositiveAmount }}
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    label={t('maximumEmployees')}
                    containerClassName="w-1/2"
                    value={value}
                    onChange={onChange}
                    disabled={!isEditingAllowed}
                    errorMessage={{
                      isShown: Boolean(formState.errors.maxEmployees),
                      text: t('formError.invalidValue'),
                    }}
                    hint={t('maximumEmployeesHint')}
                    allowDecimals={false}
                  />
                )}
              />
            </div>

            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.locationDescription')}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleSubmit(mutateLocation)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} disabled={formState.isSubmitting} />
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
    </>
  )
}

const LocationDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({ closeDialog }) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('location.name')} closeDialog={closeDialog}>
      <div className="flex justify-between mb-4">
        <PlaceholderInput label={t('title')} className="w-1/2 mr-2" />

        <PlaceholderInput label={t('category')} className="w-1/2" />
      </div>

      <div className="flex justify-between mb-4">
        <PlaceholderInput label={t('maximumClients')} className="w-1/2 mr-2" />

        <PlaceholderInput label={t('maximumEmployees')} className="w-1/2" />
      </div>

      <PlaceholderTextarea label={t('description')} />
    </PlaceholderDialog>
  )
}
