import { EmployeeGroupWithSchedule } from '@expane/data'
import { checkIfIsMoreThanOneMonthInPeriod } from '@expane/date'
import { useConvertNumberToMoneyWithoutSymbol } from '@expane/logic/currency'
import {
  CountedNotPaidSalaryType,
  CountedSalariesFullInfoByEmployeeType,
} from '@expane/logic/salaryIssues/logic'
import { checkIsSomeOfCurrentSalaryRateSettingsIsMonthly } from '@expane/logic/salarySettings'
import { roundValue, transformPersonName } from '@expane/logic/utils'
import {
  Input,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { getEmployeeByIdFromEmployeeGroups } from 'logic/employees'
import {
  SHOW_MANAGING_EMPLOYEE_TITLE,
  useShowSalaryIssuesTitleByBusinessSettings,
} from 'logic/hooks/useShowSalaryIssuesTitleByBusinessSettings'
import { translateTableTitles } from 'logic/utils'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { PayButton } from 'widgets/Buttons'
import { IssueSalaryDialogFormValues } from 'widgets/SalaryIssueDialog/index'
import { PLACEHOLDERS } from '@expane/logic/form'

interface SalaryIssueTableProps {
  control: Control<IssueSalaryDialogFormValues>
  employeeGroups: EmployeeGroupWithSchedule[]
  // нужно для того чтобы узнать есть ли задолженность
  countedSalariesFullInfo: CountedSalariesFullInfoByEmployeeType[]
  countedNotPaidSalaries: CountedNotPaidSalaryType[]
  fromDate: Date
  toDate: Date
  onPayDebtClick: (id: number) => void
}

export const SalaryIssueTable: FC<SalaryIssueTableProps> = observer(
  ({
    control,
    employeeGroups,
    countedNotPaidSalaries,
    fromDate,
    toDate,
    onPayDebtClick,
    countedSalariesFullInfo,
  }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const convertNumberToMoney = useConvertNumberToMoneyWithoutSymbol(branchId)

    const { tableTitlesByShowingManagingEmployee, showManagingEmployee } =
      useShowSalaryIssuesTitleByBusinessSettings(titles)

    return (
      <div className="h-80">
        <TableContainer className="max-h-70">
          <TableHeader>
            <tr>
              {translateTableTitles(tableTitlesByShowingManagingEmployee, t).map(
                ({ title, width, isAlignRight }) => (
                  <TableHeaderCell
                    key={title}
                    className={isAlignRight ? `${width} text-right` : width}
                  >
                    {title}
                  </TableHeaderCell>
                ),
              )}
            </tr>
          </TableHeader>

          <TableBody>
            {countedNotPaidSalaries.map(
              (
                {
                  employeeId,
                  notPaidSalaryRate,
                  notPaidPremiums,
                  notPaidServices,
                  notPaidReferralPercentages,
                  notPaidRateSalaryRequestData,
                },
                index,
              ) => {
                const employee = getEmployeeByIdFromEmployeeGroups(employeeGroups, employeeId)
                // находим ставку которая актуальна на выбранную дату, для того,
                // чтоб знать можем ли мы ее менять(если месячная) или просто отображать
                // если зарплата месячная, то наверняка должна быть только 1 формула настройки

                const isSalaryMonthly = employee
                  ? checkIsSomeOfCurrentSalaryRateSettingsIsMonthly({
                      salarySettings: employee.salarySettings,
                      fromDate,
                      toDate,
                    })
                  : false

                return (
                  <Controller
                    key={employeeId}
                    control={control}
                    rules={{
                      required: true,
                      validate: value => {
                        return Number(value.notPaidSalaryRate) <= notPaidSalaryRate
                      },
                    }}
                    name={`countedNotPaidSalaries.${index}` as 'countedNotPaidSalaries.0'}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      const notPaidSalaryWithoutRateTotalSum =
                        notPaidPremiums + notPaidServices + notPaidReferralPercentages

                      return (
                        <TableRow className="cursor-pointer" key={employeeId}>
                          <TableCell className={tableItemClassName + ' truncate'}>
                            {employee && transformPersonName(employee)}
                          </TableCell>

                          <TableCell className={tableItemClassName}>
                            {isSalaryMonthly &&
                            notPaidSalaryRate !== 0 &&
                            !checkIfIsMoreThanOneMonthInPeriod(fromDate, toDate) ? (
                              <Input
                                type="number"
                                placeholder={PLACEHOLDERS.servicePrice}
                                value={roundValue(value.notPaidSalaryRate).toString()}
                                className="text-right"
                                onChange={e => {
                                  const newSalaryRate = Number(e.target.value)

                                  onChange({
                                    ...value,
                                    notPaidSalaryRate: newSalaryRate,
                                    totalSum: newSalaryRate + notPaidSalaryWithoutRateTotalSum,
                                    notPaidRateSalaryRequestData: notPaidRateSalaryRequestData.map(
                                      data => ({ ...data, value: newSalaryRate }),
                                    ),
                                  })
                                }}
                                required
                                height="small"
                                errorMessage={{
                                  isShown: Boolean(error),
                                  text: ' ',
                                  reserveIndent: false,
                                }}
                              />
                            ) : (
                              <p className="text-right pr-4">
                                {convertNumberToMoney(notPaidSalaryRate)}
                              </p>
                            )}
                          </TableCell>

                          <TableCell className={tableItemClassName + ' text-right'}>
                            {convertNumberToMoney(notPaidServices)}
                          </TableCell>

                          <TableCell className={tableItemClassName + ' text-right'}>
                            {convertNumberToMoney(notPaidPremiums)}
                          </TableCell>
                          {showManagingEmployee && (
                            <TableCell className={tableItemClassName + ' text-right'}>
                              {convertNumberToMoney(notPaidReferralPercentages)}
                            </TableCell>
                          )}

                          <td className={tableItemClassName + ' text-right'}>
                            <p>
                              {convertNumberToMoney(
                                notPaidSalaryWithoutRateTotalSum + value.notPaidSalaryRate,
                              )}
                            </p>
                          </td>

                          <td className={tableItemClassName + ' text-right pr-4'}>
                            {checkIsDebtSalary(countedSalariesFullInfo, employeeId) ? (
                              <PayButton
                                className={buttonStyles}
                                type="outline"
                                size="small"
                                onClick={() => onPayDebtClick(employeeId)}
                              />
                            ) : (
                              '-'
                            )}
                          </td>
                        </TableRow>
                      )
                    }}
                  />
                )
              },
            )}
          </TableBody>
        </TableContainer>
      </div>
    )
  },
)

const buttonStyles = `flex rounded-md border-2 font-medium focus:outline-none focus:ring-2 
focus:ring-offset-2 leading-3 text-xs focus:ring-primary-400 text-primary-500 px-1 py-1 
border-primary-400 hover:border-primary-600 hover:text-primary-600 ml-auto`
const tableItemClassName = 'pl-4 py-1 text-sm'

const titles: { title: string; width: string; isAlignRight?: boolean }[] = [
  { title: 'employee.name', width: 'w-56' },
  { title: 'salary.rate', width: 'w-36', isAlignRight: true },
  { title: 'salary.forServices', width: 'w-28', isAlignRight: true },
  { title: 'salary.premiumsPenalties', width: 'w-32', isAlignRight: true },
  { title: SHOW_MANAGING_EMPLOYEE_TITLE, width: 'w-28', isAlignRight: true },
  { title: 'totalAmount', width: 'w-32', isAlignRight: true },
  { title: 'salary.debt', width: 'w-28', isAlignRight: true },
]

const checkIsDebtSalary = (
  countedSalariesFullInfo: CountedSalariesFullInfoByEmployeeType[],
  employeeId: number,
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const countedSalariesFullInfoByEmployee = countedSalariesFullInfo.find(
    defaultSalary => defaultSalary.employeeId === employeeId,
  )!

  return countedSalariesFullInfoByEmployee.debtSalary.totalSum
}
