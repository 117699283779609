import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  InputMaybe,
  ServerClientInsertInput,
  ServerWaitingListEmployeeInsertInput,
  ServerWaitingListInsertInput,
  ServerWaitingListSetInput,
} from '../../generated/graphql-types'
import { CLIENTS_QUERY_KEY } from '../client/queryKeys'
import { WAITING_LISTS_QUERY_KEY } from './queryKeys'
import { WaitingListDate } from './logic'

export function useUpdateWaitingList() {
  return useMutation(
    async (dto: {
      id: number
      waitingListSetInput: Omit<ServerWaitingListSetInput, 'start' | 'end'> & {
        dates?: InputMaybe<WaitingListDate[]>
      }
      serverWaitingListEmployeeInsertInput?: ServerWaitingListEmployeeInsertInput[]
    }): Promise<{ updateWaitingListById?: { id?: number } }> => {
      return request(
        gql`
          mutation (
            $waitingListSetInput: waitingList_set_input!
            $id: Int!
            $serverWaitingListEmployeeInsertInput: [waitingListEmployee_insert_input!]!
            $isWaitingListsEmployeesUpdate: Boolean!
          ) {
            updateWaitingListById(pk_columns: { id: $id }, _set: $waitingListSetInput) {
              id
            }
            deleteWaitingListsEmployees(where: { waitingListId: { _eq: $id } })
              @include(if: $isWaitingListsEmployeesUpdate) {
              affected_rows
            }
            insertWaitingListsEmployees(objects: $serverWaitingListEmployeeInsertInput)
              @include(if: $isWaitingListsEmployeesUpdate) {
              affected_rows
            }
          }
        `,
        {
          id: dto.id,
          waitingListSetInput: { ...dto.waitingListSetInput },
          serverWaitingListEmployeeInsertInput: dto.serverWaitingListEmployeeInsertInput ?? [],
          isWaitingListsEmployeesUpdate: Boolean(dto?.serverWaitingListEmployeeInsertInput),
        },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([WAITING_LISTS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useCreateWaitingList() {
  return useMutation(
    async (dto: {
      waitingListInsertInput: ServerWaitingListInsertInput &
        Required<Pick<ServerWaitingListInsertInput, 'branchId'>> & { dates: WaitingListDate[] }
      clientInsertInput?: ServerClientInsertInput
    }): Promise<{ insertWaitingList?: { id?: number } }> => {
      if (dto.clientInsertInput) {
        return request(
          gql`
            mutation (
              $serviceId: Int!
              $employeeId: Int
              $dates: jsonb!
              $description: String
              $clientInsertInput: client_insert_input!
            ) {
              insertWaitingList(
                object: {
                  client: { data: $clientInsertInput }
                  serviceId: $serviceId
                  employeeId: $employeeId
                  description: $description
                  dates: $dates
                }
              ) {
                id
              }
            }
          `,
          {
            serviceId: dto.waitingListInsertInput.serviceId,
            employeeId: dto.waitingListInsertInput.employeeId,
            description: dto.waitingListInsertInput.description,
            clientsInsertInput: dto.clientInsertInput,
          },
        )
      }

      return request(
        gql`
          mutation ($waitingListInsertInput: waitingList_insert_input!) {
            insertWaitingList(object: $waitingListInsertInput) {
              id
            }
          }
        `,
        { waitingListInsertInput: dto.waitingListInsertInput },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WAITING_LISTS_QUERY_KEY])
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
